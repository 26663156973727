import React, { useState } from 'react';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

import { useRecordContext } from 'react-admin';
import { useWebservice } from '../../../../hooks/UseWebservice';



export default function CensorNftImageButton() {

  const { isCardCensored, censorCard, uncensorCard } = useWebservice();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [initializing, setInitializing] = useState<boolean>(true);
  const [censoredImageUrl, setCensoredImageUrl] = useState<string | null>(null);
  const [censoring, setCensoring] = useState<boolean>(false);
  const [uncensoring, setUncensoring] = useState<boolean>(false);

  const record = useRecordContext();

  const onClick = () => {
    setDialogOpen(true);
    initialize();
  }

  const initialize = async () => {
    const result = await isCardCensored(record.nft_id);
    if (result.is_censored) {
      setCensoredImageUrl(result.image_url!);
    }
    setInitializing(false);
  }

  const onCensor = async () => {
    setCensoring(true);
    const result = await censorCard(record.nft_id);
    setCensoredImageUrl(result.image_url);
    setCensoring(false);
  }

  const onUncensor = async () => {
    setUncensoring(true);
    await uncensorCard(record.nft_id);
    setCensoredImageUrl(null);
    setUncensoring(false);
  }

  return (
    <>
      <Button style={{backgroundColor: 'darkblue', color: 'white', height: "100%", width: "100%"}} onClick={onClick}>Censoring</Button>
      <Dialog 
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <DialogTitle>Censor card nft - {record.nft_id}</DialogTitle>
        
        <DialogContent>
          {
            initializing
            ?
            <CircularProgress />
            :
            <>{
              censoredImageUrl 
              ?
              <div>
                {
                  uncensoring
                  ?
                  <>
                    <Typography><i>Uncensoring</i> image, please wait...</Typography>
                    <CircularProgress />
                  </>
                  :
                  <>
                    <Typography>This image is already censored.</Typography>
                    <img style={{ width: "90%" }} src={censoredImageUrl} alt="Existing censored card"></img>
                    <Button color="primary" variant="contained" onClick={onUncensor}>Do uncensor it!</Button>
                  </>
                }
              </div>
              :
              <div>
                {
                  censoring
                  ?
                  <>
                    <Typography><i>Censoring</i> image, please wait...</Typography>
                    <CircularProgress />
                  </>
                  :
                  <>
                    <Typography>Do you really want to censor this image?</Typography>
                    <Button color="primary" variant="contained" onClick={onCensor}>Do censor it!</Button>
                  </>
                }
              </div>
            }</>
          }
        </DialogContent>
      </Dialog>
    </>
  )
}
