import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import memLogo from "../../assets/meme_coin.svg";
import { useMemecoinStats } from '../../hooks/UseMemecoinStats';


export default function MemeCoinStats() {
    
    const { 
        loading: loadingStats, 
        currentEthRate, 
        currentMaticRate, 
        adminEthWallet, 
        adminMaticWallet, 
        adminEthBalance, 
        adminMaticBalance, 
        updateEthMemRate,
        updateMaticMemRate,
    } = useMemecoinStats();
    
    const [ newEthMemRate, setNewEthMemRate ] = useState<number>(0);
    const [ newMaticMemRate, setNewMaticMemRate ] = useState<number>(0);
    const [ ethConfirmDialogOpen, setEthConfirmDialogOpen ] = useState<boolean>(false);
    const [ maticConfirmDialogOpen, setMaticConfirmDialogOpen ] = useState<boolean>(false);

    const [ alertText, setAlertText ] = useState<string>("");
    const [ alertShown, setAlertShown ] = useState<boolean>(false);

    const onSetNewEthMemRate = async (newRate: number) => {
        const error = await updateEthMemRate(newRate);
        if (error != null) {
            displayAlert(`Could not set new ETH-MEM rate: ${error}`)
        } else {
            displayAlert(`New ETH-MEM rate set!`)
        }

        setEthConfirmDialogOpen(false);
    }

    const onSetNewMaticMemRate = async (newRate: number) => {
        const error = await updateMaticMemRate(newRate);
        if (error != null) {
            displayAlert(`Could not set new MATIC-MEM rate: ${error}`)
        } else {
            displayAlert(`New MATIC-MEM rate set!`)
        }

        setMaticConfirmDialogOpen(false);
    }

    const displayAlert = (text: string) => {
        setAlertShown(true);
        setAlertText(text);
    }

    return (
        <div>
            <Card>
                <CardHeader title="MemeCoin" avatar={<Avatar alt="Cindy Baker" src={memLogo} />}></CardHeader>
                <CardContent>
                    {
                        loadingStats
                        ?
                        <CircularProgress />
                        :
                        <div>
                            <Typography variant="body1" gutterBottom>
                                1 ETH to dMEM: {currentEthRate != null? currentEthRate.toString() : ""}
                            </Typography>
                            <TextField type="number" value={newEthMemRate} onChange={(event) => setNewEthMemRate(parseInt(event.target.value))}></TextField>
                            <Button onClick={() => setEthConfirmDialogOpen(true)} color="secondary" variant="contained">Update</Button>

                            <Typography variant="body1">
                                1 MATIC to dMEM: {currentMaticRate != null? currentMaticRate.toString() : ""}
                            </Typography>
                            <TextField type="number" value={newMaticMemRate} onChange={(event) => setNewMaticMemRate(parseInt(event.target.value))}></TextField>
                            <Button onClick={() => setMaticConfirmDialogOpen(true)} color="secondary" variant="contained">Update</Button>

                            <Typography variant="body1" gutterBottom>
                                Admin MEM (ETH) balance: {adminEthBalance? adminEthBalance.toString() : ""} ({adminEthWallet})
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Admin MEM (MATIC) balance: {adminMaticBalance? adminMaticBalance.toString() : ""} ({adminMaticWallet})
                            </Typography>
                        </div>
                    }
                </CardContent>
            </Card>

            <Dialog open={ethConfirmDialogOpen}>
                <DialogTitle>Confirm ETH - MEM rate change</DialogTitle>
                
                <DialogContent>
                    Do you really want 1 ETH to give {newEthMemRate} DMEM to users?
                </DialogContent>

                <DialogActions>
                    <Button autoFocus onClick={() => setEthConfirmDialogOpen(false)} color="primary">Cancel</Button>
                    <Button onClick={() => onSetNewEthMemRate(newEthMemRate)}>Confirm</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={maticConfirmDialogOpen}>
                <DialogTitle>Confirm MATIC - MEM rate change</DialogTitle>

                <DialogContent>
                    Do you really want 1 MATIC to give {newMaticMemRate} DMEM to users?
                </DialogContent>

                <DialogActions>
                    <Button autoFocus onClick={() => setMaticConfirmDialogOpen(false)} color="primary">Cancel</Button>
                    <Button onClick={() => onSetNewMaticMemRate(newMaticMemRate)}>Confirrm</Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={alertShown} autoHideDuration={2500}>
                <div>
                    {alertText}
                </div>
            </Snackbar>
        </div>
    );
}