import ExportLeagueButton from "./../../buttons/exportLeagueButton";

import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    Edit,
    EditButton,
    FunctionField,
    ImageField,
    List,
    NumberField,
    NumberInput,
    required,
    SimpleForm,
    TextField,
    TextInput
} from 'react-admin';

const number = (message = 'Must be a number') =>
    (value: any) => value && isNaN(Number(value)) ? message : undefined;

const notNegativeNumber = (message = 'Cannot be less than 0') =>
    (value: any) => parseFloat(value) < 0 ? message : undefined;

const isInteger = (message = 'Must be an integer') =>
    (value: any) => !Number.isInteger(value) ? message : undefined;

const applicationFeeSummary = (record: any) => {
    if (record.application_fee_xp === 0 && record.application_fee_mem_wei === '0') {
        return "No fee";
    } else if (record.application_fee_xp > 0 && record.application_fee_mem_wei !== '0') {
        return `${record.application_fee_xp} XP + ${record.application_fee_mem} MBC`;
    } else if (record.application_fee_memwei === '0') {
        return `${record.application_fee_xp} XP`;
    } else if (record.application_fee_xp === 0) {
        return `${record.application_fee_mem} MBC`;
    } else {
        return "Error";
    }
};

export const LeaguesList = () => (
    <List>
        <Datagrid>
            <NumberField source="id"/>
            <ImageField source="image_url" sx={{'& .RaImageField-image': {'height': '100px'}}}/>
            <TextField source="title"/>
            <TextField source="description"/>
            <TextField source="owner_address"/>
            <FunctionField label="Application fee" render={applicationFeeSummary}/>
            <NumberField source="size"/>
            <NumberField source="application_league_victory_percentage_rank"/>
            <NumberField source="rating"/>
            <BooleanField source="inactive"/>
            <ExportLeagueButton />
            <EditButton/>
        </Datagrid>
    </List>
);

const LeaguesTitle = ({league}: any) => {
    return <span>Arena league {league ? `"${league.title}" (${league.id})` : ''}</span>
};

export const LeaguesCreate = () => (
    <Create title={<LeaguesTitle/>}>
        <SimpleForm>
            <TextInput source="title" validate={required()}/>
            <TextInput source="description"/>
            <TextInput source="image_url"/>
            <ImageField source="image_url"/>
            <TextInput source="owner_address"/>
            <NumberInput source="size" defaultValue="15" validate={required()}/>
            <NumberInput source="application_league_victory_percentage_rank" defaultValue="0.7"/>
            <NumberInput source="rating" validate={required()}/>
            <NumberInput source="application_fee_xp" label="Application Xp fee"
                         validate={[required(), notNegativeNumber(), isInteger()]} defaultValue="0"/>
            <TextInput source="application_fee_mem" label="Application MBC fee"
                       validate={[required(), number(), notNegativeNumber()]} defaultValue="0"/>
            <BooleanInput source="inactive" defaultValue={true}/>
        </SimpleForm>
    </Create>
);

export const LeaguesEdit = () => (
    <Edit title={<LeaguesTitle/>}>
        <SimpleForm>
            <TextInput source="title" validate={required()}/>
            <TextInput source="description"/>
            <TextInput source="owner_address"/>
            <NumberInput source="size" defaultValue="15" validate={required()}/>
            <NumberInput source="application_league_victory_percentage_rank"/>
            <NumberInput source="rating" defaultValue="1" validate={required()}/>
            <NumberInput source="application_fee_xp" label="Application Xp fee"
                         validate={[required(), notNegativeNumber(), isInteger()]} defaultValue="0"/>
            <TextInput source="application_fee_mem" label="Application MBC fee"
                       validate={[required(), number(), notNegativeNumber()]} defaultValue="0"/>
            <BooleanInput source="inactive"/>
        </SimpleForm>
    </Edit>
);
