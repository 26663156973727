import React, {useState} from 'react';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { useRecordContext } from 'react-admin';
import { AdminTaskInfoResponse } from './applyParserRule';
import { useWebservice } from '../hooks/UseWebservice';


interface TaskStatus {
  finished: boolean,
  total_nfts_count: number,
  processed_nfts_count: number,
  error: string,
}


export default function SetDomainFaviconButton() {

  const { getAdminTaskStatus, changeDomainFavicon, updateFaviconForExistingCards } = useWebservice();

  const [faviconUrl, setFaviconUrl] = useState<string>("");
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const [changingFavicon, setChangingFavicon] = useState<boolean>(false);

  const [useOnGenesisOrigin, setUseOnGenesisOrigin] = useState<boolean>(false);
  const [startNftId, setStartNftId] = useState<number | null>();
  const [endNftId, setEndNftId] = useState<number | undefined>();
  const [applyParserRules, setApplyParserRules] = useState<boolean>(false);
  const [taskStarted, setTaskStarted] = useState<boolean>(false);
  const [taskId, setTaskId] = useState<number | null>(null);
  const [taskStatus, setTaskStatus] = useState<TaskStatus | null>(null);

  const record = useRecordContext();

  const onSetDomainFavicon = async () => {
    setChangingFavicon(true);
    await changeDomainFavicon(record.id as number, faviconUrl);
    setChangingFavicon(false);
  }

  const onApplyOnExisting = async () => {
    setTaskStarted(true);
    const result = await updateFaviconForExistingCards(record.id as number, useOnGenesisOrigin, applyParserRules, startNftId, endNftId);
    setTaskId(result.task_id);
    getTaskStatus(result.task_id);
  }

  const getTaskStatus = (taskId: number) => {
    setTimeout(async () => {
      const result = await getAdminTaskStatus(taskId);
      setTaskStatus({
        finished: result.finished,
        total_nfts_count: result.total_nfts_count,
        processed_nfts_count: result.processed_nfts_count,
        error: result.error,
      });

      if (!result.finished) {
        getTaskStatus(taskId);
      }
    }, 5000)
  }

  return (<>
    { !taskStarted && <Button style={{ backgroundColor: "red" }} onClick={() => setDialogOpen(true)}>Set custom favicon</Button> }
    { taskStarted && <Button style={{ backgroundColor: "red" }} onClick={() => setDialogOpen(true)}>See status</Button> }

    <Dialog 
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}>

      <DialogTitle>Set custom favicon for domain <i>{record.domain}</i></DialogTitle>
      <DialogContent>
        <Typography>
          This will change favicon for all future cards from this domain.
        </Typography>
        <TextField label="Favicon URL" value={faviconUrl} onChange={(e) => setFaviconUrl(e.target.value)} />
        <Typography>
          Image from the domain:
        </Typography>
        <img height="124px" width="124px" src={faviconUrl} alt="favicon"></img><br></br>
        {
          changingFavicon
          ?
          <CircularProgress />
          :
          <Button style={{ backgroundColor: "red" }} onClick={onSetDomainFavicon}>Set domain favicon now!</Button>
        }
        
        <hr></hr>
        {!taskStarted && <>
          <Typography>Do you want to apply the favicon for already created NFTs?</Typography>
          <FormControlLabel 
            control={<Checkbox color="primary" checked={useOnGenesisOrigin} onChange={(event) => setUseOnGenesisOrigin(event.target.checked)} />} 
            label="Apply for genesis and origin cards" 
            labelPlacement="end" />
          <br></br>
          <FormControlLabel 
            control={<Checkbox color="primary" checked={applyParserRules} onChange={(event) => setApplyParserRules(event.target.checked)} />} 
            label="Apply parser rules on current titles of the cards" 
            labelPlacement="end" />
          <br></br>
          <TextField label="Start on NFT id" type="number" variant="outlined" value={startNftId} onChange={(event) => setStartNftId(parseInt(event.target.value))} />
            <TextField label="End on NFT id" type="number" variant="outlined" value={endNftId} onChange={(event) => setEndNftId(parseInt(event.target.value))} />
          <Button style={{ backgroundColor: "red" }} onClick={onApplyOnExisting}>Update already created!</Button>
        </>}

        {taskStarted && !taskStatus && <>
          <Typography>Starting the task</Typography>
          <CircularProgress />
        </>}

        {taskStarted && taskStatus && !taskStatus.finished && <>
          <Typography>Task {taskId} in PROGRESS</Typography>
          <Typography>NFTs to be changed: {taskStatus.total_nfts_count}</Typography>
          <Typography>Processed NFTs: {taskStatus.processed_nfts_count}</Typography>
          <LinearProgress variant="determinate" value={(taskStatus.processed_nfts_count / taskStatus.total_nfts_count) * 100}></LinearProgress>
          <CircularProgress size={12} />
        </>}

        {taskStarted && taskStatus && taskStatus.finished && taskStatus.error && <>
          <Typography variant="caption" color="secondary">ERROR</Typography>
          <Typography>Task id: {taskId}</Typography>
          <Typography>NFTs processed <b>{taskStatus.processed_nfts_count}</b></Typography>
        </>}

        {taskStarted && taskStatus && taskStatus.finished && !taskStatus.error && <>
          <Typography variant="caption" color="primary">SUCCESSFULLY FINISHED</Typography>
          <Typography>Task id: {taskId}</Typography>
          <Typography>Processed NFTs: <b>{taskStatus.processed_nfts_count}</b></Typography>
        </>}
      </DialogContent>

    </Dialog>
  </>)
}