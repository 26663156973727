import { useController } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TextField } from '@mui/material';


interface Props {
  source: string;
  label?: string;
}


export function UTCDateTimeInput(props: Props) {

  const {
    field, 
  } = useController({ name: props.source });

  const getTimezoneOffset = (value: Date) => value.getTimezoneOffset() * 60000;

  const makeLocalAppearUTC = (value: Date | string) => {
    if (value == null || value == undefined) {
      return null;
    }

    if (typeof value == "string") {
      return value;
    }

    const dateTime = new Date(value);
    const utcFromLocal = new Date(dateTime.getTime() + getTimezoneOffset(dateTime));

    return utcFromLocal;
  };

  const localToUTC = (dateTime: Date | null) => {
    if (dateTime == null) {
      return null;
    }

    const utcFromLocal = new Date(dateTime.getTime() - getTimezoneOffset(dateTime));
    return utcFromLocal;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        {...field}
        label={props.label}
        renderInput={(props) => <TextField {...props} />}
        ampm={false}

        value={makeLocalAppearUTC(field.value)}
        onChange={(value: Date | null) => field.onChange({ target: { value: localToUTC(value) }})}
      />
    </LocalizationProvider>
  )
}
