import { List, NumberField, Datagrid, TextField, DateField, NumberInput } from 'react-admin';


const ApplicationsFilter = [
    <NumberInput label="Task ID" source="admin_task_id" />,
    <NumberInput label="Entry ID" source="entry_id" />
]

export const AdminTaskApplications = () => (
    <List filters={ApplicationsFilter}>
        <Datagrid>
            <TextField source="id" />
            <NumberField source="admin_task_id" />
            <TextField source="entry_id" />
            <DateField source="applied_at" showTime />
            <TextField source="before" />
            <TextField source="after" />
        </Datagrid>
    </List>
);