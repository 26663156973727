import React from "react";

import { Web3Chain } from "../../hooks/web3/UseWeb3"

import ethereumLogo from "../../assets/ethereum_logo.png";
import maticLogo from "../../assets/matic_logo.svg";


interface Props {
  chain: number;
  height?: number;
}


export default function ChainLogo(props: Props) {

  const imgSrc = function() {
    if (props.chain === Web3Chain.ethereum) {
      return ethereumLogo;
    } else if (props.chain === Web3Chain.matic) {
      return maticLogo;
    } else {
      return "";
    }
  }

  const height = props.height? props.height : 15;

  return (
    <img src={imgSrc()} height={height} alt="Blockchain logo"></img>
  )

}
