import Button from '@mui/material/Button';
import {useNotify, useRecordContext} from 'react-admin';
import { useWebservice } from '../hooks/UseWebservice';


interface Props {
    record: any;
}

interface LeagueCardPartial {
    id: number;
}


function AcceptCardToLeagueButton() {

    const { acceptLeagueCardApplication } = useWebservice();
    
    const notify = useNotify();
    const record = useRecordContext<LeagueCardPartial>();

    const onClick = () => {
        const acceptLeagueCardApplicationPromise = acceptLeagueCardApplication(record.id);

        acceptLeagueCardApplicationPromise.catch(error => {
            notify("Error on webservice! " + error.message, { type: "warning" })
        });

        acceptLeagueCardApplicationPromise.then(response => {
            if (response.error) {
                notify("Error accepting the card: " + response.error, { type: "warning" })
            }
            else {
                notify("Card accepted to the league! Refresh to see changes", {type: "info" })
            }
        });
    };

    return <Button style={{backgroundColor: 'darkseagreen'}} onClick={onClick}>Accept</Button>
}

export default AcceptCardToLeagueButton;
