import { useEffect, useState } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useWebservice } from '../../hooks/UseWebservice';
import { CeleryQueueInfo } from '../../webservice/models';



export default function CeleryTasks() {

  const { getWorkerQueuesOverview } = useWebservice();

  const [taskQueues, setTaskQueues] = useState<CeleryQueueInfo[]>();

  useEffect(() => {
    fetchTaskQueues();
  }, [])

  const fetchTaskQueues = async () => {
    const result = await getWorkerQueuesOverview();
    setTaskQueues(result);
  }

  return (
    <Card>
      <CardHeader title="Celery task queues"></CardHeader>
      <CardContent>
      {
        taskQueues
        ?
        <div>
          {
            taskQueues.map(taskQueue => (
              <div key={taskQueue.name}>
                <div style={{padding: "8px 0", display: 'flex', flexDirection: 'row'}}> 
                  <Typography style={{fontWeight: "bold"}}>{taskQueue.name}: </Typography> 
                  <Typography style={{color: taskQueue.tasks_count < 100? "green" : taskQueue.tasks_count < 200? "orange" : "red"}}>{taskQueue.tasks_count}</Typography>
                </div>
              </div>
            ))
          }
        </div>
        :
        <CircularProgress />
      }
      </CardContent>
    </Card>
  );
}
