import * as BigNumber from "bignumber.js"

import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

import ChainLogo from "./ChainLogo";

import useExplorerLinks from '../../hooks/web3/UseExplorerLinks';
import useWeb3 from '../../hooks/web3/UseWeb3';
import useWeiConversions from '../../hooks/web3/UseWeiConversions';

import memLogo from "../../assets/meme_coin.svg";


interface Props {
  balance: BigNumber.BigNumber;
  memBalance: BigNumber.BigNumber;
  address: string;
  label: string;
  additionalInfo?: any;
}


function ContractInfo({ balance, memBalance, address, label, additionalInfo = {} }: Props) {

  const { web3chain: chain } = useWeb3();
  const { weiToEth, weiToMem } = useWeiConversions();
  const { getLink: getExplorerLink } = useExplorerLinks();
  const classes = useStyles();

  return (
    <div className={classes.parent}>
      <div className={classes.labelWrapper}>
        <Typography variant="body1">{label}:</Typography>
        
        {balance != null && (<div className={classes.labelItem}>
          <Typography variant="body1">{weiToEth(balance, 3)!.toString()}&nbsp;</Typography>
          <ChainLogo chain={chain}></ChainLogo>
        </div>)}
        
        {memBalance != null && (<div className={classes.labelItem}>
          <Typography variant="body1">{weiToMem(memBalance, 1)!.toString()}&nbsp;</Typography>
          <img className={classes.memLogo} src={memLogo} alt="Memecoin logo" />
        </div>)}
      </div>
      <Typography variant="caption">
        { address }
      </Typography>
      {
        Object.keys(additionalInfo).map((value, index) => (
          <Typography key={value} variant="caption">
            { value }: { additionalInfo[value] }
          </Typography>
        ))
      }
      <a className={classes.explorerLink} href={getExplorerLink(address)} target="_blank" rel="noreferrer">Explorer</a>
      <div className={classes.space}></div>
    </div>
  )
}


const useStyles = makeStyles({
  parent:{
    display: 'flex',
    flexDirection: 'column'
  },
  labelWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },

  labelItem: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '6px',
    alignItems: 'center',
  },

  explorerLink: {
    fontSize: 14,
    color: '#2E9AFE',
    textDecoration: 'none',
  },

  space: {
    marginBottom: 16
  },

  memLogo: {
    width: '18px',
    height: '18px',
  },
});


export default ContractInfo;
