import { List, Edit, Create, Datagrid, TextField, EditButton, NumberField, SimpleForm, TextInput,
    required } from 'react-admin';

export const BannedDomainsList = () => (
    <List>
        <Datagrid>
			<TextField source="id" />
            <TextField source="domain" />
            <TextField source="path_regex" />
            <TextField source="title_regex"/>
            <TextField source="display_name"/>
            <EditButton />
        </Datagrid>
    </List>
);

const BannedDomainTitle = ({ bannedDomain }: any) => {
    return <span>Banned domain {bannedDomain ? `"${bannedDomain.domain}" (${bannedDomain.id})` : ''}</span>
};

export const BannedDomainCreate = () => (
    <Create title={<BannedDomainTitle />}>
        <SimpleForm>
            <TextInput source="domain" validate={required()}/>
            <TextInput source="path_regex" />
            <TextInput source="title_regex" />
            <TextInput source="display_name"/>
        </SimpleForm>
    </Create>
);

export const BannedDomainEdit = () => (
    <Edit title={<BannedDomainTitle />}>
        <SimpleForm>
            <NumberField source="id" />
            <TextInput source="domain" validate={required()} />
            <TextInput source="path_regex" />
            <TextInput source="title_regex" />
            <TextInput source="display_name"/>
        </SimpleForm>
    </Edit>
);
