import { useRefresh, useRecordContext } from "react-admin";

import ConfirmActionButton from "../components/shared/ConfirmActionButton";
import { useWebservice } from "../hooks/UseWebservice";

const AcceptArtCreatorApplicationButton = () => {

    const { acceptArtCreatorApplication } = useWebservice();

    const record = useRecordContext();
    const applicationIsOpen = record.application_status === "in_process";
    const refresh = useRefresh();

    if (!record) return null;

    const onActionConfirmed = async (record: any) => {
        await acceptArtCreatorApplication(record.id);
        refresh();
    };

    return (
        <ConfirmActionButton
            color="#277D4F"
            disabled={!applicationIsOpen}
            buttonLabel="Accept application"
            onConfirmed={onActionConfirmed}
            onSuccess={`Application #${record.id} accepted`}
            dialogTitle="Accept art creator application"
            actionLabel={`Accept application #${record.id}`}
            style={{ marginRight: "2em" }}
        />
    );
};

export default AcceptArtCreatorApplicationButton;
