import {Fragment, useState} from 'react';

import Button from '@mui/material/Button';
import IconCancel from '@mui/icons-material/Cancel';
import IconSend from '@mui/icons-material/Send';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';

import { Typography } from '@mui/material';
import { useWebservice } from '../hooks/UseWebservice';


interface Props {
    recordLive?: any;
}


interface State {
    showDialog: boolean;
    fetchingResponse: boolean;

    testTitle: string;
    testImageUrl: string;
    testCanonicalUrl: string;
    errorMessage: string;
    testedUrl: string;
}


function ParserRuleTestButton(props: Props) {

    const { testPageParserRule } = useWebservice();

    const [state, setState] = useState<State>({
        showDialog: false,
        fetchingResponse: false,

        testTitle: "",
        testImageUrl: "",
        testCanonicalUrl: "",
        errorMessage: "",
        testedUrl: ""
    });

    const handleClick = () => {
        setState({ showDialog: true, fetchingResponse: false, testTitle: "", testImageUrl: "", testCanonicalUrl: "", errorMessage: "", testedUrl: state.testedUrl });
    };

    const handleCloseClick = () => {
        setState({ showDialog: false, fetchingResponse: false, testTitle: "", testImageUrl: "", testCanonicalUrl: "", errorMessage: "", testedUrl: state.testedUrl });
    };

    const handleExecuteClick = () => {
        const type = props.recordLive.rule_type;
        const xpath = props.recordLive.xpath;
        const regex = props.recordLive.regex;
        const format = props.recordLive.format;

        const testPage = state.testedUrl;

        const testPagePromise = testPageParserRule(type, xpath, regex, format, testPage);

        setState({...state, fetchingResponse: true, testTitle: "", testImageUrl: "", testCanonicalUrl: "", errorMessage: ""});

        testPagePromise.catch((error: any) => {
            setState({
                ...state,
                errorMessage: "Error on webservice! " + error.message,
                fetchingResponse: false
            });
        });

        testPagePromise.then(response => {
            if (response.error) {
                setState({
                    ...state,
                    errorMessage: response.error,
                    fetchingResponse: false
                });
            }
            else {
                setState({
                    ...state,
                    testTitle: response.title_text,
                    testImageUrl: response.page_thumbnail_url,
                    testCanonicalUrl: response.canonical_url,
                    fetchingResponse: false
                });
            }
        });

    };

    return (
        <Fragment>
            <Button onClick={handleClick} style={ {backgroundColor: 'goldenrod', color: 'white'} }>
                Test rule
            </Button>

            <Dialog
                fullWidth
                open={state.showDialog}
                onClose={handleCloseClick}
                aria-label="Test parser rule on page">

                <DialogTitle>Test parser rule</DialogTitle>
                <DialogContent>
                    <Typography>Please note that <i>domains</i> and <i>priority</i> fields are not taken into account when testing</Typography>

                    <TextField label="URL" value={state.testedUrl} onChange={(e) => setState({...state, testedUrl: e.target.value})}/>

                    {state.fetchingResponse &&
                        <CircularProgress/>
                    }
                    {state.testTitle &&
                        <div>Title: {state.testTitle}</div>
                    }
                    {state.testImageUrl &&
                        <div>ImageUrl: {state.testImageUrl}</div>
                    }
                    {state.testCanonicalUrl &&
                        <div>CanonicalUrl: {state.testCanonicalUrl}</div>
                    }
                    {state.errorMessage &&
                        <div style={{color: 'red'}}>Error: {state.errorMessage}</div>
                    }
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={handleExecuteClick}>
                        Test
                        <IconSend/>
                    </Button>

                    <Button
                        onClick={handleCloseClick}>
                        Cancel
                        <IconCancel/>
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}

export default ParserRuleTestButton;
