import React from 'react';

import {
    Create,
    Datagrid,
    Edit,
    EditButton,
    List,
    NumberField,
    NumberInput,
    required,
    SimpleForm,
    TextField,
    TextInput,
} from 'react-admin';

const DecksFilter = [
    <TextInput label="Title" source="title"  />,
    <TextInput label="Owner Address" source="owner_address"  />
]

export const DecksList = () => (
    <List filters={DecksFilter}>
      <Datagrid>
        <NumberField source="id" />
        <TextField source="title" />
        <TextField source="color" />
        <NumberField source="size" />
        <TextField source="owner_address" />
        <EditButton />
      </Datagrid>
    </List>
);

const DeckTitle = ({ deck }: any) => {
  return <span>Deck {deck? `"${deck.title}" (${deck.id})` : ''}</span>
};

export const DeckCreate = () => (
    <Create title={<DeckTitle />}>
        <SimpleForm>
            <TextInput source="title" validate={required()} />
            <TextInput source="color" validate={required()} />
            <NumberInput source="size" validate={required()} />
            <TextInput source="owner_address" validate={required()} />
        </SimpleForm>
    </Create>
);

export const DeckEdit = () => (
    <Edit title={<DeckTitle />}>
        <SimpleForm>
            <NumberField source="id" />
            <TextInput source="title" validate={required()} />
            <TextInput source="color" validate={required()} />
            <NumberInput source="size" validate={required()} />
            <TextInput source="owner_address" validate={required()} />
        </SimpleForm>
    </Edit>
);
