import {useState} from 'react';

import ConfirmActionButton from '../components/shared/ConfirmActionButton';

import CircularProgress from '@mui/material/CircularProgress';

import { AdminTask } from '../components/dashboard/AdminTasks';
import { RaRecord, useRecordContext } from 'react-admin';
import { useWebservice } from '../hooks/UseWebservice';


interface Props {
  adminTask?: AdminTask | RaRecord;
  onTaskContinued?: (taskId: number) => void;
}


export default function ContinueAdminTaskButton({ adminTask, onTaskContinued }: Props) {
  
  const { continueAdminTask } = useWebservice();

  const record = useRecordContext();
  
  if (record != null) adminTask = record;
  
  const [continuingTask, setContinuingTask] = useState(false);

  const onConfirmed = async () => {
    setContinuingTask(true);
    const continuedTaskId = (await continueAdminTask((adminTask!.id) as number)).task_id;
    setContinuingTask(false);
    if (onTaskContinued != null) {
      onTaskContinued(continuedTaskId);
    }
  }
  
  return <>
    { (record && (!record.finished || record.error_msg != null)) && <>
      {
        continuingTask
        ?
        <CircularProgress />
        :
        <ConfirmActionButton 
          color="red"
          buttonLabel="continue"
          actionLabel={`Continue task: ${adminTask!.task_type}`}
          onConfirmed={onConfirmed}
        />
      }
    </>}
  </>
}
