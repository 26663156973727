import {Fragment, useState} from 'react';

import { useNotify, useRecordContext } from 'react-admin';

import Button from '@mui/material/Button';

import RemoveIcon from '@mui/icons-material/Remove';
import IconCancel from '@mui/icons-material/Cancel';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import { useWebservice } from '../hooks/UseWebservice';


interface State {
    error: boolean;
    showDialog: boolean;
    amount: number;
    submitting: boolean;
}


function RemoveXPButton() {

    const { removeXPFromWallet } = useWebservice();

    const record = useRecordContext();

    const [state, setState] = useState<State>({
        error: false,
        showDialog: false,
        amount: 0,
        submitting: false
    });

    const notify = useNotify();

    const handleClick = () => {
        setState({ ...state, showDialog: true });
    };

    const handleCloseClick = () => {
        setState({ ...state, showDialog: false });
    };

    const handleExecuteClick = () => {

        setState({ ...state, submitting: true })

        const account = record.account;
        const username = record.username;
        const xpAmount = state.amount;

        const removeXpPromise = removeXPFromWallet(account, xpAmount);

        removeXpPromise.catch(error => {
            notify("Error on webservice! " + error.message, { type: "warning" })
            setState({ ...state, showDialog: false });
        });

        removeXpPromise.then(response => {
            if (response.error) {
                notify("Error removing the experience! " + response.error, { type: "warning" })
            }
            else {
                notify(`Experience successfully removed from account ${account}${account !== username?` (${username})`:``}, current XP amount is ${response.experience}.`, { type: "info" })
            }
            setState({ ...state, showDialog: false });
        });

    };

    return (
        <Fragment>
            <Button style={ {'backgroundColor': '#ff5722', color: 'white'} } onClick={handleClick}>
                <RemoveIcon/>XP
            </Button>

            <Dialog
                fullWidth
                open={state.showDialog}
                onClose={handleCloseClick}
                aria-label="Create post">

                <DialogTitle>Remove Points Experience</DialogTitle>
                <DialogContent>
                    <TextField type="number" label="Amount To Be Removed" value={state.amount} onChange={(e) => setState({...state, amount: parseInt(e.target.value)})} />
                </DialogContent>

                <DialogActions>
                    {
                        state.submitting
                        ?
                        <CircularProgress />
                        :
                        <Button color="primary" variant="contained" onClick={handleExecuteClick}>
                            Submit
                        </Button>
                    }
                    <Button
                        onClick={handleCloseClick}
                    >
                        <IconCancel/>
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}

export default RemoveXPButton;
