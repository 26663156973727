import { useState, useEffect } from "react";
import Web3 from "web3";
import settings from "../../settings";


export const Web3Chain = Object.freeze({
  ethereum: 1, 
  matic: 2, 
  unknown: 3,
})


export default function useWeb3() {

  const [isReady, setIsReady] = useState<boolean>(false);
  const [web3, setWeb3] = useState<Web3 | null>(null);
  const [web3account, setWeb3Account] = useState<string | null>(null);
  const [web3chain, setWeb3Chain] = useState<any>(Web3Chain.unknown);

  useEffect(() => {
    const _web3 = new Web3(Web3.givenProvider);
    
    if (_web3 != null) {
      onWeb3Created(_web3)
    }
  }, []);

  const onWeb3Created = async (web3: Web3) => {
    
    const chainId = await web3.eth.net.getId()
    let chain;

    if (chainId === settings.chains.ethChainId) {
      chain = Web3Chain.ethereum;
    } else if (chainId === settings.chains.maticChainId) {
      chain = Web3Chain.matic;
    } else {
      chain = Web3Chain.unknown;
    }

    // @ts-expect-error
    await window.ethereum.enable();

    const accounts = await web3.eth.getAccounts();

    setWeb3(web3)
    setWeb3Chain(chain);
    setWeb3Account(accounts[0]);
    setIsReady(true)
  }

  return {
    isReady,
    web3,
    web3account,
    web3chain,
  }
}