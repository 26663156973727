import * as BigNumber from 'bignumber.js';
import { useState } from 'react';
import { useWebservice } from './UseWebservice';


let depositStopTracking = false;
let removeStopTracking = false;


export default function useMemecoinDepositing() {

  const { addDmemToWallet, removeDmemFromWallet, getWeb3TransactionStatus } = useWebservice();

  const [ depositState, setDepositState ] = useState<'enqueueing transaction' | 'queued' | 'pending' | 'success' | 'error' | null>(null);
  const [ depositTxId, setDepositTxId ] = useState<number | null>(null);
  const [ depositTxHash, setDepositTxHash ] = useState<string | null>(null);
  const [ depositError, setDepositError ] = useState<string | null>(null);

  const [ removeState, setRemoveState ] = useState<'enqueueing transaction' | 'queued' | 'pending' | 'success' | 'error' | null>(null);
  const [ removeTxId, setRemoveTxId ] = useState<number | null>(null);
  const [ removeTxHash, setRemoveTxHash ] = useState<string | null>(null);
  const [ removeError, setRemoveError ] = useState<string | null>(null);

  const memWei = new BigNumber.BigNumber(`1e18`);

  const memToWei = (mem: number | string | BigNumber.BigNumber) => {
    const memBN = new BigNumber.BigNumber(mem);
    return new BigNumber.BigNumber(memBN.multipliedBy(memWei));
  }

  const deposit = async (userAddress: string, memAmount: number | string | BigNumber.BigNumber, message: string) => {
    setDepositState('enqueueing transaction');

    const memWei = memToWei(memAmount);
    try {
      const result = await addDmemToWallet(userAddress, memWei.toString(10), message);
      if (!result.success) {
        setDepositState('error');
        setDepositError(result.error!);  
      } else {
        const depositTxId = result.tx_request_id;
        setDepositTxId(depositTxId!)
        setDepositState('queued')
        trackDepositState(depositTxId!)
      }
    } catch (e: any) {
      setDepositState('error');
      setDepositError(e.toString());
    }
  }

  const resetDeposit = () => {
    if (depositTxId != null) {
      depositStopTracking = true;
    }
    setDepositTxId(null);
    setDepositTxHash(null);
    setDepositState(null);
    setDepositError(null);
  }

  const trackDepositState = (txId: number) => {
    setTimeout(async () => {
      if (depositStopTracking) {
        depositStopTracking = false;
        return;
      }

      const txStatusResponse = await getWeb3TransactionStatus(txId);
      const txStatus = txStatusResponse.status;
      setDepositState(txStatus);

      if (txStatus === 'error') {
        setDepositError(`Error processing the transaction: ${depositTxId}.`)
      } else if (txStatus === 'success') {
        setDepositTxHash(txStatusResponse.transaction_hash);
      } else {
        trackDepositState(txId);
      }
    }, 2000)
  }

  const remove = async (userAddress: string, memAmount: number | string | BigNumber.BigNumber, message: string) => {
    setRemoveState('enqueueing transaction');

    const memWei = memToWei(memAmount);
    try {
      const result = await removeDmemFromWallet(userAddress, memWei.toString(10), message);
      if (!result.success) {
        setRemoveState('error');
        setRemoveError(result.error!);
      } else {
        const removeTxId = result.tx_request_id
        setRemoveTxId(removeTxId!);
        setRemoveState('queued')
        trackRemoveState(removeTxId!);
      }
    } catch (e: any) {
      setRemoveState('error');
      setRemoveError(e.toString());
    }
  }

  const resetRemove = () => {
    if (removeTxId != null) {
      removeStopTracking = true;
    }
    setRemoveTxId(null);
    setRemoveState(null);
    setRemoveError(null);
  }

  const trackRemoveState = (txId: number) => {
    setTimeout(async () => {
      if (removeStopTracking) {
        removeStopTracking = false;
        return;
      }

      const txStatusResponse = await getWeb3TransactionStatus(txId);
      const txStatus = txStatusResponse.status;
      setRemoveState(txStatus);

      if (txStatus === 'error') {
        setRemoveError(`Error processing the transaction: ${removeTxId}.`)
      } else if (txStatus === 'success') {
        setRemoveTxHash(txStatusResponse.transaction_hash)
      } else {
        trackRemoveState(txId);
      }
    }, 2000)
  }

  return {
    depositTxId,
    depositTxHash,
    depositState,
    depositError,
    deposit,
    resetDeposit,

    removeTxId,
    removeTxHash,
    removeState,
    removeError,
    remove,
    resetRemove,
  }
}
