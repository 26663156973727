import { useState, useEffect } from "react";
import useWeb3, { Web3Chain } from "./UseWeb3";
import settings from "../../settings";
import { Contract } from "web3-eth-contract"


export default function useEthAddresses() {

  const [adminAddress, setAdminAddress] = useState<string | null>(null);

  const [factoryContract, setFactoryContract] = useState<Contract | null>(null);
  const [factoryContractAddress, setFactoryContractAddress] = useState<string | null>(null);

  const [candidateContract, setCandidateContract] = useState<Contract | null>(null);
  const [candidateContractAddress, setCandidateContractAddress] = useState<string | null>(null);

  const [auctionContract, setAuctionContract] = useState<Contract | null>(null);
  const [auctionContractAddress, setAuctionContractAddress] = useState<string | null>(null);

  const [bankContract, setBankContract] = useState<Contract | null>(null);
  const [bankContractAddress, setBankContractAddress] = useState<string | null>(null);

  const [bankWithdrawAuthorizationContract, setBankWithdrawAuthorizationContract] = useState<Contract | null>(null);
  const [bankWithdrawAuthorizationContractAddress, setBankWithdrawAuthorizationContractAddress] = useState<string | null>(null);

  const [memContract, setMemContract] = useState<Contract | null>(null);
  const [memContractAddress, setMemContractAddress] = useState<string | null>(null);

  const [dmemSellerContract, setDmemSellerContract] = useState<Contract | null>(null);
  const [dmemSellerContractAddress, setDmemSellerContractAddress] = useState<string | null>(null);

  const [loading, setLoading] = useState<boolean>(true);

  const { isReady, web3chain, web3 } = useWeb3();

  useEffect(() => {

    const fetchContracts = async () => {
      let contractAddresses;
      if (web3chain === Web3Chain.ethereum) {
        contractAddresses = settings.contracts.eth;
      } else if (web3chain === Web3Chain.matic) {
        contractAddresses = settings.contracts.matic;
      } else {
        setLoading(false);
        return;
      }

      setOriginalContracts(contractAddresses.factory!, contractAddresses.mem!, contractAddresses.dmemSeller!);

      if (contractAddresses.bank != null) {
        setBankContracts(contractAddresses.bank);
      }
      
      setAdminAddress(settings.admin_wallet.address!);

      setLoading(false);
    }

    const setOriginalContracts = async (factoryContractAddress: string, memContractAddress: string, dmemSellerContractAddress: string) => {
      if (web3 == null) throw "Coud not set original contracts";

      const factoryContract = new web3.eth.Contract(
        require("../../assets/contractAbis/marbleNFTFactory.contract.abi.json"),
        factoryContractAddress
      );
  
      const candidateContractAddress = await factoryContract.methods.marbleNFTCandidateContract().call();
      const auctionContractAddress = await factoryContract.methods.marbleDutchAuctionContract().call();
      
  
      const candidateContract = new web3.eth.Contract(
        require("../../assets/contractAbis/marbleNFTCandidate.contract.abi.json"),
        candidateContractAddress
      )
  
      const auctionContract = new web3.eth.Contract(
        require("../../assets/contractAbis/marbleClockAuction.contract.abi.json"),
        auctionContractAddress
      )
  
      const memContract = new web3.eth.Contract(
        require("../../assets/contractAbis/memecoin.contract.abi.json"),
        memContractAddress
      )
  
      const dmemSellerContract = new web3.eth.Contract(
        require("../../assets/contractAbis/dmemSeller.contract.abi.json"),
        dmemSellerContractAddress
      )

      setFactoryContractAddress(factoryContractAddress);
      setCandidateContractAddress(candidateContractAddress);
      setAuctionContractAddress(auctionContractAddress);
      setMemContractAddress(memContractAddress);
      setDmemSellerContractAddress(dmemSellerContractAddress)
  
      setFactoryContract(factoryContract);
      setCandidateContract(candidateContract);
      setAuctionContract(auctionContract);
      setMemContract(memContract);
      setDmemSellerContract(dmemSellerContract);
    }
  
    const setBankContracts = async (bankContractAddress: string) => {
      if (web3 == null) throw "Coud not set bank contracts";

      const bankContract = new web3.eth.Contract(
        require("../../assets/contractAbis/marbleBank.contract.abi.json"),
        bankContractAddress
      )
  
      const bankWithdrawAuthorizationContractAddress = await bankContract.methods.withdrawAuthorization().call();
  
      const bankWithdrawAuthorizationContract = new web3.eth.Contract(
        require("../../assets/contractAbis/marbleBankWithdrawAuthorization.contract.abi.json"),
        bankWithdrawAuthorizationContractAddress
      )
      
      setBankContractAddress(bankContractAddress);
      setBankWithdrawAuthorizationContractAddress(bankWithdrawAuthorizationContractAddress);
      
      setBankContract(bankContract);
      setBankWithdrawAuthorizationContract(bankWithdrawAuthorizationContract);
    }
    
    if (isReady) {
      setLoading(true);
      fetchContracts();
    }
  }, [isReady, web3chain])

  return {
    adminAddress,
    
    factoryContractAddress,
    candidateContractAddress,
    auctionContractAddress,
    bankContractAddress,
    bankWithdrawAuthorizationContractAddress,
    memContractAddress,
    dmemSellerContractAddress,

    factoryContract,
    candidateContract,
    auctionContract,
    bankContract,
    bankWithdrawAuthorizationContract,
    memContract,
    dmemSellerContract,

    loading,
  }
  
}