import { useState, useEffect } from "react";

import useEthAddresses from "./UseEthAddresses";
import useWeb3 from "./UseWeb3";

import * as BigNumber from "bignumber.js";


export default function useEthBalances() {

  const [adminBalance, setAdminBalance] = useState<BigNumber.BigNumber>(new BigNumber.BigNumber(0))
  const [adminMEMBalance, setAdminMEMBalance] = useState<BigNumber.BigNumber>(new BigNumber.BigNumber(0))
  const [factoryBalance, setFactoryBalance] = useState<BigNumber.BigNumber>(new BigNumber.BigNumber(0))
  const [factoryMEMBalance, setFactoryMEMBalance] = useState<BigNumber.BigNumber>(new BigNumber.BigNumber(0))
  const [candidateBalance, setCandidateBalance] = useState<BigNumber.BigNumber>(new BigNumber.BigNumber(0))
  const [candidateMEMBalance, setCandidateMEMBalance] = useState<BigNumber.BigNumber>(new BigNumber.BigNumber(0))
  const [auctionBalance, setAuctionBalance] = useState<BigNumber.BigNumber>(new BigNumber.BigNumber(0))
  const [auctionMEMBalance, setAuctionMEMBalance] = useState<BigNumber.BigNumber>(new BigNumber.BigNumber(0))
  const [bankBalance, setBankBalance] = useState<BigNumber.BigNumber>(new BigNumber.BigNumber(0))
  const [bankMEMBalance, setBankMEMBalance] = useState<BigNumber.BigNumber>(new BigNumber.BigNumber(0))
  const [bankWithdrawAuthorizationBalance, setBankWithdrawAuthorizationBalance] = useState<BigNumber.BigNumber>(new BigNumber.BigNumber(0))
  const [bankWithdrawAuthorizationMEMBalance, setBankWithdrawAuthorizationMEMBalance] = useState<BigNumber.BigNumber>(new BigNumber.BigNumber(0))
  const [dmemSellerBalance, setDmemSellerBalance] = useState<BigNumber.BigNumber>(new BigNumber.BigNumber(0))
  const [dmemSellerMEMBalance, setDmemSellerMEMBalance] = useState<BigNumber.BigNumber>(new BigNumber.BigNumber(0))
  const [loading, setLoading] = useState<boolean>(true)

  const { web3 } = useWeb3();
  const { 
    loading: loadingContracts, 
    adminAddress,
    factoryContractAddress,
    candidateContractAddress, 
    auctionContractAddress, 
    bankContractAddress, 
    bankWithdrawAuthorizationContractAddress,
    dmemSellerContractAddress,
    memContract,
  } = useEthAddresses()

  useEffect(() => {
    const fetchBalances = async() => {
      const adminBalance = adminAddress? await web3!.eth.getBalance(adminAddress) : null;
      const adminMEMBalance = adminAddress? await memContract!.methods.balanceOf(adminAddress).call() : null;
      const factoryBalance = factoryContractAddress? await web3!.eth.getBalance(factoryContractAddress) : null;
      const factoryMEMBalance = factoryContractAddress? await memContract!.methods.balanceOf(factoryContractAddress).call() : null;
      const candidateBalance = candidateContractAddress? await web3!.eth.getBalance(candidateContractAddress) : null;
      const candidateMEMBalance = candidateContractAddress? await memContract!.methods.balanceOf(candidateContractAddress).call() : null;
      const auctionBalance = auctionContractAddress? await web3!.eth.getBalance(auctionContractAddress) : null;
      const auctionMEMBalance = auctionContractAddress? await memContract!.methods.balanceOf(auctionContractAddress).call() : null;
      const bankBalance = bankContractAddress? await web3!.eth.getBalance(bankContractAddress) : null;
      const bankMEMBalance = bankContractAddress? await memContract!.methods.balanceOf(bankContractAddress).call() : null;
      const bankWithdrawAuthorizationBalance = bankWithdrawAuthorizationContractAddress? await web3!.eth.getBalance(bankWithdrawAuthorizationContractAddress) : null;
      const bankWithdrawAuthorizationMEMBalance = bankWithdrawAuthorizationContractAddress? await memContract!.methods.balanceOf(bankWithdrawAuthorizationContractAddress).call() : null;
      const dmemSellerBalance = dmemSellerContractAddress? await web3!.eth.getBalance(dmemSellerContractAddress) : null;
      const dmemSellerMEMBalance = dmemSellerContractAddress? await memContract!.methods.balanceOf(dmemSellerContractAddress).call() : null;
      
      if (adminBalance != null) setAdminBalance(new BigNumber.BigNumber(adminBalance));
      setAdminMEMBalance(new BigNumber.BigNumber(adminMEMBalance));
      if (factoryBalance != null) setFactoryBalance(new BigNumber.BigNumber(factoryBalance));
      setFactoryMEMBalance(new BigNumber.BigNumber(factoryMEMBalance));
      if (candidateBalance != null) setCandidateBalance(new BigNumber.BigNumber(candidateBalance));
      setCandidateMEMBalance(new BigNumber.BigNumber(candidateMEMBalance));
      if (auctionBalance != null) setAuctionBalance(new BigNumber.BigNumber(auctionBalance));
      setAuctionMEMBalance(new BigNumber.BigNumber(auctionMEMBalance));
      if (bankBalance != null) setBankBalance(new BigNumber.BigNumber(bankBalance));
      setBankMEMBalance(new BigNumber.BigNumber(bankMEMBalance));
      if (bankWithdrawAuthorizationBalance != null) setBankWithdrawAuthorizationBalance(new BigNumber.BigNumber(bankWithdrawAuthorizationBalance));
      setBankWithdrawAuthorizationMEMBalance(new BigNumber.BigNumber(bankWithdrawAuthorizationMEMBalance));
      if (dmemSellerBalance != null) setDmemSellerBalance(new BigNumber.BigNumber(dmemSellerBalance));
      setDmemSellerMEMBalance(new BigNumber.BigNumber(dmemSellerMEMBalance));
  
      setLoading(false);
    }

    if (!loadingContracts && web3 !== null && memContract !== null) {
      fetchBalances();
    }
  }, [
    factoryContractAddress, candidateContractAddress, auctionContractAddress, 
    loadingContracts, web3, memContract, adminAddress, bankContractAddress,
    bankWithdrawAuthorizationContractAddress, dmemSellerContractAddress
  ])

  return {
    loading,
    adminBalance, adminMEMBalance,
    factoryBalance, factoryMEMBalance,
    candidateBalance, candidateMEMBalance,
    auctionBalance, auctionMEMBalance,
    bankBalance, bankMEMBalance,
    bankWithdrawAuthorizationBalance, bankWithdrawAuthorizationMEMBalance,
    dmemSellerBalance, dmemSellerMEMBalance,
  }

}