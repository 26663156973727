import React from 'react';

import {
    Create,
    Datagrid,
    Edit,
    EditButton,
    List,
    NumberField,
    required,
    SimpleForm,
    TextField,
    TextInput,
    SelectInput,
    NumberInput,
    ReferenceArrayInput,
    SelectArrayInput,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    FormDataConsumer,
    ReferenceInput,
    useRecordContext
} from 'react-admin';

import ParserRuleTestButton from './../../buttons/parserRuleTestButton'
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import ApplyParserRule from './../../buttons/applyParserRule';


const parserRuleFilters = [
    <TextInput label="Title" source="title_like" />,
    <SelectInput label="Type" source="rule_type" validate={required()} choices={[
        { id: "TITLE", name: "Title" },
        { id: "IMAGE_URL", name: "Image URL" },
        { id: "CANONICAL_URL", name: "Canonical URL" },
    ]} />,
    <ReferenceInput label="Domain" reference="allowed_domains" source="domains.id" perPage={1000} sort={{ field:'display_name', order: 'ASC' }}>
        <SelectInput optionText="display_name" />
    </ReferenceInput>
];


export const ParserRulesList = () => (
    <List filters={parserRuleFilters}>
        <Datagrid>
            <NumberField source="id" />
            <TextField source="title" />
            <TextField source="rule_type" />
            <ReferenceArrayField label="domains" reference="allowed_domains" source="domains" sort={{ field: 'id', order: 'DESC' }}>
                <SingleFieldList>
                    <ChipField source="display_name" />
                </SingleFieldList>
            </ReferenceArrayField>
            <NumberField source="priority" />
            <TextField source="xpath" />
            <TextField source="regex" />
            <TextField source="format" />
            <EditButton />
            <ApplyParserRule />
        </Datagrid>
    </List>
);

const ParserRuleTitle = () => {
    const parserRule = useRecordContext();
    return <span>Parser rule {parserRule? `"${parserRule.title}" (${parserRule.id})` : ''}</span>
};

export const ParserRuleCreate = () => (
    <Create title={<ParserRuleTitle />}>
        <SimpleForm>
            <Description />
            <TextInput source="title" validate={required()}/>
            <SelectInput source="rule_type" validate={required()} choices={[
                { id: "TITLE", name: "Title" },
                { id: "IMAGE_URL", name: "Image URL" },
                { id: "CANONICAL_URL", name: "Canonical URL" },
            ]} />
            <ReferenceArrayInput label="domains" reference="allowed_domains" source="domains" perPage={1000} sort={{ field:'display_name', order: 'ASC' }}>
                <SelectArrayInput optionText="display_name" />
            </ReferenceArrayInput>
            <NumberInput source="priority" validate={required()} min="1" max="100"/>
            <TextInput source="xpath" />
            <TextInput source="regex" />
            <TextInput source="format" />
            <ParserRuleTestButton />
        </SimpleForm>
    </Create>
);

export const ParserRuleEdit = () => (
    <Edit title={<ParserRuleTitle />}>
        <SimpleForm>
            <Description />
            <TextInput source="title" validate={required()}/>
            <SelectInput source="rule_type" validate={required()} choices={[
                { id: "TITLE", name: "Title" },
                { id: "IMAGE_URL", name: "Image URL" },
                { id: "CANONICAL_URL", name: "Canonical URL" },
            ]} />
            <ReferenceArrayInput label="domains" reference="allowed_domains" source="domains" perPage={1000} sort={{ field:'display_name', order: 'ASC' }}>
                <SelectArrayInput optionText="display_name" />
            </ReferenceArrayInput>
            <NumberInput source="priority" min="1" max="100"/>
            <TextInput source="xpath" />
            <TextInput source="regex" />
            <TextInput source="format" />

            <FormDataConsumer>
                {({ formData, ...rest }) => (
                    <ParserRuleTestButton recordLive={formData}/>
                )}
            </FormDataConsumer>
        </SimpleForm>
    </Edit>
);

const Description = () => (
    <Card>
        <Typography variant="h2">How to use parser rules?</Typography>

        <Typography variant="h3">1. Find title or image on a page</Typography>
        <Typography>
            Our crawler goes through HTML code of each page that is going to be Marble and looks for image, title or canonical URL on standard places.
            However, a page does not need to have these on standard places. If it has it in some non-standard place in the page, our crawler cannot know
            about it. This is where parser rules come handy. In <i>xpath</i> field you can speciify xpath to the required field, in <i>domains</i> you 
            can specify for each domains this rule should be used and finally in <i>rule type</i> you can specify what field this rule is looking for.
        </Typography>

        <Typography variant="h3">2. Change title on a page</Typography>
        <Typography>
            We may need to change some titles (e.g. because of trademarks like GIPHY). This can be solved using parser rules.
            To achieve this, in <i>rule type</i> field select <i>title</i>, in <i>domains</i> field select for which domains the rule should be used,
            in <i>regex</i> field specify regex which needs to be matched and in <i>format</i> field specify the resulting title. The title will be 
            changed <b>only if</b> the regex is matched. <br></br>
            
            Example 1:<br></br>
            To change a title containing word <i>GIPHY</i> to <i>Not allowed title</i> use the following values:<br></br>
            <i>regex:</i> .*GIPHY.*  [<i>.*</i> matches any character any number of times, then we match GIPHY word and then again anything]<br></br>
            <i>format:</i> Not allowed title [the resulting title]<br></br>

            Example 2:<br></br>
            To change a title containing word <i>GIPHY</i> in case insensitive way (e.g. match also <i>giphy</i> and <i>Giphy</i> or even <i>GiPhY</i>) to <i>Not allowed title</i> use the following values<br></br>
            <i>regex:</i> .*[Gg][Ii][Pp][Hh][Yy].*  [<i>[Gg]</i> matches character G or g]<br></br>
            <i>format:</i> Not allowed title [the resulting title]<br></br>

            Example 3:<br></br>
            Usually, you'll want to use parts of the original title in the resulting title. This is where regex groups come in handy. You can mark parts of the original title in a so called group and then reference it in the format field. 
            This example replacec word GIPHY to word GIFSITE:<br></br>
            <i>regex:</i> (.*)GIPHY(.*)  [groups are specified using round brackets (parentheses). The first group here will containin everything before the word GIPHY and the other everything after the word]<br></br>
            <i>format:</i> {"{"}0{"}"}GIFSITE{"{"}1{"}"}  [numbers in curly brackets reference groups from the regex, indexed from 0]<br></br>
        </Typography>

        <Typography variant="h3">3. Final notes</Typography>
        <Typography>
            <i>1.</i> leaving domain field empty will result in the rule being applied for every page<br></br>
            <i>2.</i> some rules may clash, that's why they have priority. Rules with higher priority are executed first. 
                Higher priority has <b>lower</b> number, e.g. rule with priority 2 has larger priority than rule with priority 5.
            <i>3.</i> <i>title</i> field specify no functionality, its only for you so you can describe the rule and easily recognize what it does in the list of parser rules.
        </Typography>
    </Card>
)
