import { List, Edit, Datagrid, TextField, EditButton, NumberField, SimpleForm,
    TextInput, required } from 'react-admin';

export const ArenaConfigList = () => (
    <List>
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="value" />
            <EditButton />
        </Datagrid>
    </List>
);

const ArenaConfigTitle = ({ appConfig }: any) => {
    return <span>Configuration {appConfig ? `"${appConfig.id}" (${appConfig.name})` : ''}</span>
};

export const ArenaConfigEdit = () => (
    <Edit title={<ArenaConfigTitle />}>
        <SimpleForm validate={syncValidate} >
            <NumberField source="id"/>
            <TextField source="name"/>
            <TextInput source="value" validate={required()}/>
        </SimpleForm>
    </Edit>
);


const syncValidate = (values: any) => {
    const config_value = values.value;
    switch (values.value_type) {
        case "INTEGER":
            if (!config_value.match(/^-?\d+$/)) {
                return {'value': 'Not an integer'}
            }
            break;
        case "DECIMAL_PREC_10":
            if (isNaN(config_value)) {
                return {'value': 'Not a float'}
            }
            break;
        default:
            break;
    }

    return {};
};
