import { useRecordContext } from "react-admin";
import { useWebservice } from "../../../../hooks/UseWebservice";
import ConfirmActionButton from "../../../shared/ConfirmActionButton";


export default function RescrapeCardThumbnailButton() {

  const { rescrapeCardImage } = useWebservice();
  const record = useRecordContext();

  const onActionConfirmed = async (record: any) => {
    await rescrapeCardImage(record.nft_id);
  }

  return (<>
    <ConfirmActionButton 
      color="violet" 
      buttonLabel="Rescrape Card Thumbnail"
      onConfirmed={onActionConfirmed}
      onSuccess="Card thumbnail image resraped"
      dialogTitle="Rescrape Card Thumbnail"
      actionLabel={`Rescrape card thumbnail image for Marble NFT #${record.nft_id}`}
      style={{height: "100%", width: "100%"}}
    />
  </>)
}