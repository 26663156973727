import { List, Edit, Datagrid, TextField, EditButton, NumberField, SimpleForm,
    TextInput, required } from 'react-admin';
import useAppConfigChangeValidation from '../../hooks/UseAppConfigChangeValidation';


export const WalletExperienceConfigList = () => (
    <List>
        <Datagrid>
		        <TextField source="id" />
            <TextField source="name" />
            <TextField source="value" />
            <EditButton />
        </Datagrid>
    </List>
);

const WalletExperienceConfigTitle = ({ walletExperienceConfig }: any) => {
    return <span>Configuration {walletExperienceConfig ? `"${walletExperienceConfig.id}" (${walletExperienceConfig.name})` : ''}</span>
};

export const WalletExperienceConfigEdit = () => {
    const { validation } = useAppConfigChangeValidation();
    
    return (
        <Edit title={<WalletExperienceConfigTitle />}>
            <SimpleForm validate={validation}>
                <NumberField source="id"/>
                <TextField source="name"/>
                <TextInput source="value" validate={required()}/>
            </SimpleForm>
        </Edit>
    );
}
