import { useRecordContext } from "react-admin";
import ConfirmActionButton from "../../../shared/ConfirmActionButton";
import { useWebservice } from "../../../../hooks/UseWebservice";


interface Props {
  disabled?: boolean
}


export default function RemoveSignatureButton(props: Props) {

  const { removeSignature } = useWebservice();
  const record = useRecordContext()

  const onConfirmed = async () => {
    await removeSignature(record.nft_id);
  }

  return (
    <ConfirmActionButton 
      style={{width: "100%", height: "100%", backgroundColor: props.disabled? "lightgray" : "IndianRed"}} 
      disabled={props.disabled === true}
      dialogTitle="Remove signature"
      actionLabel={`Remove signature from #${record.nft_id}`}
      buttonLabel="Remove signature"
      onSuccess="Signature removed"
      onConfirmed={onConfirmed}
    />
  )
}