import { List, Edit, Create, Datagrid, TextField, EditButton, NumberField, SimpleForm, TextInput,
    required } from 'react-admin';

export const LevelTypeList = () => (
    <List>
        <Datagrid>
			<TextField source="id" />
            <TextField source="name" />
            <TextField source="max_rank" />
            <TextField source="max_level" />
            <EditButton />
        </Datagrid>
    </List>
);

const LevelTypeTitle = ({ levelType }: any) => {
    return <span>Level type {levelType ? `"${levelType.name}" (${levelType.id})` : ''}</span>
};

const validateLevelType = () => {
    return {};
};

export const LevelTypeCreate = () => (
    <Create title={<LevelTypeTitle />}>
        <SimpleForm validate={validateLevelType}>
            <TextInput source="name" validate={required()}/>
            <TextInput source="max_rank" validate={required()} />
            <TextInput source="max_level" validate={required()} />
        </SimpleForm>
    </Create>
);

export const LevelTypeEdit = () => (
    <Edit title={<LevelTypeTitle />}>
        <SimpleForm validate={validateLevelType} >
            <NumberField source="id" />
            <TextInput source="name" validate={required()}/>
            <TextInput source="max_rank" validate={required()} />
            <TextInput source="max_level" validate={required()} />
        </SimpleForm>
    </Edit>
);
