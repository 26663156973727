import { List, Edit, Create, Datagrid, TextField, EditButton, NumberField, SimpleForm,
    TextInput, required } from 'react-admin';

const inputStyle = { width: '1000px' };

export const ArenaBlacklistList = () => (
    <List>
        <Datagrid>
            <TextField source="id" />
            <TextField source="voter_address" />
            <TextField source="card_owner_address" />
            <EditButton />
        </Datagrid>
    </List>
);

const ArenaBlacklistTitle = ({ blackList }: any) => {
    return <span>Blacklist {blackList ? `"${blackList.id}"` : ''}</span>
};

export const ArenaBlacklistEdit = () => (
    <Edit title={<ArenaBlacklistTitle />}>
        <SimpleForm>
            <NumberField source="id"/>
            <TextInput source="voter_address" label="Voter Address (ETH address of the voter, whose votes should be blacklisted)"
                       validate={required()} style={inputStyle} />
            <TextInput source="card_owner_address" label="Card Owner Address (ETH address of a user, on whose cards the voter is banned to vote.
                       If left blank, all votes of the voter will be banned)" style={inputStyle} />
        </SimpleForm>
    </Edit>
);

export const ArenaBlacklistCreate = () => (
    <Create title={<ArenaBlacklistTitle />}>
        <SimpleForm>
            <TextInput source="voter_address" label="Voter Address (ETH address of the voter, whose votes should be blacklisted)"
                       validate={required()} style={inputStyle} />
            <TextInput source="card_owner_address" label="Card Owner Address (ETH address of a user, on whose cards the voter is banned to vote.
                       If left blank, all votes of the voter will be banned)" style={inputStyle} />
        </SimpleForm>
    </Create>
);
