import {
    Create,
    Datagrid,
    TextInput,
    Edit,
    EditButton,
    List,
    NumberField,
    NumberInput,
    required,
    SelectInput,
    SimpleForm,
    TextField,
} from 'react-admin';

export const BackgroundGeneratorsList = () => (
    <List>
        <Datagrid>
			<NumberField source="id" />
            <TextField source="generator_type" />
            <NumberField source="from_nft_id" />
            <NumberField source="to_nft_id" />
            <TextField source="parameters" label="Additional parameters" />
            <EditButton />
        </Datagrid>
    </List>
);

const BackgroundGeneratorTitle = ({ generator }: any) => {
    return <span>Background generator {generator? `"${generator.generator_type}" (${generator.id})` : ''}</span>
};

export const BackgroundGeneratorCreate = () => (
    <Create title={<BackgroundGeneratorTitle />}>
        <SimpleForm>
            <SelectInput source="generator_type" validate={required()} choices={[
                { id: "CUSTOM", name: "Custom Image" },
                { id: "LITHIUM", name: "Lithium (gen0)" },
                { id: "BERYLLIUM", name: "Beryllium (gen1)" },
                { id: "BERYLLIUM_PLUS", name: "Beryllium v2 (gen1)" },
                { id: "BORON", name: "Boron (gen2)" },
            ]} />
            <NumberInput source="from_nft_id" validate={required()} />
            <NumberInput source="to_nft_id" />
            <TextInput source="parameters" label="Additional parameters" />
        </SimpleForm>
    </Create>
);

export const BackgroundGeneratorEdit = () => (
    <Edit title={<BackgroundGeneratorTitle />}>
        <SimpleForm>
            <NumberField source="id" />
            <SelectInput source="generator_type" validate={required()} choices={[
                { id: "CUSTOM", name: "Custom Image" },
                { id: "LITHIUM", name: "Lithium (gen0)" },
                { id: "BERYLLIUM", name: "Beryllium (gen1)" },
                { id: "BERYLLIUM_PLUS", name: "Beryllium v2 (gen1)" },
                { id: "BORON", name: "Boron (gen2)" },
            ]} />
            <NumberInput source="from_nft_id" validate={required()} />
            <NumberInput source="to_nft_id" />
            <TextInput source="parameters" label="Additional parameters" />
        </SimpleForm>
    </Edit>
);
