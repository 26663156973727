import { List, Edit, Create, Datagrid, TextField, EditButton, DateTimeInput, SimpleForm, TextInput, BooleanField, BooleanInput, required, NumberField } from 'react-admin';
import { UTCDateTimeInput } from '../shared/UTCDateTimeInput';
import {TimestampField, timestampUTCParser } from "./../../timestamp";

const allowedDomainsListFilters = [
    <TextInput label="Domain" source="domain_ilike"/>,
    <TextInput label="Display name" source="display_name_ilike"/>,
    <BooleanInput label="ScrapingBee - JS off" source="use_scrapingbee"/>,
    <BooleanInput label="ScrapingBee - Premium" source="use_scrapingbee_premium"/>,
    <BooleanInput label="Active" source="active"/>,
];


export const AllowedDomainsList = () => (
    <List filters={allowedDomainsListFilters}>
        <Datagrid>
            <NumberField source="id" />
            <TextField source="domain" />
            <TextField style={{ color: 'purple', width: '150px', overflow: 'hidden' }} source="path_regex" />
            <TextField source="display_name"/>
            <TextField source="default_link"/>
            <BooleanField source="exclusive" />
            <BooleanField source="use_proxy" />
            <BooleanField source="unique_title" />
            <BooleanField source="cloudflare_protected" />
            <BooleanField source="use_scrapingbee" label="ScrapingBee - JS off" />
            <BooleanField source="use_scrapingbee_premium" label="ScrapingBee - Premium" />
            <BooleanField source="is_allowed_without_image" />
            <BooleanField source="active" />
            <TimestampField source="allowed_from" label="Allowed From (UTC Time)" />
            <EditButton />
        </Datagrid>
    </List>
);

const AllowedDomainTitle = ({ allowedDomain }: any) => {
    return <span>Allowed domain {allowedDomain ? `"${allowedDomain.domain}" (${allowedDomain.id})` : ''}</span>
};

const validateAllowedDomain = (values: any) => {
    return {};
};

export const AllowedDomainCreate = () => (
    <Create title={<AllowedDomainTitle />}>
        <SimpleForm validate={validateAllowedDomain}>
            <TextInput source="domain" validate={required()}/>
            <TextInput source="path_regex" />
            <TextInput source="display_name" />
            <TextInput source="default_link"/>
            <BooleanInput source="exclusive" />
            <BooleanInput source="use_proxy" validate={required()} defaultValue={false}/>
            <BooleanInput source="unique_title" validate={required()} defaultValue={false}/>
            <BooleanInput source="cloudflare_protected" validate={required()} defaultValue={false} />
            <BooleanInput source="use_scrapingbee" validate={required()} defaultValue={false} label="ScrapingBee - JS off" />
            <BooleanInput source="use_scrapingbee_premium" validate={required()} defaultValue={false} label="ScrapingBee - Premium" />
            <BooleanInput source="is_allowed_without_image" validate={required()} defaultValue={false} />
            <BooleanInput source="active" />
            <UTCDateTimeInput source="allowed_from" label="Allowed from (UTC time)" />
        </SimpleForm>
    </Create>
);

export const AllowedDomainEdit = () => (
    <Edit title={<AllowedDomainTitle />}>
        <SimpleForm validate={validateAllowedDomain}>
            <NumberField source="id" />
            <TextInput source="domain" validate={required()} />
            <TextInput source="path_regex" />
            <TextInput source="display_name" />
            <TextInput source="default_link"/>
            <BooleanInput source="exclusive" />
            <BooleanInput source="use_proxy" validate={required()} defaultValue={false}/>
            <BooleanInput source="unique_title" validate={required()} defaultValue={false}/>
            <BooleanInput source="cloudflare_protected" validate={required()} defaultValue={false} />
            <BooleanInput source="use_scrapingbee" validate={required()} defaultValue={false} label="ScrapingBee - JS off" />
            <BooleanInput source="use_scrapingbee_premium" validate={required()} defaultValue={false} label="ScrapingBee - Premium" />
            <BooleanInput source="is_allowed_without_image" validate={required()} defaultValue={false} />
            <BooleanInput source="active" />
            <UTCDateTimeInput source="allowed_from" label="Allowed from (UTC time)" />
        </SimpleForm>
    </Edit>
);
