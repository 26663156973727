import * as BigNumber from "bignumber.js";
import useEthAddresses from "./UseEthAddresses";
import useWeb3 from "./UseWeb3";


export default function useMemecoinContract() {

  const { web3account } = useWeb3();
  const { memContract } = useEthAddresses();

  const getAllowance = async (ownerAddress: string, spenderAddress: string) => {
    if (memContract == null || ownerAddress == null || spenderAddress == null) {
      return null;
    }

    return await memContract.methods.allowance(ownerAddress, spenderAddress).call();
  }

  const increaseAllowance = async function (spenderAddress: string, amount: BigNumber.BigNumber) {
    if (memContract == null || spenderAddress == null || amount == null) {
      return null;
    }

    return await memContract.methods.increaseAllowance(spenderAddress, amount.toString(10)).send({ from: web3account });
  }

  return {
    getAllowance,
    increaseAllowance
  }

}