import * as BigNumber from "bignumber.js";


export default function useWeiConversions() {

  const weiInEth = new BigNumber.BigNumber(`1${"0".repeat(18)}`);
  const weiInMem = new BigNumber.BigNumber(`1${"0".repeat(18)}`);

  const weiToEth = (wei: number | string | BigNumber.BigNumber, decimals: number | null = null) => {
    return divideAnyBy(wei, weiInEth, decimals);
  }

  const weiToMem = (wei: number | string | BigNumber.BigNumber, decimals: number | null = null) => {
    return divideAnyBy(wei, weiInMem, decimals);
  }

  const memToWei = (mem: number | string | BigNumber.BigNumber) => {
    return mem == null? null : (new BigNumber.BigNumber(mem)).multipliedBy(weiInMem);
  }

  const divideAnyBy = (valueToDivide: number | string | BigNumber.BigNumber, divideBy: BigNumber.BigNumber, decimals: number | null = null) => {
    if (valueToDivide == null) {
      return null;
    }
    
    let bigNumberValue;
    
    if (typeof valueToDivide === 'number' || typeof valueToDivide === 'string') {
      bigNumberValue = new BigNumber.BigNumber(valueToDivide);
    } else {
      bigNumberValue = valueToDivide;
    }

    let resultValue = bigNumberValue.dividedBy(new BigNumber.BigNumber(divideBy));

    if (decimals != null) {
      resultValue = resultValue.decimalPlaces(decimals);
    }

    return resultValue;
  }

  return {
    weiToEth,
    weiToMem,

    memToWei,

    oneMemToWei: weiInMem
  }

}
