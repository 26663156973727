import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import ConfirmActionButton from "../shared/ConfirmActionButton";
import { useWebservice } from "../../hooks/UseWebservice";
import { DomainResponse } from "../../webservice/models";


interface State {
  startNftId: number | string;
  endNftId: number | string;
  domainIds: number[];

  processingRequest: boolean;
  domainOptions: DomainResponse[];
}


export default function OpenseaNotifications() {

  const { getAllDomains, notifyOpenseaAboutNftUpdates } = useWebservice();

  const [state, setState] = useState<State>({
    startNftId: '',
    endNftId: '',
    domainIds: [],

    processingRequest: false,
    domainOptions: []
  });

  useEffect(() => {
    const fetchDomains = async () => {
      const domains = await getAllDomains();
      setState(state => ({
        ...state, 
        domainOptions: domains
      }))
    }

    fetchDomains();
  }, [])

  const setStartNftId = (startNftId: number) => setState({...state, startNftId: startNftId});
  const setEndNftId = (endNftId: number) => setState({...state, endNftId: endNftId});
  const setDomainIds = (domainIds: number[]) => setState({...state, domainIds: domainIds});
  const setProcessingRequest = (value: boolean) => setState({...state, processingRequest: value});

  const onSubmit = async (record: any) => {
    setProcessingRequest(true);
    await notifyOpenseaAboutNftUpdates(state.startNftId, state.endNftId, state.domainIds);
  }

  return (
    <Card>
      <CardHeader title="OpenSea Update"></CardHeader>
      <CardContent>
        <TextField 
          type="number"
          label="Start NFT ID"
          value={state.startNftId}
          onChange={(e) => setStartNftId(parseInt(e.target.value))}
        />
        
        <TextField
          type="number"
          label="End NFT ID"
          value={state.endNftId}
          onChange={(e) => setEndNftId(parseInt(e.target.value))}
        />

        <Box style={{margin: "10px 0"}}>
          <FormControl variant="outlined" style={{width: "100%"}}>
            <InputLabel id="domains-input-label">Domains</InputLabel>
            <Select
              labelId="domains-input-label"
              input={<OutlinedInput label="Domains" />}
              multiple
              value={state.domainIds}
              onChange={(e) => setDomainIds(e.target.value as number[])}
              renderValue={(selected: unknown) =>
                (selected as number[]).map((domainId: number) => state.domainOptions.find((domain: DomainResponse) => domain.id === domainId)).map((domain: DomainResponse | undefined) => (
                  <Box key={domain?.id}><Chip label={domain?.domain_display_name ?? domain?.domain} /></Box>
                ))}
            >
              {state.domainOptions.map((domain) => (
                <MenuItem key={domain.id} value={domain.id}>
                  {domain.domain_display_name ?? domain.domain}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <ConfirmActionButton 
          color="red"
          buttonLabel="Notify OpenSea" 
          dialogTitle="Notify OpenSea?" 
          actionLabel="Schedule OpenSea notifications" 
          onSuccess="Notifications successfully scheduled. Please note that we do not want to spam or overload opensea, so the notifications will be sent over some time, not all at once"
          onConfirmed={onSubmit}>
        </ConfirmActionButton>
      </CardContent>
    </Card>
  )

}
