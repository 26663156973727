import { useRecordContext } from "react-admin";

interface Props {
    source: string;
    label?: string;
}

export const TimestampField = ({ source }: Props) => {
    const record = useRecordContext();

    const value = record[source];
    if (value == null) return null;

    let dateString = "";
    if (typeof value == "string") {
        dateString = value;
        dateString = dateString.replace("T", " ");
    }

    return (
        <span>
            {dateString}
        </span>
    );
};

export const timestampUTCParser = (value: string) => {
    const date = new Date(value);
    const pad = '00';
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();

    return `${year}-${(pad + month).slice(-2)}-${(pad + day).slice(-2)}T${(pad + hour).slice(-2)}:${(pad + minute).slice(-2)}`;
};
