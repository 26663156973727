import React, { useEffect, useState } from 'react';
import { useNotify, useRecordContext } from 'react-admin';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';


interface Props {
  color?: string;
  disabled?: boolean;
  buttonLabel?: string;
  dialogTitle?: string;
  actionLabel?: string;
  onSuccess?: string;
  children?: any; // TODO: type
  style?: object;

  onConfirmed: (record: any) => void;
}


interface State {
  dialogOpen: boolean;
  color: string;
  buttonLabel: string | null;
  confirmActionInProgress: boolean;
}


function ConfirmActionButton(props: Props) {
  const record = useRecordContext();

  const [state, setState] = useState<State>({
    dialogOpen: false,
    color: 'default',
    buttonLabel: null,
    confirmActionInProgress: false
  });

  const notify = useNotify();

  useEffect(() => {
    const activeColor = props.color? props.color : 'primary';
    setState(state => ({
      ...state,
      color: props.disabled? 'default' : activeColor,
      buttonLabel: props.buttonLabel? props.buttonLabel : 'Do it!'
    }))
  }, [props.color, props.disabled, props.buttonLabel])

  const onButtonClicked = () => {
    setState({...state, dialogOpen: true})
  }

  const onCancel = () => {
    setState({...state, dialogOpen: false})
  }

  const onConfirmed = async () => {
    setState({...state, confirmActionInProgress: true});
    try {
      await props.onConfirmed(record);
      notify(props.onSuccess ? props.onSuccess : "Action successful", { type: "success" })
    } catch (e) {
      notify("Action failed", { type: "error" })
      console.error(e);
    } finally {
      setState({...state, dialogOpen: false, confirmActionInProgress: false});
    }
  }

  return <>
    <Button
      onClick={() => onButtonClicked()}
      style={{backgroundColor: state.color, color: 'white', ...props.style}}
      variant="contained"
      disabled={props.disabled}
    >
      {state.buttonLabel}
    </Button>

    <Dialog
      disableEscapeKeyDown
      open={state.dialogOpen}
    >
      <DialogTitle>{props.dialogTitle ? props.dialogTitle : "Confirm action"}</DialogTitle>
      <DialogContent>
        <Typography>Please confirm the following action: {props.actionLabel ? props.actionLabel : 'Action'}</Typography>
        {props.children}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onCancel} variant="contained">Cancel</Button>
        {
          state.confirmActionInProgress
          ?
          <CircularProgress size={16} />
          :
          <Button onClick={onConfirmed} style={{backgroundColor: state.color, color: 'white'}} variant="contained">{state.buttonLabel}</Button>
        }

      </DialogActions>
    </Dialog>
  </>
}

export default ConfirmActionButton;
