import { List, Edit, Datagrid, TextField, EditButton, NumberField, SimpleForm, DateField } from 'react-admin';


export const UserList = () => (
    <List>
        <Datagrid>
            <TextField source="name" />
            <DateField source="created" />
            <EditButton />
        </Datagrid>
    </List>
);

const UserName = ({ user }: any) => {
    return <span>User {user ? `"${user.name}"` : ''}</span>
};

export const UserEdit = () => (
    <Edit title={<UserName />}>
        <SimpleForm>
            <NumberField source="id" />
            <TextField source="name" />
            <TextField source="created" />
        </SimpleForm>
    </Edit>
);
