import { useRecordContext } from "react-admin";
import { useWebservice } from "../../../../hooks/UseWebservice";
import ConfirmActionButton from "../../../shared/ConfirmActionButton";


export default function RemoveArtCreatorAutographButton() {

  const { removeArtCretorAutograph } = useWebservice();

  const record = useRecordContext()

  const onConfirmed = async () => {
    await removeArtCretorAutograph(record.nft_id);
  }

  return (
    <ConfirmActionButton 
      style={{width: "100%", height: "100%"}} 
      color="Salmon"
      disabled={!record.is_verified}
      dialogTitle="Remove art creator autograph"
      actionLabel={`Remove art creator autograph from #${record.nft_id}`}
      buttonLabel="Remove art creator autograph"
      onSuccess="Autograph removed"
      onConfirmed={onConfirmed}
    />
  )
}