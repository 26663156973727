import { List, Edit, Create, Datagrid, NumberField, TextField, SimpleForm, DateTimeInput, TextInput, SelectInput, SelectField,
    EditButton, required, useRecordContext } from 'react-admin';

import { TimestampField } from "./../../timestamp";

import { UTCDateTimeInput } from './../../components/shared/UTCDateTimeInput';


export const AlertsList = () => (
    <List>
        <Datagrid>
			<TextField source="id" />
            <TextField source="title" />
            <SelectField source="alert_type" choices={[
                { id: 'COLLECTION_RELEASE', name: 'Collection release' },
            ]} />
            <TimestampField source="countdown" label="Countdown (UTC Time)"/>
            <TimestampField source="show_since" label="Show since (UTC Time)"/>
            <TimestampField source="show_until" label="Show until (UTC Time)"/>
            <EditButton />
        </Datagrid>
    </List>
);

const AlertTitle = () => {
    const record = useRecordContext();

    return <span>Alert {record ? `"${record.title}"` : ''}</span>
};

// NOTE: we need to use this transform function, because emptyValue prop on SelectInput does not work in currect admin version
const finalTransform = (data: any) => ({
    ...data,
    alert_type: data.alert_type === ''? null : data.alert_type
});


export const AlertCreate = () => (
    <Create title={<AlertTitle />} transform={finalTransform}>
        <SimpleForm>
            <TextInput source="title" validate={required()}/>

            <SelectInput source="alert_type" emptyValue={null} choices={[
                { id: 'COLLECTION_RELEASE', name: 'Collection release' },
            ]} />

            <UTCDateTimeInput source="countdown" label="Countdown (UTC time)" />
            <UTCDateTimeInput source="show_since" label="Show since (UTC time)" />
            <UTCDateTimeInput source="show_until" label="Show until (UTC time)" />
        </SimpleForm>
    </Create>
);

export const AlertEdit = () => (
    <Edit title={<AlertTitle />} transform={finalTransform}>
        <SimpleForm>
            <NumberField source="id" />

            <TextInput source="title" validate={required()}/>

            <SelectInput source="alert_type" emptyValue={null} choices={[
                { id: 'COLLECTION_RELEASE', name: 'Collection release' }
            ]} />

            <UTCDateTimeInput source="countdown" label="Countdown (UTC time)" />
            <UTCDateTimeInput source="show_since" label="Show since (UTC time)" />
            <UTCDateTimeInput source="show_until" label="Show until (UTC time)" />
        </SimpleForm>
    </Edit>
);
