import React, { useState } from 'react';
import { useNotify, useRecordContext } from 'react-admin';
import CircularProgress from '@mui/material/CircularProgress';
import UploadImage from './UploadImage';
import { useWebservice } from '../../hooks/UseWebservice';


interface Props {
  taskPath: string;
  label: string;
}


function UploadImageField(props: Props) {

  const { uploadFile } = useWebservice();

  const record = useRecordContext();

  const [uploading, setUploading] = useState(false);
  const notify = useNotify();

  const setNewIconImage = async (imageFile: File) => {
    setUploading(true);
    try {
      await uploadFile(props.taskPath, { record_id: record.id }, imageFile);
      notify("File succssfully uploaded", { type: "success" })
    } catch (error: any) {
      notify(`Error uploading the file! ${error.message}`, { type: "error" })
    } finally {
      setUploading(false);
    }
  }

  return <>
    {
      uploading
      ?
      <CircularProgress />
      :
      <UploadImage onImageChange={(imageFile: File) => setNewIconImage(imageFile)} buttonLabel={props.label}></UploadImage>
    }
  </>
}

export default UploadImageField;
