import { List, NumberField, Datagrid, TextField, NumberInput, BooleanField } from 'react-admin';
import ContinueAdminTaskButton from './../../buttons/continueAdminTaskButton';


const AdminTaskFilter= [
    <NumberInput label="Task ID" source="id" />
]


export const AdminTasks = () => (
    <List filters={AdminTaskFilter}>
        <Datagrid>
            <NumberField source="id" />

            <NumberField source="start_checkpoint" />
            <NumberField source="end_checkpoint" />

            <TextField source="task_type" />
            <TextField source="task_params" />

            <NumberField source="processed_entries_count" />
            <NumberField source="applied_on_entries_count" />
            <NumberField source="total_entries_count" />

            <TextField source="error_msg" />

            <BooleanField source="finished" />
            <ContinueAdminTaskButton />
        </Datagrid>
    </List>
);
