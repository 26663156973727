import {useState} from 'react';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { useRecordContext } from 'react-admin';
import { useWebservice } from '../hooks/UseWebservice';


export interface AdminTaskInfoResponse {
  task_id: number;
  task_type: string;
  first_nft_id: number;
  last_nft_id: number;
  total_nfts_count: number;
  processed_nfts_count: number;
  applied_on_nfts_count: number;
  finished: boolean;
  error: string;
  specific_task_data: any;
}

interface TaskStatus {
  finished: boolean;
  total: number;
  processed: number;
  appliedCount: number;
  error: string;
}


export default function ApplyParserRule() {

  const { applyParserRuleOnExistingCards, getAdminTaskStatus } = useWebservice();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [applyOnOriginGenesis, setApplyOnOriginGenesis] = useState<boolean>(false);
  const [startNftId, setStartNftId] = useState<number>();
  const [endNftId, setEndNftId] = useState<number>();
  const [taskStarted, setTaskStarted] = useState<boolean>(false);
  const [taskId, setTaskId] = useState<number>();
  const [taskStatus, setTaskStatus] = useState<TaskStatus>();

  const record = useRecordContext();


  const startTask = async () => {
    setTaskStarted(true);
    const result = await applyParserRuleOnExistingCards(record.id, applyOnOriginGenesis, startNftId, endNftId);
    setTaskId(result.task_id);
    getTaskStatus(result.task_id);
  }

  const getTaskStatus = (taskId: number) => {
    setTimeout(async () => {
      const result = await getAdminTaskStatus(taskId)
      setTaskStatus({
        finished: result.finished,
        total: result.total_nfts_count,
        processed: result.processed_nfts_count,
        appliedCount: result.applied_on_nfts_count,
        error: result.error
      });

      if (!result.finished) {
        getTaskStatus(taskId);
      }
    }, 5000)
  }

  const ruleCanBeAppliedOnExisting = record.rule_type === "TITLE" && record.regex && record.format;

  return (
    <>
    { ruleCanBeAppliedOnExisting && !taskStarted && <Button style={{ backgroundColor: "red" }} onClick={() => setDialogOpen(true)}>Apply</Button> }
    { ruleCanBeAppliedOnExisting && taskStarted && <Button style={{ backgroundColor: "red" }} onClick={() => setDialogOpen(true)}>See status</Button> }

    <Dialog 
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}>

      <DialogTitle>Confirm applying rule <i>{record.title}</i> on all existing nfts from rule's domains</DialogTitle>
      <DialogContent>
        <Typography>
          Please bear in mind that this will apply only <b>title change</b> using <i>regex</i> and <i>format</i> fields.<br></br>
          This will <b>not</b> rescrape the metadata using <i>xpath</i> field!
        </Typography>
        <hr></hr>
        {
          taskStarted
          ?
          <>
            {taskStatus && taskStatus.finished && taskStatus.error && <>
              <Typography variant="caption" color="secondary">ERRIR</Typography>
              <Typography>Task id: {taskId}</Typography>
              <Typography>Rule applied <b>{taskStatus.appliedCount}</b> times</Typography>
            </>}

            {taskStatus && taskStatus.finished && !taskStatus.error && <>
              <Typography variant="caption" color="primary">FINISHED</Typography>
              <Typography>Task id: {taskId}</Typography>
              <Typography>Rule applied <b>{taskStatus.appliedCount}</b> times</Typography>
            </>}

            {taskStatus && !taskStatus.finished && <>
              <Typography>Task {taskId} in PROGRESS</Typography>
              <Typography>Total NFTs: {taskStatus.total}</Typography>
              <Typography>Processed NFTs: {taskStatus.processed}</Typography>
              <Typography>Rule applied: {taskStatus.appliedCount} times</Typography>
              <LinearProgress variant="determinate" value={(taskStatus.processed / taskStatus.total) * 100}></LinearProgress>
              <CircularProgress size={12} />
            </>}

            {!taskStatus && <>
              <Typography>Starting the task</Typography>
              <CircularProgress />
            </>}
          </>
          :
          <>
            <FormControlLabel 
              control={<Checkbox color="primary" checked={applyOnOriginGenesis} onChange={(event) => setApplyOnOriginGenesis(event.target.checked)} />} 
              label="Apply for genesis and origin cards" 
              labelPlacement="end" />
            <br></br>
            <TextField label="Start on NFT id" type="number" variant="outlined" value={startNftId} onChange={(event) => setStartNftId(parseInt(event.target.value))} />
            <TextField label="End on NFT id" type="number" variant="outlined" value={endNftId} onChange={(event) => setEndNftId(parseInt(event.target.value))} />
            <br></br>
            <Button style={{ backgroundColor: "red" }} onClick={startTask}>Apply now!</Button>
          </>
        }
      </DialogContent>

  </Dialog>
    </>
  )
}