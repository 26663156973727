import { useState } from 'react';

import { required, useRecordContext } from 'react-admin';

import Button from '@mui/material/Button';

import RemoveIcon from '@mui/icons-material/Remove';
import IconCancel from '@mui/icons-material/Cancel';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';

import useMemecoinDepositing from '../hooks/UseMemecoinDepositing';


export default function RemoveDMEMButton() {

    const [ memAmount, setMemAmount ] = useState<string>("0");
    const [ reason, setReason ] = useState<string>("");
    const [ dialogOpen, setDialogOpen ] = useState<boolean>(false)

    const { remove, resetRemove, removeTxHash, removeState, removeError } = useMemecoinDepositing()
    const record = useRecordContext();

    const onDialogOpen = () => {
        setDialogOpen(true)
        resetRemove();
    }

    const onRemove = () => {
        const userAddress = record.account;
        remove(userAddress, memAmount, reason);
    }

    const onStopTracking = () => {
        resetRemove();
    }

    return (
        <>
            <Button style={ {'backgroundColor': '#ff5722', color: 'white'} } onClick={onDialogOpen}>
                <RemoveIcon />DMEM
            </Button>

            <Dialog
                fullWidth
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}>

                <DialogTitle>Remove memecoin from user</DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>
                            Removing for user: {record.account}
                            {
                                record.account !== record.username
                                ?
                                <i> ({record.username})</i>
                                :
                                null
                            }
                        </span>
                        <TextField label="Amount" value={memAmount} onChange={(e) => setMemAmount(e.target.value)}/>
                        <TextField label="Reason" value={reason} onChange={(e) => setReason(e.target.value)}/>

                        {
                            removeTxHash !== null && removeTxHash !== ""
                            ?
                            <span>Remove transaction hash: {removeTxHash}</span>
                            :
                            null
                        }
                    </div>
                </DialogContent>
                
                <DialogActions>
                    {
                        (removeState === 'queued' || removeState === 'pending')&&
                        <Button onClick={onStopTracking} variant="contained" color="secondary">
                            Stop tracking
                        </Button>        
                    }

                    {
                        removeState !== null && removeState !== 'success' && removeState !== 'error' &&
                        <div>
                            <CircularProgress size={16}></CircularProgress>
                            <span>Status: {removeState}</span>
                        </div>
                    }
                    {
                        removeState === 'success' && <span style={{ color: 'green' }}>SUCCESS!</span>
                    }
                    {
                        removeState === 'error' && <span style={{ color: 'red' }}>ERROR: {removeError}</span>
                    }
                    <Button onClick={onRemove} variant="contained" type="submit" color="primary" disabled={removeState != null}>
                        Remove
                    </Button>

                    <Button onClick={() => setDialogOpen(false)} disabled={removeState != null}>
                        <IconCancel/>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
