const settings = {
    api: {
        url: process.env.REACT_APP_API_URL,
        task_url: process.env.REACT_APP_TASK_URL
    },
    auth: {
        url: process.env.REACT_APP_AUTH_URL
    },
    scrapingbee: {
        api_key: process.env.REACT_APP_SCRAPINGBEE_APIKEY
    },
    contracts: {
        eth: {
            factory: process.env.REACT_APP_CONTRACT_ADDRESS_ETH_FACTORY,
            mem: process.env.REACT_APP_CONTRACT_ADDRESS_ETH_MEM,
            dmemSeller: process.env.REACT_APP_CONTRACT_ADDRESS_ETH_DMEM_SELLER,
            bank: null,
        },
        matic: {
            factory: process.env.REACT_APP_CONTRACT_ADDRESS_MATIC_FACTORY,
            mem: process.env.REACT_APP_CONTRACT_ADDRESS_MATIC_MEM,
            dmemSeller: process.env.REACT_APP_CONTRACT_ADDRESS_MATIC_DMEM_SELLER,
            bank: process.env.REACT_APP_CONTRACT_ADDRESS_MATIC_BANK,
        }
    },
    admin_wallet: {
        address: process.env.REACT_APP_ADDRESS_ADMIN,
    },
    chains: {
        ethChainId: (process.env.REACT_APP_ETH_CHAIN_ID != null) ? parseInt(process.env.REACT_APP_ETH_CHAIN_ID) : -1,
        ethExplorer: process.env.REACT_APP_ETH_EXPLORER,
        maticChainId: (process.env.REACT_APP_MATIC_CHAIN_ID != null) ? parseInt(process.env.REACT_APP_MATIC_CHAIN_ID) : -1,
        maticExplorer: process.env.REACT_APP_MATIC_EXPLORER,
    }
};

export default settings;
