import React, {useEffect, useState} from 'react';

import {SimpleForm, useRecordContext} from 'react-admin';
import {useNotify} from 'react-admin';


import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import IconCancel from '@mui/icons-material/Cancel';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useWebservice } from '../../../../hooks/UseWebservice';


interface CardImageState {
    cardImageUrl: string;
    version: number;
    calledWS: boolean;
};

function CardImage(props: { nft_id: number }) {
    
    const { getCardImage } = useWebservice();

    const [state, setState] = useState<CardImageState>({
        cardImageUrl: "",
        version: -1,
        calledWS: false
    });

    useEffect(() => {
        const fetch = async () => {
            const response = await getCardImage(props.nft_id)
            setState({cardImageUrl: response.url, version: response.version, calledWS: true})
        }

        fetch()
    }, [props.nft_id])

    return (
        <img src={`${state.cardImageUrl}?version=${state.version}`} alt="Card" width="300px" />
    )
}


interface ImageFieldProps {
    imageUrl: string;
    title: string;
}

function ImageField(props: ImageFieldProps) {
    return (
        <img src={props.imageUrl} alt={props.title} width="150px" />
    )
}


interface ChangeCardImageButtonState {
    error: boolean;
    showDialog: boolean;
    submitting: boolean;
}

function ChangeCardImageButton() {

    const { changeNftCardBackgroundImage, changeNftCardThumbnailImage } = useWebservice();

    const [state, setState] = useState<ChangeCardImageButtonState>({
        error: false,
        showDialog: false,
        submitting: false
    });
    const [newImageUrl, setNewImageUrl] = useState<string>('')
    const [newImageType, setNewImageType] = useState<string>('')
    
    const notfy = useNotify();
    const record = useRecordContext();

    const handleClick = () => {
        setState({ showDialog: true, error: state.error, submitting: state.submitting });
    }

    const handleCloseClick = () => {
        setState({ showDialog: false, error: state.error, submitting: state.submitting });
    }

    const handleSubmit = async () => {
        setState({ submitting: true, error: state.error, showDialog: state.showDialog });

        const nftId = record.nft_id;

        let response = null;
        try {
            switch (newImageType) {
                case "BACKGROUND":
                    response = await changeNftCardBackgroundImage(nftId, newImageUrl); break;
                case "THUMBNAIL":
                    response = await changeNftCardThumbnailImage(nftId, newImageUrl); break;
                default:
                    notfy(`Unknown image type ${newImageType}!`, { type: "warning" });
                    return
            }
        } catch (error) {
            setState({ showDialog: false, submitting: false, error: state.error });
            notfy(`Error on webservice! ${error}`, { type: "warning" })
            return;
        } finally {
            setState({ submitting: false, error: state.error, showDialog: false });
        }

        if (response.error) {
            notfy("Error changing the image! " + response.error, { type: "warning" })
        }
        else {
            notfy(`${newImageType} successfully changed to ${newImageUrl}`, { type: "info" })
        }
    };

    return (
        <>
            <Button onClick={handleClick} style={{ height: "100%", width: "100%" }}>
                Change image
            </Button>

            <Dialog
                fullWidth
                open={state.showDialog}
                onClose={handleCloseClick}
                aria-label="Create post">

                <DialogTitle>Change card image</DialogTitle>
                <DialogContent>
                    <SimpleForm
                        onSubmit={handleSubmit}
                        toolbar={false}>
                        <Typography>Card image</Typography>
                        <CardImage nft_id={record.nft_id} />

                        <Typography>Image from URL</Typography>
                        <ImageField imageUrl={newImageUrl} title="Image from URL" />

                        <TextField label="New image URL" value={newImageUrl} onChange={(e) => setNewImageUrl(e.target.value)} />
                        <Select
                            value={newImageType}
                            label="Image type"
                            onChange={(e) => setNewImageType(e.target.value as string)}
                        >
                            <MenuItem value={'BACKGROUND'}>Background image</MenuItem>
                            <MenuItem value={'THUMBNAIL'}>Thumbnail image (in circle)</MenuItem>
                        </Select>
                    </SimpleForm>
                </DialogContent>

                <DialogActions>
                    {
                        state.submitting
                        ?
                        <CircularProgress />
                        :
                        <Button variant="contained" onClick={handleSubmit}>
                            Submit
                        </Button>
                    }
                    <Button onClick={handleCloseClick} >
                        <IconCancel/>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );

}

export default ChangeCardImageButton;
