import { makeStyles } from '@mui/styles';

import AdminTasks from './AdminTasks';
import BankWithdrawWhitelist from './BankWithdrawWhitelist';
import CeleryTasks from './CeleryTasks';
import DailyDMEMRewards from './DailyDMEMRewards';
import EthBalances from './EthBalances';
import MemeCoinStats from './MemeCoinStats';
import PendingTransactions from './PendingTransactions';
import ScrapingbeeStats from './ScrapingbeeStats';
import Welcome from './Welcome';

import Grid from "@mui/material/Grid"
import OpenseaNotifications from './OpenseaNotifications';


const useStyles = makeStyles({
    welcomeWrapper: {
        marginBottom: "12px",
    },

    gridItem: {
        "& > *": {
            marginBottom: "8px",
        }
    }
});


export default function Dashboard() {

    const classes = useStyles();

    return (
        <div>
            <div className={classes.welcomeWrapper}>
                <Welcome />
            </div>

            <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={3}>
                <Grid item xs={3} className={classes.gridItem}>
                    <EthBalances />
                    <BankWithdrawWhitelist />
                    <OpenseaNotifications />
                </Grid>

                <Grid item xs={3} className={classes.gridItem}>
                    <MemeCoinStats />
                    <ScrapingbeeStats />
                    <CeleryTasks />
                </Grid>

                <Grid item xs={6} className={classes.gridItem}>
                    <DailyDMEMRewards />
                    <AdminTasks />
                    <PendingTransactions />
                </Grid>
            </Grid>
        </div>
    );
}
