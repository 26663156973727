import { useRecordContext } from "react-admin";


interface Props {
    source: string;
    length: number;
}


const TruncatedTextField = (props: Props) => {
    const record = useRecordContext();

    const text = record[props.source];
    const truncatedText =
        text && text.length > props.length ? `${text.substring(0, props.length)}...` : text;

    return text ? <span>{truncatedText}</span> : null;
};

TruncatedTextField.defaultProps = {
    addLabel: true,
};

export default TruncatedTextField;
