import {Datagrid, Filter, List, Edit, NumberField, TextField, TextInput, EditButton, SimpleForm} from 'react-admin';

import AddXPButton from "../../buttons/addXpButton";
import AddDMEMButton from "../../buttons/addDMEMButton";
import RemoveXPButton from "../../buttons/removeXpButton";
import RemoveDMEMButton from "../../buttons/removeDMEMButton";


const WalletFilter = [
    <TextInput label="account" source="account" />,
    <TextInput label="username" source="username" />,
    <TextInput label="email" source="email" />,
    <TextInput label="country" source="country" />,
]


export const WalletList = () => (
    <List filters={WalletFilter} >
        <Datagrid>
            <NumberField source="id" />
            <TextField source="account"/>
			<TextField source="username" />
			<TextField source="email" />
			<TextField source="country" />
			<TextField label="Daily DMEM reward" source="dmem_wei_reward" />
            <TextField label="XP" source="xp_balance" />
			<AddXPButton />
            <RemoveXPButton />
			<AddDMEMButton />
			<RemoveDMEMButton />
			<EditButton />
        </Datagrid>
    </List>
);

const WalletTitle = ({ wallet }: any) => {
    return <span>Wallet {wallet ? (wallet.username ? `"${wallet.username}"` : `"${wallet.id}"`) : ""}</span>
};

export const WalletEdit = () => (
    <Edit title={<WalletTitle />}>
        <SimpleForm>
            <NumberField source="id"/>
            <TextField source="account"/>
            <TextField source="username"/>
            <NumberField source="dmem_wei_reward"/>
        </SimpleForm>
    </Edit>
);
