import { useState } from "react"
import { TextField, useNotify, useRecordContext } from "react-admin"
import Button from "@mui/material/Button"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import { Box } from "@mui/system"


interface Props {
  source: string;
  label?: string;
  fullWidth?: boolean;
}

export default function SecretField(props: Props) {
  const record = useRecordContext();
  const notify = useNotify();

  const display = () => {
    setShow(true)
    setTimeout(() => {
      setShow(false)
    }, 5000)
  }

  const [show, setShow] = useState<boolean>(false);

  const onCopyToClipboard = () => {
    const secret = record[props.source];
    navigator.clipboard.writeText(secret);
    notify("Secret copied to clipboard", { type: "info" })
  }

  return (
    <Box width={props.fullWidth? "100%" : 250}>
      {
        show?
        <TextField style={{wordBreak: "break-all"}} source={props.source}/>
        :
        <Tooltip title="Click to show">
          <Typography onClick={display} component="span" variant="inherit" style={{cursor: 'pointer'}}>░▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▓</Typography>
        </Tooltip>
      }
      <Button onClick={onCopyToClipboard}>Copy to clipboard</Button>
    </Box>
  )
}
