import { useState } from "react";

import Button from '@mui/material/Button';
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import useWeb3, { Web3Chain } from "../../hooks/web3/UseWeb3";

import ChainLogo from "../shared/ChainLogo";
import useBankWithdrawalAuthorization from "../../hooks/web3/UseBankWithdrawalAuthorization";


export default function BankWithdrawWhitelist() {

  const [isWhitelistedAddress, setIsWhitelistedAddress] = useState<string>("");
  const [isWhitelistedResponse, setIsWhitelistedResponse] = useState<boolean | null>(null);
  const [isWhitelistedLoading, setIsWhitelistedLoading] = useState<boolean>(false);

  const [addToWhitelistAddress, setAddToWhitelistAddress] = useState<string>("");
  const [addToWhitelistDone, setAddToWhitelistDone] = useState<boolean>(false);
  const [addToWhitelistLoading, setAddToWhitelistLoading] = useState<boolean>(false);

  const [removeFromWhitelistAddress, setRemoveFromWhitelistAddress] = useState<string>("");
  const [removeFromWhitelistDone, setRemoveFromWhitelistDone] = useState<boolean>(false);
  const [removeFromWhitelistLoading, setRemoveFromWhitelistLoading] = useState<boolean>(false);

  const { web3chain, web3account } = useWeb3();
  const { contractOwner, isWhitelisted, addToWhitelist, removeFromWhitelist } = useBankWithdrawalAuthorization();
  
  const onGetIsWhitelisted = async () => {
    setIsWhitelistedResponse(null)
    setIsWhitelistedLoading(true);
    const result = await isWhitelisted(isWhitelistedAddress)
    setIsWhitelistedResponse(result)
    setIsWhitelistedLoading(false);
  }

  const onAddToWhitelist = async () => {
    setAddToWhitelistDone(false);
    setAddToWhitelistLoading(true);
    await addToWhitelist(addToWhitelistAddress)
    setAddToWhitelistDone(true);
    setAddToWhitelistLoading(false);
  }

  const onRemoveFromWhitelist = async () => {
    setRemoveFromWhitelistDone(false);
    setRemoveFromWhitelistLoading(true);
    await removeFromWhitelist(removeFromWhitelistAddress)
    setRemoveFromWhitelistDone(true);
    setRemoveFromWhitelistLoading(false);
  }

  return (
    <Card>
      <CardHeader title="Bank withdrawal whitelist"></CardHeader>
      <CardContent>
        {
          web3chain === undefined
          ?
          <>
            Fetching chain <CircularProgress></CircularProgress>
          </>
          :
          <>
            {
              web3chain !== Web3Chain.matic
              ?
              <Typography variant="caption">
                These actions are available only on Matic chain. Current chain <ChainLogo chain={web3chain} height={25}></ChainLogo>
              </Typography>
              :
              <>
                {
                  contractOwner !== web3account
                  ? 
                  <Typography variant="caption">
                    These actions can be called only from owner account, which is: {contractOwner}. Your address is: {web3account}
                  </Typography>
                  :
                  <>
                    <TextField type="text" label="Is whitelisted" value={isWhitelistedAddress} onChange={(event) => setIsWhitelistedAddress(event.target.value)}></TextField>
                    {
                      isWhitelistedLoading
                      ?
                      <CircularProgress></CircularProgress>
                      :
                      <Button onClick={onGetIsWhitelisted} style={ {'backgroundColor': 'goldenrod', color: 'white'} } variant="contained">GET</Button>
                    }
                    {
                      isWhitelistedResponse !== null &&
                        <span>{isWhitelistedResponse? "true" : "false"}</span>
                    }

                    <TextField type="text" label="Add to whitelist" value={addToWhitelistAddress} onChange={(event) => setAddToWhitelistAddress(event.target.value)}></TextField>
                    {
                      addToWhitelistLoading
                      ?
                      <CircularProgress></CircularProgress>
                      :
                      <Button onClick={onAddToWhitelist} style={ {'backgroundColor': 'lime', color: 'black'} } variant="contained">ADD</Button>
                    }
                    {
                      addToWhitelistDone && <span>Done</span>
                    }

                    <TextField type="text" label="Remove from whitelist" value={removeFromWhitelistAddress} onChange={(event) => setRemoveFromWhitelistAddress(event.target.value)}></TextField>
                    {
                      removeFromWhitelistLoading
                      ?
                      <CircularProgress></CircularProgress>
                      :
                      <Button onClick={onRemoveFromWhitelist} style={ {'backgroundColor': 'tomato', color: 'black'} } variant="contained">REMOVE</Button>
                    }
                    {
                      removeFromWhitelistDone && <span>Done</span>
                    }
                  </>
                }
              </>
            }
          </>
        }
      </CardContent>
    </Card>
  )
}