import React from 'react';

import { List, Edit, Datagrid, TextField, EditButton, BooleanField, NumberField, SimpleForm, TextInput,
    ImageField, required } from 'react-admin';
import CensorDomainButton from './../../buttons/censorDomainButton';
import RescrapeCardThumbnailsButton from '../../buttons/rescrapeCardThumbnailsButton';
import SetDomainFaviconButton from './../../buttons/setDomainFaviconButton';

export const DomainList = () => (
    <List filter={{ domain_type: 'PRIVATE' }}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="domain" />
        <TextField source="domain_power" />
        <BooleanField source="censor_thumbnails" />
        <CensorDomainButton internal={false} />
        <CensorDomainButton internal={true} />
        <ImageField source="favicon_url" title="Favicon" />
        <SetDomainFaviconButton />
        <RescrapeCardThumbnailsButton />
        <EditButton />
      </Datagrid>
    </List>
);

export const DomainEdit = () => (
    <Edit title="domain.domain_display_name">
        <SimpleForm>
            <NumberField source="id" />
            <TextInput source="domain_power" validate={required()} />
        </SimpleForm>
    </Edit>
);
