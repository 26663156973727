import { useRefresh, useRecordContext } from "react-admin";

import ConfirmActionButton from "../components/shared/ConfirmActionButton";
import { useWebservice } from "../hooks/UseWebservice";


const DeclineArtCreatorApplicationButton = () => {
    
    const { declineArtCreatorApplication } = useWebservice();

    const record = useRecordContext();
    const applicationIsOpen = record.application_status === "in_process";
    const refresh = useRefresh();

    if (!record) return null;

    const onActionConfirmed = async (record: any) => {
        await declineArtCreatorApplication(record.id);
        refresh();
    };

    return (
        <ConfirmActionButton
            color="#D14D47"
            disabled={!applicationIsOpen}
            buttonLabel="Decline application"
            onConfirmed={onActionConfirmed}
            onSuccess={`Application #${record.id} declined`}
            dialogTitle="Decline art creator application"
            actionLabel={`Decline application #${record.id}`}
        />
    );
};

export default DeclineArtCreatorApplicationButton;
