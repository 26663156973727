import {Admin, fetchUtils, Options, Resource} from 'react-admin';
import jsonServerRestClient from 'ra-data-json-server';

import settings from './settings';
import authClient from './authClient';

import {AlertCreate, AlertEdit, AlertsList} from "./components/resources/alerts"
import {UserEdit, UserList} from "./components/resources/users";
import {AllowedDomainCreate, AllowedDomainEdit, AllowedDomainsList} from "./components/resources/allowedDomains";
import {BannedDomainCreate, BannedDomainEdit, BannedDomainsList} from "./components/resources/bannedDomains";
import {DomainEdit, DomainList} from "./components/resources/domain";
import {LevelTypeCreate, LevelTypeEdit, LevelTypeList} from "./components/resources/levelTypes";
import {AppConfigEdit, AppConfigList} from "./components/resources/appConfig";
import {WalletExperienceConfigEdit, WalletExperienceConfigList} from "./components/resources/walletExperienceConfig";
import {MarbleNftList} from "./components/resources/marbleNft/marbleNft"
import Dashboard from './components/dashboard/Dashboard';
import {WalletList, WalletEdit} from "./components/resources/wallets";
import {ParserRuleCreate, ParserRuleEdit, ParserRulesList} from "./components/resources/parserRules";
import {CrawlerRuleCreate, CrawlerRuleEdit, CrawlerRuleList} from "./components/resources/crawlerRules";
import {BackgroundGeneratorCreate, BackgroundGeneratorEdit, BackgroundGeneratorsList} from "./components/resources/backgoundGenerators";
import {StatsAccountsCreate, StatsAccountsEdit, StatsAccountsList} from "./components/resources/statsAccounts"

import {LeaguesCreate, LeaguesEdit, LeaguesList} from "./components/resources/arenaLeagues";
import {LeagueCardsEdit, LeagueCardsList} from "./components/resources/arenaCards";
import {ArenaConfigEdit, ArenaConfigList} from "./components/resources/arenaConfig";
import {RewardsList, RewardsEdit, RewardsCreate} from "./components/resources/arenaRewards";

import {CandidateList} from "./components/resources/candidates";

import {AccountCircle, Code, PlaylistAddCheck, Block, Web, Exposure, Dehaze, Style, PhotoAlbum,
    NotificationsActive, Image, TrendingUp, AccountBalance, SentimentVeryDissatisfied, Star, DoneAll, FilterNone,
    CropDin,
    Campaign} from '@mui/icons-material';
import {ArenaBlacklistCreate, ArenaBlacklistEdit, ArenaBlacklistList} from "./components/resources/arenaBlacklist";

import {DecksList, DeckEdit, DeckCreate} from "./components/resources/decks";
import {DeckCardsList, DeckCardEdit} from "./components/resources/deckCards";
import {DeckRequirementsList, DeckRequirementCreate, DeckRequirementEdit} from "./components/resources/deckRequirements";
import "./App.css"
import {AdminTaskApplications} from './components/resources/adminTaskApplications';
import {AdminTasks} from './components/resources/adminTasks';
import { ArtCreatorCreate, ArtCreatorEdit, ArtCreatorsList } from './components/resources/artCreators';
import { ScrapingBeeRuleCreate, ScrapingBeeRuleEdit, ScrapingBeeRulesList } from './components/resources/scrapingBeeRules';
import { ArtCreatorApplicationsList, ArtCreatorApplicationShow } from './components/resources/artCreatorApplications';
import { CardVerificationBidsList } from './components/resources/cardVerificationBids';
import { 
    MarbleNotificationRecipientCreate, MarbleNotificationRecipientsList, MarbleNotificationRecipientEdit, MarbleNotificationRecipientShow
} from './components/resources/marbleNotificationRecipients'


const httpClient = (url: string, options: Options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }

    const token = localStorage.getItem('token');
    // @ts-expect-error
    options.headers.set('Authorization', `Bearer ${token}`);

    console.log(`Fetching url '${url}'.`);

    return fetchUtils.fetchJson(url, options);
};

const restClient = jsonServerRestClient(settings.api.url, httpClient);


const App = () => (
    <Admin dataProvider={restClient} authProvider={authClient} title="Marblegame Admin" dashboard={Dashboard}>
        <Resource name="users" list={UserList} edit={UserEdit} options={{label: 'Admins'}} icon={AccountCircle}/>
        <Resource name="alerts" list={AlertsList} edit={AlertEdit} create={AlertCreate} icon={NotificationsActive} />
        <Resource name="allowed_domains" list={AllowedDomainsList} edit={AllowedDomainEdit}
                  create={AllowedDomainCreate} icon={PlaylistAddCheck}  />
        <Resource name="app_config" list={AppConfigList} edit={AppConfigEdit} icon={Dehaze}/>
        <Resource name="background_generators" list={BackgroundGeneratorsList} edit={BackgroundGeneratorEdit}
                  create={BackgroundGeneratorCreate} icon={Image} />
        <Resource name="banned_domains" list={BannedDomainsList} edit={BannedDomainEdit}
                  create={BannedDomainCreate} icon={Block} />
        <Resource name="candidates" list={CandidateList}
                  icon={Code}/>
        <Resource name="domains" list={DomainList} edit={DomainEdit} icon={Web} />
        <Resource name="level_types" list={LevelTypeList} edit={LevelTypeEdit}
                  create={LevelTypeCreate} icon={Exposure} />
        <Resource name="marble_nft" list={MarbleNftList} icon={PhotoAlbum}/>
        <Resource name="parser_rules" list={ParserRulesList} edit={ParserRuleEdit} create={ParserRuleCreate}
                  icon={Code}/>
        <Resource name="crawler_rules" list={CrawlerRuleList} edit={CrawlerRuleEdit} create={CrawlerRuleCreate}
                  icon={Code}/>
        <Resource name="scraping_bee_rules" list={ScrapingBeeRulesList} edit={ScrapingBeeRuleEdit} create={ScrapingBeeRuleCreate}
                  icon={Code}/>
        <Resource name="wallet_experience_config" list={WalletExperienceConfigList} edit={WalletExperienceConfigEdit}
                  icon={Dehaze} />
        <Resource name="wallets" list={WalletList} edit={WalletEdit} icon={Style}/>
        <Resource name="stats_accounts" list={StatsAccountsList} edit={StatsAccountsEdit}
                  create={StatsAccountsCreate} icon={TrendingUp}  />
        <Resource name="leagues" list={LeaguesList} edit={LeaguesEdit} create={LeaguesCreate} icon={AccountBalance}
                  options={{ label: "Arena Leagues" }} />
        <Resource name="league_cards" list={LeagueCardsList} edit={LeagueCardsEdit} icon={PhotoAlbum}
                  options={{ label: "Arena League Cards" }} />
        <Resource name="arena_blacklist" list={ArenaBlacklistList} edit={ArenaBlacklistEdit}
                  create={ArenaBlacklistCreate} icon={SentimentVeryDissatisfied} />
        <Resource name="arena_config" list={ArenaConfigList} edit={ArenaConfigEdit} icon={Dehaze}/>
        <Resource name="arena_rewards" list={RewardsList} edit={RewardsEdit} create={RewardsCreate} icon={Star} />

        <Resource name="deck_requirements" list={DeckRequirementsList} edit={DeckRequirementEdit} create={DeckRequirementCreate} icon={DoneAll} />
        <Resource name="decks" list={DecksList} edit={DeckEdit} create={DeckCreate} icon={FilterNone} />
        <Resource name="deck_cards" list={DeckCardsList} edit={DeckCardEdit} icon={CropDin} />

        <Resource name="admin_task_applications" list={AdminTaskApplications} />
        <Resource name="admin_tasks" list={AdminTasks} />

        <Resource name="art_creators" list={ArtCreatorsList} edit={ArtCreatorEdit} create={ArtCreatorCreate} icon={AccountCircle} />
        <Resource name="art_creator_applications" list={ArtCreatorApplicationsList} show={ArtCreatorApplicationShow} icon={Dehaze}/>
        <Resource name="card_verification_bids" list={CardVerificationBidsList} />
        <Resource name="marble_nft_notification_recipients" list={MarbleNotificationRecipientsList} edit={MarbleNotificationRecipientEdit}
                  create={MarbleNotificationRecipientCreate} show={MarbleNotificationRecipientShow} icon={Campaign} />
    </Admin>
);

export default App;
