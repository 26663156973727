import React, { useEffect, useState } from 'react';
import { useRecordContext } from "react-admin";
import CircularProgress from '@mui/material/CircularProgress';
import { useWebservice } from '../../hooks/UseWebservice';


interface Props {
  label?: string;
}


const AutographedCardPreviewField = (_: Props) => {

  const { getSignedCardPreview } = useWebservice();

  const record = useRecordContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [signedCardPreview, setSignedCardPreview] = useState<string>()

  useEffect(() => {
    const fetchCardPreview = async () => {
      const response = await getSignedCardPreview(record?.autograph_image_public_id);
      setSignedCardPreview(response.signed_card_preview_url);
      setLoading(false);
    }

    fetchCardPreview();
  }, [record?.autograph_image_public_id]);

  return <>
    {
      loading
      ?
      <CircularProgress />
      :
      <img src={signedCardPreview} height="300"></img>
    }
  </>
}

AutographedCardPreviewField.defaultProps = {
    addLabel: true
};

export default AutographedCardPreviewField
