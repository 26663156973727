import {
    Create,
    Datagrid,
    Edit,
    EditButton,
    List,
    NumberField,
    NumberInput,
    required,
    SimpleForm,
    SelectInput,
    SelectField,
    Filter,
    TextField,
    TextInput
} from 'react-admin';

const DeckRequirementsFilter = [
    <NumberInput label="Deck Number" source="deck_number"  />,
    <SelectInput label="Requirement Type" source="requirement_type"  choices={[
        { id: 'CARDS_OWNED_COUNT', name: 'COUNT: Cards owned' },
    ]} />
]

export const DeckRequirementsList = () => (
    <List sort={{ field: 'deck_number', order: 'ASC' }} filters={DeckRequirementsFilter}>
      <Datagrid>
        <NumberField source="id" />
        <NumberField source="deck_number" />
        <SelectField source="requirement_type" choices={[
           { id: 'CARDS_OWNED_COUNT', name: 'COUNT: Cards owned' },
        ]} />
        <NumberField source="value" />
        <TextField source="message"/>
        <EditButton />
      </Datagrid>
    </List>
);

const DeckRequirementTitle = ({ deck_requirement }: any) => {
  return <span>Arena league {deck_requirement? `"${deck_requirement.requirement_type} - ${deck_requirement.deck_number}" (${deck_requirement.id})` : ''}</span>
};

export const DeckRequirementCreate = () => (
    <Create title={<DeckRequirementTitle />}>
        <SimpleForm>
            <NumberInput source="deck_number" validate={required()} />
            <SelectInput source="requirement_type" validate={required()} choices={[
                { id: 'CARDS_OWNED_COUNT', name: 'COUNT: Cards owned' },
            ]} />
            <NumberInput source="value" validate={required()} />
            <TextInput source="message"/>
        </SimpleForm>
    </Create>
);

export const DeckRequirementEdit = () => (
    <Edit title={<DeckRequirementTitle />}>
        <SimpleForm>
            <NumberField source="id" />
            <NumberInput source="deck_number" validate={required()} />
            <SelectInput source="requirement_type" validate={required()} choices={[
                { id: 'CARDS_OWNED_COUNT', name: 'COUNT: Cards owned' },
            ]} />
            <NumberInput source="value" validate={required()} />
            <TextInput source="message"/>
        </SimpleForm>
    </Edit>
);
