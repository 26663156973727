import {AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS, AuthActionType} from 'react-admin';
import jwt_decode from 'jwt-decode';

import settings from './settings';

interface TokenType {
    roles: string
}

export function logout() {
    localStorage.removeItem('token');
}

export default (type: AuthActionType, params: any) => {
    // called when the user attempts to log in
    if (type === AUTH_LOGIN) {
        const {username, password} = params;

        const backendUrl: string | undefined = settings.auth.url;
        if (backendUrl === undefined || backendUrl === null) {
            throw "Backend URL not specified";
        }

        const request = new Request(backendUrl, {
            method: 'POST',
            body: JSON.stringify({name: username, password: password}),
            headers: new Headers({'Content-Type': 'application/json'})
        });

        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    let message = response.statusText;
                    try {
                        return response.json().then(({message}) => {
                            throw new Error(message);
                        });
                    } catch (e) {
                    }
                    throw new Error(message);
                }
                return response.json().then(({token}) => {
                    const decoded = jwt_decode<TokenType>(token);
                    localStorage.setItem('token', token);
                    localStorage.setItem('permissions', decoded.roles);
                });
            });
    }

    // called when the user clicks on the logout button
    if (type === AUTH_LOGOUT) {
        logout();
        return Promise.resolve();
    }

    // called when the API returns an error
    if (type === AUTH_ERROR) {
        const {status} = params;
        if (status === 401 || status === 403) {
            return Promise.reject();
        }

        return Promise.resolve();
    }

    // called when the user navigates to a new location
    if (type === AUTH_CHECK) {
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    }

    if (type === AUTH_GET_PERMISSIONS) {
        return Promise.resolve(localStorage.getItem('permissions'));
    }

    return Promise.resolve();
}
