import {
    Create,
    Datagrid,
    Edit,
    EditButton,
    List,
    NumberField,
    DateField,
    NumberInput,
    required,
    SimpleForm,
    TextField,
    SelectInput,
    ReferenceInput,
    DateTimeInput,
} from 'react-admin';

export const RewardsList = () => (
    <List>
      <Datagrid>
        <NumberField source="id" />
        <TextField source="value" />
        <NumberField source="card_nft_id" />
        <NumberField source="arena_league_id" />
        <DateField source="received_at" />
        <EditButton />
      </Datagrid>
    </List>
);

const RewardTitle = ({ reward }: any) => {
  return <span>Battle reward {reward? `"${reward.value}" (${reward.arena_league_id} - ${reward.card_nft_id})` : ''}</span>
};

export const RewardsCreate = () => (
    <Create title={<RewardTitle />}>
        <SimpleForm>
            <SelectInput source="value" choices={[
                { id: 'CHALLENGER', name: 'CHALLENGER' },
                { id: 'ELITE', name: 'ELITE' },
                { id: 'CHAMPION', name: 'CHAMPION' },
            ]} validate={required()} />

            <NumberInput label="NFT id" source="card_nft_id" />

            <ReferenceInput label="Arena League" source="arena_league_id" reference="leagues" perPage={100000} sort={{field: "id", order: "DESC"}}>
                <SelectInput optionText="title" />
            </ReferenceInput>

            <DateTimeInput source="received_at" />
        </SimpleForm>
    </Create>
);

export const RewardsEdit = () => (
    <Edit title={<RewardTitle />}>
        <SimpleForm>
            <NumberField source="id"/>

            <SelectInput source="value" choices={[
                { id: 'CHALLENGER', name: 'CHALLENGER' },
                { id: 'ELITE', name: 'ELITE' },
                { id: 'CHAMPION', name: 'CHAMPION' },
            ]} validate={required()} />

            <NumberInput label="NFT id" source="card_nft_id" />

            <ReferenceInput label="Arena League" source="arena_league_id" reference="leagues" perPage={100000} sort={{field: "id", order: "DESC"}}>
                <SelectInput optionText="title" />
            </ReferenceInput>

            <DateTimeInput source="received_at" />
        </SimpleForm>
    </Edit>
);
