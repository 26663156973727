import useWeb3, { Web3Chain } from "./UseWeb3";
import settings from "../../settings";


export default function useExplorerLinks() {

  const { web3chain: chain  } = useWeb3();

  const getLink = (address: string) => {
    if (chain === Web3Chain.ethereum) {
      return `${settings.chains.ethExplorer}/${address}`
    } else if (chain === Web3Chain.matic) {
      return `${settings.chains.maticExplorer}/${address}`;
    } else {
      return "#";
    }
  }

  return {
    getLink
  }

}