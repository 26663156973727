import React from 'react';

import {
    List, Datagrid, TextField, TextInput, DateInput,
    TopToolbar, ExportButton, RefreshButton, FilterButton
} from 'react-admin';

const CandidateActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}: any) => (
    <TopToolbar>
        <FilterButton/>
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            // @ts-expect-error
            filter={filterValues}
            exporter={exporter}
            maxResults={10000000}
        />
        <RefreshButton />
    </TopToolbar>
);


const CandidateFilter = [
    <TextInput label="Creator" source="creator"/>,
    <DateInput label="Created After" source="created_gte"/>,
    <DateInput label="Created Before" source="created_lte"/>,
]

export const CandidateList = () => (
    <List filters={CandidateFilter} actions={<CandidateActions />} >
        <Datagrid>
			<TextField source="id" />
            <TextField source="url" />
            <TextField source="url_hash"/>
            <TextField source="price"/>
            <TextField source="creator"/>
            <TextField source="network"/>
            <TextField source="created"/>
            <TextField source="db_record_created"/>
        </Datagrid>
    </List>
);

