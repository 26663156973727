import React, { useState } from 'react';
import Button from '@mui/material/Button';


interface Props {
  buttonLabel: string;
  onImageChange: ((file: File) => void) | ((file: File) => Promise<void>) | null
}

export default function UploadImage(props: Props) {

  const [previewImage, setPreviewImage] = useState<string | null>(null)

  const onFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];

    setPreviewImage(URL.createObjectURL(file))

    if (props.onImageChange != null) {
      props.onImageChange(file)
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column'}}>
      <Button
        color='primary'
        variant="contained"
        component="label"
      >
        {
          props.buttonLabel != null
          ?
          props.buttonLabel
          :
          "Upload File"
        }
        <input
          onChange={onFileSelect}
          type="file"
          hidden
        />
      </Button>

      { previewImage && <>
        <span>Preview:</span>
        <div>
          <img src={previewImage} alt="preview" height="100" />
        </div>
      </>}
    </div>
  )
}