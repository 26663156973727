import { List, Edit, Datagrid, TextField, EditButton, NumberField, SimpleForm,
    TextInput, required } from 'react-admin';
import useAppConfigChangeValidation from '../../hooks/UseAppConfigChangeValidation';

export const AppConfigList = () => (
    <List>
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="value" />
            <TextField source="description" />
            <EditButton />
        </Datagrid>
    </List>
);

const AppConfigTitle = ({ appConfig }: any) => {
    return <span>Configuration {appConfig ? `"${appConfig.id}" (${appConfig.name})` : ''}</span>
};



export function AppConfigEdit() {
    const { validation } = useAppConfigChangeValidation();

    return (
        <Edit title={<AppConfigTitle />}>
            <SimpleForm validate={validation}>
                <NumberField source="id"/>
                <TextField source="name"/>
                <TextInput source="value" validate={required()}/>
                <TextInput source="description" multiline />
            </SimpleForm>
        </Edit>
    );
}
