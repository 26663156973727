import {
    List,
    Datagrid,
    TextField,
    UrlField,
    SelectInput,
    DateField,
    SimpleShowLayout,
    Show,
    ShowButton,
    ListButton,
    ImageField,
    useRecordContext,
} from "react-admin";

import AutographedCardPreviewField from "./../../components/shared/AutographedCardPreviewField";
import AcceptArtCreatorApplicationButton from './../../buttons/acceptArtCreatorApplicationButton';
import DeclineArtCreatorApplicationButton from './../../buttons/declineArtCreatorApplicationButton';


const ArtCreatorApplicationsFilter = [
    <SelectInput label="Status" source="application_status" choices={[
        { id: 'ACCEPTED', name: 'Accepted' },
        { id: 'DECLINED', name: 'Declined' },
        { id: 'IN_PROCESS', name: 'In process' },
    ]}/>
]


export const ArtCreatorApplicationsList = () => (
    <List filters={ArtCreatorApplicationsFilter} >
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="email" />
            <UrlField source="twitter_profile_url" target="_blank"/>
            <TextField source="eth_address" />
            <TextField source="application_status"/>
            <DateField source="created_at" showTime/>
            <ShowButton label="Review the application"/>
        </Datagrid>
    </List>
);


const ArtCreatorApplicationTitle = () => {
    const record = useRecordContext();
    return <span>{`Art Creator Application ${record?.id}`}</span>;
};

export const ArtCreatorApplicationShow = () => {
    const listButtonStyles = {
        marginLeft: "auto",
        width: "17em",
        display: "flex",
    };

    return (
        <Show title={<ArtCreatorApplicationTitle/>}>
            <SimpleShowLayout>
                <ListButton
                    label="View all applications"
                    variant="contained"
                    style={listButtonStyles}
                />
                <TextField source="id" />
                <TextField source="application_status" />
                <DateField source="created_at" showTime />
                <DateField source="closed_at" showTime />
                <TextField source="name" />
                <UrlField source="twitter_profile_url" target="_blank" />
                <TextField source="eth_address" />
                <ImageField source="profile_image_url" label="Profile image preview" sx={{'& .RaImageField-image': {'height': '160px'}}} />
                <AutographedCardPreviewField label="Autographed card preview" />
                <ArtApplicationShowPageButtons />
            </SimpleShowLayout>
        </Show>
    );
};

const ArtApplicationShowPageButtons = () => {
    return (
        <section style={{ display: "flex", marginTop: "2em", flexWrap: "wrap" }}>
            <AcceptArtCreatorApplicationButton/>
            <DeclineArtCreatorApplicationButton/>
        </section>
    );
};
