import React from 'react';

import {
    Create,
    Datagrid,
    Edit,
    EditButton,
    List,
    NumberField,
    required,
    SimpleForm,
    TextField,
    TextInput,
    SelectInput,
    ReferenceField,
    ReferenceInput,
} from 'react-admin';


export const ScrapingBeeRulesList = () => (
    <List>
        <Datagrid>
            <NumberField source="id" />
            <TextField source="title" />
            <TextField source="url_regex" />
            <ReferenceField label="domain" source="domain_id" reference="allowed_domains">
                <TextField source="display_name" />
            </ReferenceField>
            <EditButton />
        </Datagrid>
    </List>
);

const ScrapingBeeRuleTitle = ({ rule }: any) => {
    return <span>ScrapingBee rule {rule? `"${rule.title}" (${rule.id})` : ''}</span>
};

export const ScrapingBeeRuleCreate = () => (
    <Create title={<ScrapingBeeRuleTitle />}>
        <SimpleForm>
            <TextInput source="title" validate={required()}/>
            <ReferenceInput label="Domain" source="domain_id" reference="allowed_domains">
                <SelectInput optionText="display_name" />
            </ReferenceInput>
            <TextInput source="url_regex" validate={required()}/>
        </SimpleForm>
    </Create>
);

export const ScrapingBeeRuleEdit = () => (
    <Edit title={<ScrapingBeeRuleTitle />}>
        <SimpleForm>
            <TextInput source="title" validate={required()}/>
            <ReferenceInput label="Domain" source="domain_id" reference="allowed_domains">
                <SelectInput optionText="display_name" />
            </ReferenceInput>
            <TextInput source="url_regex" validate={required()}/>
        </SimpleForm>
    </Edit>
);
