import { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import useEthBalances from "../../hooks/web3/UseEthBalances";
import useWeb3, { Web3Chain } from "../../hooks/web3/UseWeb3";
import useEthAddresses from "../../hooks/web3/UseEthAddresses";
import ContractInfo from "../shared/ContractInfo";
import useMemecoinContract from "../../hooks/web3/UseMemecoinContract";
import useWeiConversions from "../../hooks/web3/UseWeiConversions";

import settings from "../../settings";
import * as BigNumber from "bignumber.js";


function EthBalances() {

  const [ adminBankAllowance, setAdminBankAllowance ] = useState<BigNumber.BigNumber | null>(null);

  const { web3chain: chain } = useWeb3();
  const { 
    loading: loadingAddresses, 
    factoryContractAddress, candidateContractAddress, auctionContractAddress, 
    bankContractAddress, bankWithdrawAuthorizationContractAddress, dmemSellerContractAddress,
    adminAddress
  } = useEthAddresses();
  const { 
    loading, 
    adminBalance, adminMEMBalance, 
    factoryBalance, factoryMEMBalance,
    candidateBalance, candidateMEMBalance,
    auctionBalance, auctionMEMBalance,
    bankBalance, bankMEMBalance,
    bankWithdrawAuthorizationBalance, bankWithdrawAuthorizationMEMBalance,
    dmemSellerBalance, dmemSellerMEMBalance,
  } = useEthBalances();
  const { getAllowance } = useMemecoinContract();
  const { weiToEth } = useWeiConversions();

  useEffect(() => {
    if (loading || loadingAddresses) {
      return;
    }

    const fetchOwnerBankAllowance = async () => {
      const allowance = await getAllowance(adminAddress!, bankContractAddress!);
      setAdminBankAllowance(weiToEth(allowance));
    }

    fetchOwnerBankAllowance();
  }, [loading, loadingAddresses, adminAddress, bankContractAddress])

  

  return (
    <Card>
      <CardHeader title="Blockchain Balances"></CardHeader>
      <CardContent>
        {
          loading
          ?
          <CircularProgress />
          :
          <div>
            {
              chain !== Web3Chain.unknown
              ?
              <>
                <ContractInfo 
                  balance={adminBalance} address={adminAddress!} label={"Admin"} memBalance={adminMEMBalance}
                  additionalInfo={{Allowance: adminBankAllowance? adminBankAllowance.toString() + ' MEM' : 'Loading...'}}></ContractInfo>
                <IncreaseAllowanceButton></IncreaseAllowanceButton>
                <ContractInfo balance={factoryBalance} memBalance={factoryMEMBalance} address={factoryContractAddress!} label={"Factory"}></ContractInfo>
                <ContractInfo balance={candidateBalance} memBalance={candidateMEMBalance} address={candidateContractAddress!} label={"Candidate"}></ContractInfo>
                <ContractInfo balance={auctionBalance} memBalance={auctionMEMBalance} address={auctionContractAddress!} label={"Auction"}></ContractInfo>
                { 
                  chain === Web3Chain.matic &&
                  <>
                    <ContractInfo balance={bankBalance} memBalance={bankMEMBalance} address={bankContractAddress!} label={"Bank"}></ContractInfo>
                    <ContractInfo balance={bankWithdrawAuthorizationBalance} memBalance={bankWithdrawAuthorizationMEMBalance} address={bankWithdrawAuthorizationContractAddress!} label={"Bank authorization"}></ContractInfo>
                  </>
                }
                <ContractInfo balance={dmemSellerBalance} memBalance={dmemSellerMEMBalance} address={dmemSellerContractAddress!} label={"dMEM Seller"}></ContractInfo>
              </>
              :
              <Typography variant="body1">
                Unknown ETH chain. Please switch to supported chain in your wallet.
              </Typography>
            }
          </div>
        }
      </CardContent>
    </Card>
  )

}

export default EthBalances;


function IncreaseAllowanceButton() {

  const [ dialogOpen, setDialogOpen ] = useState<boolean>(false);
  const [ memAmount, setMemAmount ] = useState<string>("0");
  const { web3account, web3chain } = useWeb3();
  const { increaseAllowance } = useMemecoinContract();
  const { bankContractAddress } = useEthAddresses();
  const { memToWei } = useWeiConversions();

  const onButtonClicked = () => {
    setDialogOpen(!dialogOpen)
  }

  const onCancel = () => {
    setDialogOpen(false);
  }

  const onConfirmed = () => {
    increaseAllowance(bankContractAddress!, memToWei(memAmount)!)
    setDialogOpen(false);
  }

  return <>
    <Button 
      disabled={web3account !== settings.admin_wallet.address || web3chain !== Web3Chain.matic} 
      variant="contained" color="primary" size="small"
      onClick={() => onButtonClicked()} 
    >
      Increase allowance
    </Button>

    <Dialog open={dialogOpen}>
      <DialogTitle>Increase allowance for bank (Bank will be able to use that amount of MEM of currently logged in wallet)?</DialogTitle>
      <DialogContent>
        <Typography>Type MEM amount:</Typography>
        <TextField type="number" label="MEM amount" value={memAmount} onChange={(event) => setMemAmount(event.target.value)}></TextField>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onCancel} color="primary" variant="contained">Cancel</Button>
        <Button onClick={onConfirmed} variant="contained">Confirm</Button> 
      </DialogActions>
    </Dialog>
  </>
}