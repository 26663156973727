import { AdminTaskInfoResponse } from './../buttons/applyParserRule';
import { 
  LevelResponse, BooleanResponse, GetCardImageResponse, GetSignatureTemplateReponse, 
  ExportWinningCardsLeagueResponse, TestParserRuleResponse, ValidateAppConfigBody, PreviewCardMetadataByScrappingResponse, CeleryQueueInfo, DailyDmemRewardPayoutResponse, NextPayoutStatsResponse, MarbleNotificationRecipientResponse, DomainResponse, GetQueueOverviewResponse, IsCensoredResponse, SendDmemResponse, TransactionStatusResponse, GetMemecoinStatsResponse, MaybeErrorResponse
} from './../webservice/models';
import { Identifier, useRedirect } from 'react-admin';
import settings from '../settings'
import { logout } from '../authClient';
import { ExperienceResponse } from '../webservice/models';
import { AdminTask } from '../components/dashboard/AdminTasks';
import * as BigNumber from 'bignumber.js';


export function useWebservice() {

  const redirct = useRedirect();

  function uploadFile<ReturnType, BodyType = any>(task: string, data: BodyType, file: File): Promise<ReturnType> {
    const body = new FormData()
    body.append('file', file)
    body.append('data', JSON.stringify(data))

    return _callTask<ReturnType>(task, body)
  }

  async function _callJsonTask<ReturnType, BodyType = any>(task: string, data: BodyType | null = null): Promise<ReturnType> {
    const body = JSON.stringify(data);
    return _callTask<ReturnType>(task, body);
  };

  function _callTask<ReturnType>(task: string, body: string | FormData): Promise<ReturnType> {
    let headers = new Headers();
    _setAuthorizationHeader(headers);

    return fetch(`${settings.api.task_url}/${task}`, {
      headers: headers,
      method: 'POST',
      body: body
    }).then(response => {
      if (!response.ok) {
        if (response.status == 403 || response.status == 401) {
          // localStorage.removeItem('token');
          // throw Error("Not authenticated")
          logout()
          redirct("/login")
          return Promise.reject()
        }

        console.error(`Error occured when calling webservice task '${task}'.`);
        console.error(`Detail of the error: ${response}`);
        let error_response = { 'error_response': response };
        throw error_response;
      }

      return response.json();
    });

    function _setAuthorizationHeader(headers: Headers) {
      const authToken = localStorage.getItem('token');
      headers.set('Authorization', `Bearer ${authToken}`);
    }
  }

  const testPageParserRule = (ruleType: "TITLE" | "IMAGE_URL" | "CANONICAL_URL", xpath: string | null, regex: string | null, format: string | null, testPageUrl: string): Promise<TestParserRuleResponse> => {
    return _callJsonTask<TestParserRuleResponse>('admin/test_parser_rule_task', {rule_type: ruleType, xpath: xpath, regex: regex, format: format, test_page_url: testPageUrl});
  };

  const levelUpCard = (nftId: number): Promise<LevelResponse> => {
    return _callJsonTask<LevelResponse>('admin/level_up_card_task', {nft_id: nftId});
  };

  const levelDownCard = (nftId: number): Promise<LevelResponse> => {
    return _callJsonTask<LevelResponse>('admin/level_down_card_task', {nft_id: nftId});
  };

  const addXPToWallet = (account: string, xpAmount: number): Promise<ExperienceResponse> => {
    return _callJsonTask<ExperienceResponse>('admin/add_xp_to_wallet_task', {account: account, amount: xpAmount});
  };

  const removeXPFromWallet = (account: string, xpAmount: number): Promise<ExperienceResponse> => {
    return _callJsonTask<ExperienceResponse>('admin/remove_xp_from_wallet_task', {account: account, amount: xpAmount});
  };

  const changeNftCardThumbnailImage = (nftId: number, imageUrl: string): Promise<BooleanResponse> => {
    return _callJsonTask<BooleanResponse>('admin/update_card_page_image_task', {nft_id: nftId, image_url: imageUrl});
  };

  const changeNftCardBackgroundImage = (nftId: number, imageUrl: string): Promise<BooleanResponse> => {
    return _callJsonTask<BooleanResponse>('admin/update_card_bgr_with_custom_image_task', {nft_id: nftId, bgr_image_url: imageUrl});
  };

  const changeNftCardSignatureImage = (nftId: number, imageUrl: string): Promise<BooleanResponse> => {
    return _callJsonTask<BooleanResponse>('admin/change_card_signature_image_task', {nft_id: nftId, signature_image_url: imageUrl ? imageUrl : '' });
  };

  const getCardImage = (nftId: number): Promise<GetCardImageResponse> => {
    return _callJsonTask<GetCardImageResponse>('admin/get_card_image_task', {nft_id: nftId});
  };

  const kickCardFromLeague = (leagueCardId: number): Promise<BooleanResponse> => {
    return _callJsonTask<BooleanResponse>('admin/kick_card_from_league_task', {league_card_id: leagueCardId});
  };

  const denyLeagueCardApplication = (leagueApplicationId: number): Promise<BooleanResponse> => {
    return _callJsonTask<BooleanResponse>('admin/deny_league_card_application_task', {application_id: leagueApplicationId});
  };

  const acceptLeagueCardApplication = (leagueApplicationId: number): Promise<BooleanResponse> => {
    return _callJsonTask<BooleanResponse>('admin/accept_league_card_application_task', {application_id: leagueApplicationId});
  };

  const getSignatureTemplateImage = (nftId: number): Promise<GetSignatureTemplateReponse> => {
    return _callJsonTask<GetSignatureTemplateReponse>('admin/get_signature_template_image_task', {nft_id: nftId});
  };

  const exportWinningCardsLeague = (leagueId: number, _dateFrom: Date, _dateTo: Date, exportType: 'winning-cards' | 'all-battles'): Promise<ExportWinningCardsLeagueResponse> => {
    return _callJsonTask<ExportWinningCardsLeagueResponse>('admin/export_league_stats_task', {league_id: leagueId, date_from: _dateFrom, date_to: _dateTo, export_type: exportType});
  };

  const updateCardMetadata = (nftId: number, title: string): Promise<BooleanResponse> => {
    return _callJsonTask<BooleanResponse>('admin/card_metadata/update_task', {nft_id: nftId, title: title});
  }

  const updateCardMetadataByScraping = (nftId: number): Promise<BooleanResponse> => {
    return _callJsonTask<BooleanResponse>('admin/card_metadata/update_by_scraping_task', {nft_id: nftId});
  }

  const existsOtherCreatorWithNameTask = (creatorId: number, creatorName: string): Promise<BooleanResponse> => {
    return _callJsonTask<BooleanResponse>('admin/art_creators/exists_other_creator_with_name_task', {creator_id: creatorId, name: creatorName});
  };

  const validateAppConfigChange = (appConfig: ValidateAppConfigBody): Promise<BooleanResponse> => {
    return _callJsonTask<BooleanResponse>('admin/validate_app_config_change_task', appConfig)
  };

  const acceptArtCreatorApplication = (applicationId: number): Promise<BooleanResponse> => {
    return _callJsonTask<BooleanResponse>("admin/art_creator_applications/accept_art_creator_application_task", { art_creator_application_id: applicationId });
  }

  const declineArtCreatorApplication = (applicationId: number): Promise<BooleanResponse> => {
    return _callJsonTask<BooleanResponse>("admin/art_creator_applications/decline_art_creator_application_task", { art_creator_application_id: applicationId });
  }

  const acceptVerificationBid = (bidId: number): Promise<BooleanResponse> => {
    return _callJsonTask<BooleanResponse>('admin/art_creators/accept_verification_bid_by_admin_task', { verification_bid_id: bidId });
  }

  const declineVerificationBid = (bidId: number): Promise<BooleanResponse> => {
    return _callJsonTask<BooleanResponse>('admin/art_creators/decline_verification_bid_by_admin_task', { verification_bid_id: bidId });
  }

  const applyParserRuleOnExistingCards = (ruleId: Identifier, applyOnGenesisCards: boolean, startNftId: number | undefined, endNftId: number | undefined): Promise<{task_id: number}> => {
    return _callJsonTask<{task_id: number}>("admin/parser_rules/apply_rule_on_existing_cards_task", { 
      parser_rule_id: ruleId,
      apply_on_genesis_origin: applyOnGenesisCards,
      start_nft_id: startNftId,
      end_nft_id: endNftId,
    })
  }

  const getAdminTaskStatus = (taskId: Identifier): Promise<AdminTaskInfoResponse> => {
    return _callJsonTask<AdminTaskInfoResponse>("admin/admin_tasks/get_task_status_task", { task_id: taskId })
  }

  const continueAdminTask = (taskId: number): Promise<{task_id: number}> => {
    return _callJsonTask<{task_id: number}>('admin/admin_tasks/continue_task_task', { admin_task_id: taskId })
  }

  const censorDomain = (domainId: Identifier, internal: boolean): Promise<{censor_task_id: number}> => {
    return _callJsonTask<{censor_task_id: number}>('admin/card_censoring/censor_domain_task', { domain_id: domainId, internal: internal });
  }

  const uncensorDomain = (domainId: Identifier, internal: boolean): Promise<{censor_task_id: number}> => {
    return _callJsonTask<{censor_task_id: number}>('admin/card_censoring/uncensor_domain_task', { domain_id: domainId, internal: internal });
  }

  const rescrapeCardImages = (domainIds: number[], cardTextRegex: string, startNftId: number | null): Promise<{admin_task_id: number}> => {
    return _callJsonTask<{admin_task_id: number}>("admin/card/rescrape_card_images_task", { 
      domain_ids: domainIds, 
      card_text_regex: cardTextRegex, 
      start_nft_id: startNftId
    })
  }

  const changeDomainFavicon = (domainId: number, faviconUrl: string): Promise<BooleanResponse> => {
    return _callJsonTask<BooleanResponse>("admin/domain_favicon/change_domain_favicon_task", { domain_id: domainId, favicon_url: faviconUrl });
  }

  const updateFaviconForExistingCards = (domainId: number, applyOnGenesisCards: boolean, updateTitles: boolean, startNftId: number | undefined | null, endNftId: number | undefined): Promise<{task_id: number}> => {
    return _callJsonTask<{task_id: number}>("admin/domain_favicon/update_existing_cards_task", { 
      domain_id: domainId, 
      use_on_genesis_origin: applyOnGenesisCards, 
      update_titles: updateTitles,
      first_nft_id: startNftId,
      last_nft_id: endNftId,
    });
  }

  const getAdminTasksOverview = (amount: number, maxHoursAgo: number): Promise<AdminTask[]> => {
    return _callJsonTask<AdminTask[]>('admin/admin_tasks/get_overview_task', { limit: amount, hours_ago: maxHoursAgo });
  }

  const previewCardMetadataByScraping = (nftId: number): Promise<PreviewCardMetadataByScrappingResponse> => {
    return _callJsonTask<PreviewCardMetadataByScrappingResponse>('admin/card_metadata/preview_scrape_task', {nft_id: nftId});
  }

  const getWorkerQueuesOverview = (): Promise<CeleryQueueInfo[]> => {
    return _callJsonTask<CeleryQueueInfo[]>('admin/celery_tasks/get_queues_overview_task');
  }

  const getDailyRewardsWeeklyStats = (): Promise<DailyDmemRewardPayoutResponse[]> => {
    return _callJsonTask<DailyDmemRewardPayoutResponse[]>('admin/daily_dmem/get_weekly_stats_task');
  }

  const getDailyRewardsNextPayout = (): Promise<NextPayoutStatsResponse> => {
    return _callJsonTask<NextPayoutStatsResponse>('admin/daily_dmem/get_next_payout_stats_task');
  }

  const scheduleSendingdMissingDailyRewards = (payoutDate: Date): Promise<BooleanResponse> => {
    const body = { payout_date: payoutDate.toISOString().slice(0, 10) }
    return _callJsonTask<BooleanResponse>('admin/daily_dmem/schedule_sending_missing_rewards_task', body)
  }

  const getSignedCardPreview = (uploadedImageId: number): Promise<{signed_card_preview_url: string}> => {
    return _callJsonTask<{signed_card_preview_url: string}>("image/get_signed_card_preview_task", {
      uploaded_autograph_image_public_id: uploadedImageId,
    });
  }

  const rescrapeCardImage = (nftId: number): Promise<BooleanResponse> => {
    return _callJsonTask<BooleanResponse>('admin/card/rescrape_card_image_task', { nft_id: nftId })
  }

  const hasSignature = (nftId: number): Promise<BooleanResponse> => {
    return _callJsonTask<BooleanResponse>("admin/marble_nft/has_signature_task", {nft_id: nftId}) 
  }

  const removeSignature = (nftId: number): Promise<BooleanResponse> => {
    return _callJsonTask<BooleanResponse>("admin/card/remove_signature_image_task", { nft_id: nftId })
  }

  const removeArtCretorAutograph = (nftId: number): Promise<BooleanResponse> => {
    return _callJsonTask<BooleanResponse>("admin/card/remove_art_creator_autograph_task", { nft_id: nftId })
  }

  const generateNewApiKeyForMarbleNotificationsRecipient = (recipientId: number): Promise<BooleanResponse> => {
    return _callJsonTask<BooleanResponse>('admin/notifications/generate_new_recipient_api_key_task', { recipient_id: recipientId });
  }

  const getMarbleNotificationRecipientOverview = (recipientId: number, fromDate: Date | null, toDate: Date | null, latestRequestsCount: number): Promise<MarbleNotificationRecipientResponse> => {
    return _callJsonTask<MarbleNotificationRecipientResponse>('admin/notifications/get_recipient_overview_task', {
      recipient_id: recipientId,
      from_time: fromDate,
      to_time: toDate,
      requests_count: latestRequestsCount
    })
  }

  const getAllDomains = (): Promise<DomainResponse[]> => {
    return _callJsonTask<DomainResponse[]>('search/get_domains_task');
  }

  const notifyOpenseaAboutNftUpdates = (startNftId: number | string, endNftId: number | string, domainIds: number[]): Promise<BooleanResponse> => {
    return _callJsonTask<BooleanResponse>('admin/opensea/notify_nft_updates_task', {
      start_nft_id: startNftId,
      end_nft_id: endNftId,
      domain_ids: domainIds
    })
  }

  const getOutgoingPendingWeb3TransactionsOverview = (): Promise<GetQueueOverviewResponse> => {
    return _callJsonTask<GetQueueOverviewResponse>('admin/transactions/get_queue_overview_task'); 
  }

  const setGasPrice = (newGasPrice: number | string): Promise<BooleanResponse> => {
    return _callJsonTask<BooleanResponse>('admin/transactions/set_gas_price_task', { gas_price_wei: newGasPrice });
  }

  const isCardCensored = (nftId: number): Promise<IsCensoredResponse> => {
    return _callJsonTask<IsCensoredResponse>('admin/card_censoring/is_censored_task', { nft_id: nftId });
  }

  const censorCard = (nftId: number): Promise<{image_url: string}> => {
    return _callJsonTask<{image_url: string}>('admin/card_censoring/censor_card_task', { nft_id: nftId });
  }

  const uncensorCard = (nftId: number): Promise<BooleanResponse> => {
    return _callJsonTask<BooleanResponse>('admin/card_censoring/uncensor_card_task', { nft_id: nftId });
  }

  const addDmemToWallet = (userAddress: string, amountWei: number | string, message: string): Promise<SendDmemResponse> => {
    return _callJsonTask<SendDmemResponse>('admin/transactions/add_dmem_to_wallet_task', {
      user_address: userAddress,
      amount_wei: amountWei,
      message: message
    });
  }

  const removeDmemFromWallet = (userAddress: string, amountWei: number | string, message: string): Promise<SendDmemResponse> => {
    return _callJsonTask<SendDmemResponse>('admin/remove_dmem_from_wallet_task', { 
      user_address: userAddress, 
      amount_wei: amountWei, 
      message: message 
    });
  } 

  const getWeb3TransactionStatus = (transactionId: number): Promise<TransactionStatusResponse> => {
    return _callJsonTask<TransactionStatusResponse>('transaction_status_task', { transaction_id: transactionId });
  }

  const getMemecoinStats = (): Promise<GetMemecoinStatsResponse> => {
    return _callJsonTask<GetMemecoinStatsResponse>('admin/stats/get_memecoin_stats_task');
  }

  const setEthToMemRate = (newRateWei: BigNumber.BigNumber): Promise<MaybeErrorResponse> => {
    return _callJsonTask<MaybeErrorResponse>('admin/set_eth_to_mem_rate_task', { rate: newRateWei.toString() })
  }

  const setMaticToMemRate = (newRateWei: BigNumber.BigNumber): Promise<MaybeErrorResponse> => {
    return _callJsonTask<MaybeErrorResponse>('admin/set_matic_to_mem_rate_task', { rate: newRateWei.toString() })
  }

  return {
    uploadFile,

    testPageParserRule,
    levelUpCard,
    levelDownCard,
    addXPToWallet,
    removeXPFromWallet,
    changeNftCardThumbnailImage,
    changeNftCardBackgroundImage,
    changeNftCardSignatureImage,
    getCardImage,
    kickCardFromLeague,
    denyLeagueCardApplication,
    acceptLeagueCardApplication,
    getSignatureTemplateImage,
    exportWinningCardsLeague,
    updateCardMetadata,
    updateCardMetadataByScraping,
    existsOtherCreatorWithNameTask,
    validateAppConfigChange,
    acceptArtCreatorApplication,
    declineArtCreatorApplication,
    acceptVerificationBid,
    declineVerificationBid,
    applyParserRuleOnExistingCards,
    getAdminTaskStatus,
    continueAdminTask,
    censorDomain,
    uncensorDomain,
    rescrapeCardImages,
    changeDomainFavicon,
    updateFaviconForExistingCards,
    getAdminTasksOverview,
    previewCardMetadataByScraping,
    getWorkerQueuesOverview,
    getDailyRewardsWeeklyStats,
    getDailyRewardsNextPayout,
    scheduleSendingdMissingDailyRewards,
    getSignedCardPreview,
    rescrapeCardImage,
    hasSignature,
    removeSignature,
    removeArtCretorAutograph,
    generateNewApiKeyForMarbleNotificationsRecipient,
    getMarbleNotificationRecipientOverview,
    getAllDomains,
    notifyOpenseaAboutNftUpdates,
    getOutgoingPendingWeb3TransactionsOverview,
    setGasPrice,
    isCardCensored,
    censorCard,
    uncensorCard,
    addDmemToWallet,
    removeDmemFromWallet,
    getWeb3TransactionStatus,
    getMemecoinStats,
    setEthToMemRate,
    setMaticToMemRate,
  }
  
}