import {
    List, Datagrid,
    DateField, TextField, NumberField, ReferenceField, FunctionField,
    EditButton,
    NumberInput, ReferenceInput, SelectInput, AutocompleteInput
} from 'react-admin';
import AcceptCardVerificationBidButton from './../../buttons/acceptCardVerificationBidButton';
import DeclineCardVerificationBidButton from './../../buttons/declineCardVerificationBidButton';
import useWeiConversions from './../../hooks/web3/UseWeiConversions';


const CardVerificationBidsFilter = [
    <ReferenceInput label="Art creator" source="requested_art_creator_id" reference="art_creators" >
        <SelectInput optionText="id" />
    </ReferenceInput>,
    <ReferenceInput label="Requested by" source="requested_by_wallet_id" reference="wallets" perPage={10} >
        <AutocompleteInput optionText={record => `${record.account} (${record.username})`} />
    </ReferenceInput>,

    <NumberInput label="NFT ID" source="nft_id"  />
]


export const CardVerificationBidsList = () => {
    const { weiToMem } = useWeiConversions();

    return (
        <List filters={CardVerificationBidsFilter}>
            <Datagrid>
                <TextField source="id" />
                <ReferenceField label="Art creator id" source="requested_art_creator_id" reference="art_creators">
                    <TextField source="id"></TextField>
                </ReferenceField>
                <ReferenceField label="Requested by" source="requested_by_wallet_id" reference="wallets">
                    <TextField source="account"></TextField>
                </ReferenceField>
                <NumberField source="nft_id" />
                <FunctionField
                    label="Offered $MEM"
                    render={(record: any) => `${weiToMem(record.offered_mem_wei_amount)}`}
                />
                <FunctionField
                    label="Royalty"
                    render={(record: any) => `${parseFloat(record.royalty_percentage)}%`}
                />
                <TextField source="status" />
                <DateField source="created_at" />
                <DateField source="closed_at" />

                <AcceptCardVerificationBidButton />
                <DeclineCardVerificationBidButton />
                <EditButton />
            </Datagrid>
        </List>
    )
};
