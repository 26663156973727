import React, { useState } from 'react';

import { useNotify, useRecordContext } from 'react-admin';

import Button from '@mui/material/Button';

import IconCancel from '@mui/icons-material/Cancel';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import { useWebservice } from '../../../../hooks/UseWebservice';


interface ExampleSignatureProps {
    imageUrl?: string;
    title?: string;
}

function ExampleSignature(props: ExampleSignatureProps) { 
    return (
        <div className="signature-example" style={ {
            backgroundImage: "url(" + props.imageUrl + ")",
        } } >
        </div>
    )
}

function ChangeCardSignatureButton() {

    const { changeNftCardSignatureImage, getSignatureTemplateImage } = useWebservice();

    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [currentSignatureImage, setCurrentSignatureImage] = useState<string>();
    const [signatureTemplateImage, setSignatureTemplateImage] = useState<string>();
    const [newSignatureImageUrl, setNewSignatureImageUrl] = useState<string>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const notify = useNotify();
    const record = useRecordContext();

    const handleOpenDialog = async () => {
        setDialogOpen(true);
        if (signatureTemplateImage == null) {
            const response = await getSignatureTemplateImage(record.nft_id)
            setSignatureTemplateImage(response.template_url)
            setCurrentSignatureImage(response.signature_image)
        }
    }

    const handleSubmit = async () => {
        setIsSubmitting(true);

        notify(`Updating image, please wait for the next notification`, { type: "info" });

        try {
            const response = await changeNftCardSignatureImage(record.nft_id, newSignatureImageUrl!);
            if (response.error) {
                notify("Error changing the image! " + response.error, { type: "warning" })
            }
            else {
                notify(`Signature successfully changed to ${newSignatureImageUrl}`, { type: "info" })
            }
        } catch (error: any) {
            notify("Error on webservice! " + error.message, { type: "warning" })
        } finally {
            setIsSubmitting(false);
            setDialogOpen(false);
        }
    }

    return (<>
        <Button onClick={handleOpenDialog} style={{ height: "100%", width: "100%" }}>
            Change signature image
        </Button>

        <Dialog
            fullWidth
            open={dialogOpen}
            onClose={() => setDialogOpen(false)} 
        >

            <DialogTitle>Change card signature</DialogTitle>
            <DialogContent>
                <span>
                    The signature image is overlayed over the card image. If the size of the
                    signature image is different than the card's size, it will be resized! To create a signature,
                    use transparent PNG image! If you are not sure how to create the image, download template image
                    on this
                </span>
                <a href={signatureTemplateImage}> link</a>
                <div><br />Current signature: <a href={currentSignatureImage}>{currentSignatureImage}</a></div>
                

                <TextField required label="Signature image URL" value={newSignatureImageUrl} onChange={(e) => setNewSignatureImageUrl(e.target.value)}  />
                <ExampleSignature imageUrl={newSignatureImageUrl} title="Image from URL" />
            </DialogContent>

            <DialogActions>
                {
                    isSubmitting
                    ?
                    <CircularProgress />
                    :
                    <Button onClick={handleSubmit} color="primary" variant="contained">Save</Button>
                }
                <Button
                    title="Cancel"
                    onClick={() => setDialogOpen(false)}
                >
                    <IconCancel/>
                </Button>
            </DialogActions>
        </Dialog>
    </>)
}

export default ChangeCardSignatureButton;
