import { useEffect, useState } from "react";
import useContracts from "./UseEthAddresses"
import useWeb3 from "./UseWeb3";


export default function useBankWithdrawalAuthorization() {

  const [contractOwner, setContractOwner] = useState(null);

  const { web3account } = useWeb3();
  const { bankWithdrawAuthorizationContract } = useContracts();

  useEffect(() => {
    const fetchOwner = async () => {
      const owner = await bankWithdrawAuthorizationContract!.methods.owner().call();
      setContractOwner(owner);
    }

    if (bankWithdrawAuthorizationContract != null) {
      fetchOwner();
    }
  }, [bankWithdrawAuthorizationContract])

  const isWhitelisted = async function (address: string): Promise<boolean> {
    return await bankWithdrawAuthorizationContract!.methods.whitelist(address).call();
  }

  const addToWhitelist = async function (address: string) {
    return await bankWithdrawAuthorizationContract!.methods.addToWhitelist(address).send({ from: web3account });
  }
  
  const removeFromWhitelist = async function (address: string) {
    return await bankWithdrawAuthorizationContract!.methods.removeFromWhitelist(address).send({ from: web3account });
  }

  return {
    contractOwner,
    isWhitelisted,
    addToWhitelist,
    removeFromWhitelist,
  }
}