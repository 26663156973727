import {
    Datagrid,
    Filter,
    FunctionField,
    List,
    NumberField,
    BooleanField,
    NumberInput,
    BooleanInput,
    Edit,
    SimpleForm,
    EditButton,
    SelectInput,
    TextField,
    useRecordContext
} from 'react-admin';

import KickFromLeagueButton from './../../buttons/kickFromLeagueButton'
import AcceptCardToLeagueButton from "./../../buttons/acceptCardToLeagueButton";

const ConditionalAcceptCardToLeagueButton = () => {
    const record = useRecordContext();

    return record && record.status === 'APPLICATION' && !record.obsolete
        ? <AcceptCardToLeagueButton />
        : null;
}

const ConditionalKickFromLeagueButton = () => {
    const record = useRecordContext();

    return record && !record.obsolete
        ? <KickFromLeagueButton />
        : null;
}

const applicationFeeSummary = (record: any) => {
    if (record.application_fee_xp === 0 && record.application_fee_mem_wei === '0') {
        return "No fee";
    } else if (record.application_fee_xp > 0 && record.application_fee_mem_wei !== '0') {
        return `${record.application_fee_xp} XP + ${record.application_fee_mem} MBC`;
    } else if (record.application_fee_xp > 0) {
        return `${record.application_fee_xp} XP`;
    } else if (record.application_fee_mem_wei !== '0') {
        return `${record.application_fee_mem} MBC`;
    } else {
        return "Error";
    }
};

const LeagueCardsFilter = [
    <NumberInput label="NFT ID" source="card_nft_id" />,
    <NumberInput label="League ID" source="arena_league_id" />,
    <BooleanInput label="Obsolete" source="obsolete" />,
    <SelectInput label="State" source="status"  choices={[
        { id: 'APPLICATION', name: 'Application' },
        { id: 'HIDDEN', name: 'Hidden' },
        { id: 'VISIBLE', name: 'Visible' },
    ]} />
]

export const LeagueCardsList = () => (
    <List sort={{ field: 'id', order: 'DESC' }} filters={LeagueCardsFilter} >
      <Datagrid>
        <NumberField source="id" />
        <NumberField source="arena_league_id" />
        <NumberField source="card_nft_id" />
        <NumberField source="score" />
        <TextField source="status" />
        <FunctionField label="Application fee" render={applicationFeeSummary}/>
        <NumberField source="battles_played" />
        <NumberField source="battles_won" />
        <BooleanField source="obsolete" />
        <ConditionalKickFromLeagueButton />
        <ConditionalAcceptCardToLeagueButton />
        <EditButton />
      </Datagrid>
    </List>
);

const LeagueCardsTitle = ({ card }: any) => {
  return <span>League card {card? `"${card.id}"` : ''}</span>
};

export const LeagueCardsEdit = () => (
    <Edit title={<LeagueCardsTitle />}>
      <SimpleForm>
        <NumberField source="id" />
        <NumberInput source="score" />
        <TextField label="State" source="status" />
        <NumberInput source="battles_played" />
        <NumberInput source="battles_won" />
        <NumberField label="Application fee XP" source="application_fee_xp" />
        <NumberField label="Application fee MBC" source="application_fee_mem" />
      </SimpleForm>
    </Edit>
);
