import { Divider, Typography } from '@mui/material';

import {
    Create,
    Datagrid,
    Edit,
    EditButton,
    Labeled,
    List,
    NumberField,
    required,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    useRecordContext,
    useRefresh
} from 'react-admin';
import NotificationRecipientRequestsOverview from '../../components/notificationRecipientRequestsOverview';
import ConfirmActionButton from '../../components/shared/ConfirmActionButton';
import HealthField from '../../components/shared/HealthField';

import SecretField from '../../components/shared/SecretField';
import { useWebservice } from '../../hooks/UseWebservice';


function GenerateApiKeyButton() {

  const { generateNewApiKeyForMarbleNotificationsRecipient } = useWebservice();

  const refresh = useRefresh();

  const onRegenerateApiKey = async (record: any) => {
    await generateNewApiKeyForMarbleNotificationsRecipient(record.id);
    refresh();
  }

  return (
    <ConfirmActionButton
      onConfirmed={onRegenerateApiKey}
      buttonLabel="New API key"
      dialogTitle="Confirm new API key generation"
      actionLabel="Generate new API key. WARNING: this will break functionality of the current recipient"
      onSuccess="New API key generated"
    />
  )
}


export function MarbleNotificationRecipientsList() {


  return (
    <List>
      <Datagrid>
        <NumberField source="id" />
        <TextField source="name" />
        <TextField source="url" />
        <SecretField source="api_key" label="API Key" />
        <GenerateApiKeyButton />
        <HealthField totalCountSource="requests_summary_3days.requests_count" failedCountSource="requests_summary_3days.failed_count" label="Health last 24 hours" />
        <HealthField totalCountSource="requests_summary_24hours.requests_count" failedCountSource="requests_summary_24hours.failed_count" label="Health last 24 hours" />
        <HealthField totalCountSource="requests_summary_1hour.requests_count" failedCountSource="requests_summary_1hour.failed_count" label="Health last hour" />
        <ShowButton label="Detail"/>
        <EditButton />
      </Datagrid>
    </List>
  )
}

const Title = () => {
    const record = useRecordContext();
    return <span>Notifications recipient {record? `"${record.name}" (${record.id})` : ''}</span>
};

export const MarbleNotificationRecipientCreate = () => (
  <Create title={<Title />}>
    <SimpleForm>
      <TextInput source="name" validate={required()}/>
      <TextInput source="url" validate={required()}/>
    </SimpleForm>
  </Create>
);

export const MarbleNotificationRecipientEdit = () => (
  <Edit title={<Title />}>
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="name" validate={required()} />
      <TextInput source="url" validate={required()} />
      <Labeled>
        <SecretField source="api_key" label="API Key" fullWidth />
      </Labeled>
      <GenerateApiKeyButton />
    </SimpleForm>
  </Edit>
);


export const MarbleNotificationRecipientShow = () => (
  <Show title={<Title />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="url" />
      <Labeled>
        <SecretField source="api_key" label="API Key" fullWidth />
      </Labeled>

      <Divider textAlign="left"><Typography variant="caption">Request stats</Typography></Divider>
      <NotificationRecipientRequestsOverview />
    </SimpleShowLayout>
  </Show>
);
