import { 
  Badge, Box, Button, Chip, CircularProgress, Divider, List, ListItem, 
  ListItemText, Paper, Slider, Stack, TextField, Typography 
} from "@mui/material";
import { useEffect, useState } from "react";
import { useRecordContext } from "react-admin"
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useWebservice } from "../hooks/UseWebservice";


interface Overview {
  responseCodes: {
    responseCode: number;
    occuredCount: number;
  }[];
  latestRequests: {
    timestamp: Date;
    statusCode: number;
    durationInSeconds: number;
  }[];
}


export default function NotificationRecipientRequestsOverview() {

  const { getMarbleNotificationRecipientOverview } = useWebservice();

  const record = useRecordContext();
  const [overview, setOverview] = useState<Overview | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [latestRequestsCount, setLatestRequestsCount] = useState<number>(15);
  const [fromDate, setFromDate] = useState<Date | null>(getDaysAgoDate(7));
  const [toDate, setToDate] = useState<Date | null>(new Date());

  function getDaysAgoDate(days: number): Date {
    const date = new Date();
    date.setDate(date.getDate() - days);
    
    return date;
  }

  const onGetDataClick = () => {
    loadData();
  }

  const loadData = async () => {
    setLoading(true)
    try {
      const response: {
        response_codes: { response_code: number, occurred_count: number }[],
        latest_requests: { sent_at: string, request_duration_seconds: number, status_code: number }[]
      } = await getMarbleNotificationRecipientOverview(record.id as number, fromDate, toDate, latestRequestsCount);

      const overviewModel: Overview = {
        responseCodes: response.response_codes.map(code => { return { 
          responseCode: code.response_code, occuredCount: code.occurred_count
        }}),
        latestRequests: response.latest_requests.map(request => { return { 
          timestamp: new Date(request.sent_at), statusCode: request.status_code, durationInSeconds: request.request_duration_seconds 
        }})
      }
      setOverview(overviewModel); 
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack direction="row" spacing={4} divider={<Divider orientation="vertical" flexItem />} alignItems="center" justifyContent="center">
          <DateTimePicker
            label="From date"
            value={fromDate}
            onChange={(value) => setFromDate(value)}
            renderInput={(params: any) => <TextField {...params} />}
          />

          <DateTimePicker
            label="To date"
            value={toDate}
            onChange={(value) => setToDate(value)}
            renderInput={(params: any) => <TextField {...params} />}
          />
          
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-evenly"> 
            <Typography>Latest requests count</Typography>
            <Slider
              aria-label="Latest requests count"
              valueLabelDisplay="on"
              step={5}
              marks
              min={0}
              max={50}
              value={latestRequestsCount}
              onChange={(_, value) => setLatestRequestsCount(value as number)}
            />
          </Stack>
          <Button onClick={onGetDataClick} variant="contained">Fetch data</Button>
        </Stack>
      </LocalizationProvider>

      <Paper>
        {
          overview != null
          ?
          <>
            {
              loading
              ?
              <CircularProgress />
              :
              <>
                <Box sx={{ p: 2 }}>
                  <Stack direction="row" spacing={3}>
                  {
                    overview?.responseCodes?.map((responseCode) => (
                      <Badge badgeContent={responseCode.occuredCount} color="primary">
                        <Chip label={responseCode.responseCode} variant="filled" color={responseCode.responseCode < 400? "success" : "error"}></Chip>
                      </Badge>
                    ))
                  }
                  </Stack>

                  <List>
                    {
                      overview?.latestRequests?.map((request) => (
                        <ListItem>
                          <ListItemText primary={`${request.timestamp} - ${request.statusCode} (${request.durationInSeconds}s)`} style={{color: request.statusCode < 400? "green" : "red"}}/>
                        </ListItem>
                      ))
                    }
                  </List>
                </Box>
              </>
            }
          </>
          :
          <>
          {
            loading
            ?
            <CircularProgress />
            :
            <>
            </>
          }
          </>
        }
      </Paper>
    </>
  )
}