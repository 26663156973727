import {useState} from 'react';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { useRecordContext } from 'react-admin';
import { AdminTaskInfoResponse } from './applyParserRule';
import { useWebservice } from '../hooks/UseWebservice';


interface AdminTaskStatus {
  finished: boolean;
  total: number;
  processed: number;
  applied: number;
  error: string;
}


export default function RescrapeCardThumbnailsButton() {
  
  const { rescrapeCardImages, getAdminTaskStatus } = useWebservice();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [cardTextRegex, setCardTextRegex] = useState<string>("");
  const [startOnNftId, setStartOnNftId] = useState<number | null>(null);

  const [taskStarted, setTaskStarted] = useState<boolean>(false);
  const [taskId, setTaskId] = useState<number | null>(null);
  const [taskStatus, setTaskStatus] = useState<AdminTaskStatus | null>(null);

  const record = useRecordContext();

  const onStartRescraping = async () => {
    setTaskStarted(true);
    const result = await rescrapeCardImages([record.id as number],  cardTextRegex,  startOnNftId);
    setTaskId(result.admin_task_id);
    getTaskStatus(result.admin_task_id);
  }

  const getTaskStatus = (taskId: number) => {
    setTimeout(async () => {
      const result = await getAdminTaskStatus(taskId)
      setTaskStatus({
        finished: result.finished,
        total: result.total_nfts_count,
        processed: result.processed_nfts_count,
        applied: result.applied_on_nfts_count,
        error: result.error,
      });

      if (!result.finished) {
        getTaskStatus(taskId);
      }
    }, 5000)
  }

  return (
    <>
    { !taskStarted && <Button style={{ backgroundColor: "purple" }} onClick={() => setDialogOpen(true)}>Rescrape thumbnail images</Button> }
    { taskStarted && <Button style={{ backgroundColor: "purple" }} onClick={() => setDialogOpen(true)}>See rescraping status</Button> }

    <Dialog 
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}>

      <DialogTitle>Rescrape cards thumbnail images for domain <i>{record.domain}</i></DialogTitle>
      <DialogContent>
        <Typography>
          This will start rescraping of thumbnails for each NFT from this domain matching the given regex. If the thumbnail image
          can't be found on the page or is the same as on the card, nothing happens.
        </Typography>
        <TextField label="Card text regex" value={cardTextRegex} onChange={(e) => setCardTextRegex(e.target.value)} />

        <hr></hr>
        {!taskStarted && <>
          <Typography>You can specify on which NFT the rescraping should start. If you don't specify it, it will start on first non origin card (Card #1001).</Typography>
          <TextField label="Start on NFT id" type="number" variant="outlined" value={startOnNftId} onChange={(event) => setStartOnNftId(parseInt(event.target.value))} />
          <br></br>
          <Button style={{ backgroundColor: "red" }} onClick={onStartRescraping}>Start rescraping now!</Button>
        </>}

        {taskStarted && !taskStatus && <>
          <Typography>Starting the task</Typography>
          <CircularProgress />
        </>}

        {taskStarted && taskStatus && !taskStatus.finished && <>
          <Typography>Task {taskId} in PROGRESS</Typography>
          <Typography>NFTs to be changed: <b>{taskStatus.total}</b></Typography>
          <Typography>Processed NFTs: <b>{taskStatus.processed}</b></Typography>
          <Typography>Applied on NFTs <b>{taskStatus.applied}</b></Typography>
          <LinearProgress variant="determinate" value={(taskStatus.processed / taskStatus.total) * 100}></LinearProgress>
          <CircularProgress size={12} />
        </>}

        {taskStarted && taskStatus && taskStatus.finished && taskStatus.error && <>
          <Typography variant="caption" color="secondary">ERROR</Typography>
          <Typography>Task id: {taskId}</Typography>
          <Typography>NFTs processed <b>{taskStatus.processed}</b></Typography>
          <Typography>Applied on NFTs <b>{taskStatus.applied}</b></Typography>
        </>}

        {taskStarted && taskStatus && taskStatus.finished && !taskStatus.error && <>
          <Typography variant="caption" color="primary">SUCCESSFULLY FINISHED</Typography>
          <Typography>Task id: {taskId}</Typography>
          <Typography>Processed NFTs: <b>{taskStatus.processed}</b></Typography>
          <Typography>Applied on NFTs <b>{taskStatus.applied}</b></Typography>
        </>}
      </DialogContent>

  </Dialog>
    </>
  )
}