import { Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecordContext } from 'react-admin';


interface Props {
  totalCountSource: string;
  failedCountSource: string;
  label?: string;
}


enum Status {
  UNKNOWN,
  HEALTHY,
  UNHEALTHY,
  BROKEN,
}


export default function HealthField(props: Props) {
  const record = useRecordContext();
  const [status, setStatus] = useState<Status>(Status.UNKNOWN)
  const [failedPercentage, setFailedPercentage] = useState<number | null>(null);

  useEffect(() => {
    const totalCountValue = getSourceValue(props.totalCountSource);
    const failedCountValue = getSourceValue(props.failedCountSource);

    if (totalCountValue === null || failedCountValue === null || totalCountValue == 0) {
      setStatus(Status.UNKNOWN);
      setFailedPercentage(null);
      return;
    }

    const failedPercentage = failedCountValue / totalCountValue;
    if (failedPercentage >= 0.5) {
      setStatus(Status.BROKEN);
    } else if (failedPercentage >= 0.2) {
      setStatus(Status.UNHEALTHY);
    } else {
      setStatus(Status.HEALTHY);
    }
    setFailedPercentage(failedPercentage);
  }, [record])

  const getSourceValue = (source: string): any => {
    const sourceParts = source.split(".");
    let currentValue = record;
    
    for (const sourcePartIndex in sourceParts) {
      const sourcePart = sourceParts[sourcePartIndex];
      if (!(sourcePart in currentValue)) {
        return null;
      }

      currentValue = currentValue[sourcePart];
    }

    return currentValue;
  }

  const getStatusColor = () => {
    if (status == Status.UNKNOWN) {
      return 'gray';
    } else if (status == Status.HEALTHY) {
      return 'green';
    } else if (status === Status.UNHEALTHY) {
      return 'yellow';
    } else if (status == Status.BROKEN) {
      return 'red';
    } else {
      return 'transparent';
    }
  }
  
  return <Tooltip title={"Failed percentage: " + (failedPercentage !== null? `${Math.round(failedPercentage * 100)}%` : "Unknown")}>
    <span style={{
      height: "25px",
      width: "25px",
      backgroundColor: getStatusColor(),
      borderRadius: "50%",
      display: "inline-block"
    }}></span>
  </Tooltip>
}
