import { List, Edit, Create, Datagrid, TextField, EditButton, NumberField, SimpleForm, TextInput, BooleanInput,
    required } from 'react-admin';

export const CrawlerRuleList = () => (
    <List>
        <Datagrid>
			<TextField source="id" />
            <TextField source="regex" />
            <TextField source="format" />
            <TextField source="fix_output" />
            <EditButton />
        </Datagrid>
    </List>
);

const CrawlerRuleTitle = ({ crawlerRule }: any) => {
    return <span>Crawler rule {crawlerRule ? `(${crawlerRule.id})` : ''}</span>
};


export const CrawlerRuleCreate = () => (
    <Create title={<CrawlerRuleTitle />}>
        <SimpleForm>
            <TextInput source="regex" validate={required()}/>
            <TextInput source="format" validate={required()}/>
            <BooleanInput source="fix_output"/>
        </SimpleForm>
    </Create>
);

export const CrawlerRuleEdit = () => (
    <Edit title={<CrawlerRuleTitle />}>
        <SimpleForm>
            <NumberField source="id" />
            <TextInput source="regex" validate={required()}/>
            <TextInput source="format" validate={required()}/>
            <BooleanInput source="fix_output"/>
        </SimpleForm>
    </Edit>
);
