import { useState, useEffect } from 'react';

import { makeStyles } from '@mui/styles';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useWeiConversions from '../../hooks/web3/UseWeiConversions';

import memLogo from "../../assets/meme_coin.svg";
import * as BigNumber from 'bignumber.js';
import { useWebservice } from '../../hooks/UseWebservice';
import { useNotify } from 'react-admin';
import ConfirmActionButton from '../shared/ConfirmActionButton';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';


interface DailyDmemRewardPayout {
  id: number;
  timestamp: Date;
  rewardForDay: Date,
  memWeiLimit: BigNumber.BigNumber;
  totalExpectedMemAmountWei: BigNumber.BigNumber;
  totalTransferedMemAmountWei: BigNumber.BigNumber;
  totalExpectedTransfers: number;
  totalExecutedTransfers: number;
}

interface NextPayoutStats {
  timestamp: Date;
  toBePaidMemWei: BigNumber.BigNumber;
  wallets: {
    id: number;
    address: string;
    username: string | null;
    amountMemWei: BigNumber.BigNumber;
  }[];
}

function DailyDMEMRewards() {

  const { 
    getDailyRewardsWeeklyStats, 
    getDailyRewardsNextPayout, 
    scheduleSendingdMissingDailyRewards 
  } = useWebservice();

  const classes = useStyles();
  const [weeklyTotalData, setWeeklyTotalData] = useState<DailyDmemRewardPayout[]>();
  const [nextPayoutData, setNextPayoutData] = useState<NextPayoutStats>();
  const [sendMissingRewardsDate, setMissingRewardsDte] = useState<Date | null>(new Date());

  const { weiToMem } = useWeiConversions();

  const notify = useNotify();

  useEffect(() => {
    fetchWeeklyData();
    fetchNextPayoutData();
  }, [])

  const fetchWeeklyData = async () => {
    const weeklyStats = await getDailyRewardsWeeklyStats()
    setWeeklyTotalData(weeklyStats.slice(0, 10).map((dailyStats) => { 
      return {
        id: dailyStats.id,
        rewardForDay: new Date(dailyStats.reward_for_day),
        timestamp: new Date(dailyStats.started_at * 1000),
        memWeiLimit: new BigNumber.BigNumber(dailyStats.limit_per_user_dmem_wei),
        totalExpectedMemAmountWei: new BigNumber.BigNumber(dailyStats.total_expected_dmem_wei),
        totalTransferedMemAmountWei: new BigNumber.BigNumber(dailyStats.total_paid_dmem_wei),
        totalExpectedTransfers: dailyStats.total_expected_wallets,
        totalExecutedTransfers: dailyStats.total_paid_wallets,
      }
    }))
  }

  const fetchNextPayoutData = async () => {
    const nextPayoutStats = await getDailyRewardsNextPayout();
    setNextPayoutData({
      timestamp: new Date(nextPayoutStats.starts_at),
      toBePaidMemWei: new BigNumber.BigNumber(nextPayoutStats.total_amount_mem_wei),
      wallets: nextPayoutStats.wallets.map(walletStats => { return {
        id: walletStats.id,
        address: walletStats.address,
        username: walletStats.username !== ''? walletStats.username : null,
        amountMemWei: new BigNumber.BigNumber(walletStats.amount_mem_wei)
      }})
    });
  }

  const onSendMissingDailyRewards = async (payoutDay: Date) => {
    await scheduleSendingdMissingDailyRewards(payoutDay);
  }

  const toReadableDate = (date: Date) => `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
  
  const toCountdownText = (date: any) => {
    // @ts-expect-error
    let diffMillis: number = date - new Date();

    const hours = Math.floor(diffMillis / (1000 * 60 * 60));
    diffMillis -= hours * (1000 * 60 * 60);

    const minutes = Math.floor(diffMillis / (1000 * 60));
    diffMillis -= minutes * (1000 * 60);

    const seconds = Math.floor(diffMillis / 1000);

    return `${hours < 10? '0' : ''}${hours}:${minutes < 10? '0' : ''}${minutes}:${seconds < 10? '0' : ''}${seconds}`
  }

  return (
    <Card>
      <CardHeader title="Daily DMEM Rewards"></CardHeader>
      <CardContent>
        <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={1}>
          {
            weeklyTotalData == null?
            <CircularProgress />
            :
            <Grid item xs={4}>
              <Typography variant="subtitle1">Last Week Payouts</Typography>
              <List>
                {
                  weeklyTotalData.map(dayData => { return (
                    <div key={dayData.id} className={classes.weeklyListItem}>
                      <Typography className={classes.weeklyListItemDate}>{`${toReadableDate(dayData.timestamp)} (${dayData.id}):`}</Typography>

                      <div className={classes.weeklyListItemStats}>
                        <div className={classes.weeklyListItemStatsField}>
                          <Typography variant="caption">Limit:&nbsp;</Typography>
                          <div className={classes.memAmount}>
                            <Typography variant="caption">{weiToMem(dayData.memWeiLimit)?.toString()}</Typography>
                            <Avatar className={classes.memLogo} src={memLogo} />
                          </div>
                        </div>

                        <div className={classes.weeklyListItemStatsField}>
                          <Typography variant="caption" color={dayData.totalExpectedMemAmountWei >= dayData.totalTransferedMemAmountWei? "primary" : "error"}>
                            Paid:&nbsp;
                          </Typography>
                          <div className={classes.memAmount}>
                            <Typography variant="caption">{weiToMem(dayData.totalTransferedMemAmountWei)?.toString()}</Typography>
                          </div>
                          <Typography variant="caption">/</Typography>   
                          <div className={classes.memAmount}>
                            <Typography variant="caption">{weiToMem(dayData.totalExpectedMemAmountWei)?.toString()}</Typography>
                            <Avatar className={classes.memLogo} src={memLogo} />
                          </div>
                        </div>

                        <div className={classes.weeklyListItemStatsField}>
                          <Typography variant="caption" color={dayData.totalExecutedTransfers >= dayData.totalExpectedTransfers? "primary" : "error"}>
                            TXs:&nbsp;
                          </Typography>
                          <Typography variant="caption">
                            {dayData.totalExecutedTransfers} / {dayData.totalExpectedTransfers}
                          </Typography>
                        </div>

                        {
                          dayData.totalExecutedTransfers < dayData.totalExpectedTransfers
                          ?
                          <div className={classes.weeklyListItemStatsField}>
                            <ConfirmActionButton 
                              onConfirmed={() => onSendMissingDailyRewards(dayData.rewardForDay)}
                              color="red"
                              buttonLabel="Send missing"
                              dialogTitle="Send missing arena rewards"
                              actionLabel={`Send missing arena rewards for day ${toReadableDate(dayData.timestamp)}`}
                              onSuccess={`Sending missing rewards for ${toReadableDate(dayData.timestamp)} scheduled`}
                            />
                          </div>
                          :
                          null
                        }
                      </div>
                    </div>
                  )})
                }
              </List>

              <div>
                <Typography variant="subtitle1">Send missing rewards</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    renderInput={(props) => <TextField {...props} />}
                    label="Rewards from day"
                    value={sendMissingRewardsDate}
                    onChange={(date) => setMissingRewardsDte(date)}
                  />
                </LocalizationProvider>
                <ConfirmActionButton 
                  onConfirmed={() => onSendMissingDailyRewards(sendMissingRewardsDate!!)}
                  disabled={!sendMissingRewardsDate}
                  color="red"
                  buttonLabel="Send missing"
                  dialogTitle="Send missing arena rewards"
                  actionLabel={`Send missing arena rewards for day ${toReadableDate(sendMissingRewardsDate!)}`}
                  onSuccess={`Sending missing rewards for ${toReadableDate(sendMissingRewardsDate!)} scheduled`}
                />
              </div>
            </Grid>
          }
          
          {
            nextPayoutData == null?
            <CircularProgress />
            :
            <Grid item xs={8}>
              <Typography variant="subtitle1">Next Payout</Typography>
              <Typography className={classes.nextPayoutFieldTitle}>Countdown: </Typography>
              <Typography className={classes.nextPayoutFieldValue}>{toCountdownText(nextPayoutData.timestamp)}</Typography>

              <Typography className={classes.nextPayoutFieldTitle}>To be paid: </Typography>
              <div className={classes.memAmount}>
                <Typography variant="caption">{weiToMem(nextPayoutData.toBePaidMemWei)?.toString()}</Typography>
                <Avatar className={classes.memLogo} src={memLogo} />
              </div>

              <Typography className={classes.nextPayoutFieldTitle}>Payouts: </Typography>
              {
                nextPayoutData.wallets.length === 0?
                <Typography>None</Typography>
                :
                <List className={classes.nextPayoutList}>
                  {
                    nextPayoutData.wallets.map((walletPayout) => { return (
                      <div key={walletPayout.address}>
                        <div className={classes.nextPayoutUserName}>
                          <Typography className={classes.nextPayoutFieldTitle}>
                            {walletPayout.address}
                            {walletPayout.username != null && ` (${walletPayout.username})`}
                            :
                          </Typography>
                          
                        </div>

                        <div className={classes.memAmount}>
                          <Typography variant="caption">{weiToMem(walletPayout.amountMemWei)?.toString()}</Typography>
                          <Avatar className={classes.memLogo} src={memLogo} />
                        </div>
                      </div>
                    )})
                  }
                </List>
              }
            </Grid>
          }
        </Grid>
      </CardContent>
    </Card>
  )
}


const useStyles = makeStyles({
  memAmount: {
    display: 'flex', 
    flexDirection: 'row',
  },
  memLogo: {
    width: '20px',
    height: '20px',
    margin: '0 6px'
  },

  weeklyListItem: {
    display: 'flex', 
    flexDirection: 'column',
  },
  weeklyListItemDate: {
    fontWeight: 600,
  },
  weeklyListItemStats: { 
    display: 'flex', 
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    margin: '0 16px',
  },
  weeklyListItemStatsField: {
    display: 'flex', 
    flexDirection: 'row',
  },

  nextPayoutList: {
    maxHeight: '256px',
    overflowY: 'scroll',
  },
  nextPayoutFieldTitle: {
    fontWeight: 600
  },
  nextPayoutFieldValue: {
    margin: '0 16px',
  },
  nextPayoutUserName: {
    display: 'flex', 
    flexDirection: 'row',
  }
})

export default DailyDMEMRewards;
