import React, { ReactElement, useState } from 'react';

import LevelUpButton from './buttons/levelUpButton'
import LevelDownButton from './buttons/levelDownButton'

import {Button, Datagrid, Filter, List, NumberInput, ReferenceInput, SelectInput, TextField, useRecordContext} from 'react-admin';

import ChangeCardImageButton from "./buttons/changeCardImageButton";
import ChangeCardSignatureButton from "./buttons/changeCardSignatureButton";
import UpdateCardMetadataButton from './buttons/updateCardMetadataButton';
import CensorNftImageButton from "./buttons/censorNftImageButton";
import RescrapeCardThumbnailButton from "./buttons/rescrapeCardThumbnailButton";
import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import { Box } from '@mui/system';
import RemoveSignatureButton from './buttons/removeSignatureButton';
import RemoveArtCreatorAutographButton from './buttons/removeArtCreatorAutographButton';
import { BooleanResponse } from '../../../webservice/models';
import { useWebservice } from '../../../hooks/UseWebservice';


const filters = [
    <NumberInput label="NFT ID" source="nft_id"  />,
    <NumberInput label="Page ID" source="page_id"  />,

    <ReferenceInput label="Domain" source="domain.private_domain.id" reference="domains" filter={{ domain_type: 'PRIVATE'}} >
        <SelectInput optionText="domain" />
    </ReferenceInput>,
]


function MarbleNftGridButton(props: {children: ReactElement}) {
    const buttonSize = "156px";
    return (
        <Grid item xs={4}>
            <Box sx={{width: buttonSize, height: buttonSize}}>
                {props.children}
            </Box>
        </Grid>
    )
}


function MarbleNftButtons() {

    const { hasSignature } = useWebservice();
    
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [nftHasSignature, setNftHasSignature] = useState<boolean | null>(null);
    const record = useRecordContext();

    const onOpenDialog = () => {
        hasSignature(record.nft_id) 
            .then((response: BooleanResponse) => {
                setNftHasSignature(response.response);
            })
        setDialogOpen(true);
    }

    return (<>
        <Button variant="contained" label="Actions" onClick={onOpenDialog} />
        {
            dialogOpen
            ?
            <Dialog
                fullWidth
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}>

                <DialogTitle>Marble NFT Actions - {record.nft_id}</DialogTitle>
                
                <DialogContent>
                    <Grid container spacing={4}> 
                        <MarbleNftGridButton><ChangeCardSignatureButton /></MarbleNftGridButton>
                        <MarbleNftGridButton><RemoveSignatureButton disabled={!nftHasSignature} /></MarbleNftGridButton>
                        <MarbleNftGridButton><ChangeCardImageButton /></MarbleNftGridButton>
                        <MarbleNftGridButton><LevelUpButton /></MarbleNftGridButton>
                        <MarbleNftGridButton><LevelDownButton /></MarbleNftGridButton>
                        <MarbleNftGridButton><UpdateCardMetadataButton /></MarbleNftGridButton>
                        <MarbleNftGridButton><CensorNftImageButton /></MarbleNftGridButton>
                        <MarbleNftGridButton><RescrapeCardThumbnailButton /></MarbleNftGridButton>
                        <MarbleNftGridButton><RemoveArtCreatorAutographButton /></MarbleNftGridButton>
                    </Grid>
                </DialogContent>
            </Dialog>
            :
            <></>
        }
    </>)
}


export const MarbleNftList = () => (
    <List sort={{ field: 'nft_id', order: 'DESC' }} filters={filters}>
        <Datagrid>
			<TextField source="nft_id" />
			<TextField source="page_id" />
			<TextField source="source_uri" />
			<MarbleNftButtons />
        </Datagrid>
    </List>
);
