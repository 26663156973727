import React, { useState, Fragment } from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { withStyles } from '@mui/styles';

import {useNotify, useRecordContext} from 'react-admin';
import { useWebservice } from '../../../../hooks/UseWebservice';
import { PreviewCardMetadataByScrappingResponse } from '../../../../webservice/models';


const styles = {
    dialogContent: {
        display: 'flex',
        'flex-direction': 'row'
    },
    card: {
        width: '512px',
        padding: '10px',
        'margin-right': '10px',

        display: 'flex',
        'flex-direction': 'column'
    },
    cardItem: {
        margin: '6px'
    }
};

// @ts-ignore
const UpdateCardMetadataButton = withStyles(styles)(({ classes }) => {

    const { previewCardMetadataByScraping, updateCardMetadata, updateCardMetadataByScraping } = useWebservice();

    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [manualTitle, setManualTitle] = useState<string>('');
    const [scrapedTitle, setScrapedTitle] = useState<string>('');
    const [updatingManually, setUpdatingManually] = useState<boolean>(false);
    const [updatingByScraping, setUpdatingByScraping] = useState<boolean>(false);
    const [scraping, setScraping] = useState<boolean>(false);

    const notify = useNotify();
    const record = useRecordContext();

    const handleClick = () => {
        setShowDialog(true)
    }

    const handleUpdateManually = async () => {
        setUpdatingManually(true);
        try {
            await updateCardMetadata(record.nft_id, manualTitle);
            notify('Updated!', { type: 'success' });
            setShowDialog(false);
        } catch {
            notify('Error executing the task', { type: 'warning'})
        } finally {
            setUpdatingManually(false);
        }
    }

    const handleUpdateScraped = async () => {
        setUpdatingByScraping(true);
        try {
            await updateCardMetadataByScraping(record.nft_id);
            notify('Updated!', { type: 'success' });
            setShowDialog(false);
        } catch {
            notify('Error executing the task', { type: 'warning' })
        } finally {
            setUpdatingByScraping(false);
        }
    }

    const handlePreviewScrapeData = async () => {
        setScraping(true);
        try {
            const result = await previewCardMetadataByScraping(record.nft_id);
            setScrapedTitle(result.title);
        } catch {
            notify('Error executing the task', { type: 'warning' })
        } finally {
            setScraping(false);
        }
    }

    return (
        <Fragment>
            <Button onClick={handleClick} style={ {backgroundColor: 'goldenrod', color: 'white', height: "100%", width: "100%"} }>
                Change metadata
            </Button>

            <Dialog 
                maxWidth='md'
                open={showDialog}
                onClose={() => setShowDialog(false)}
                aria-label="Set or scrape new metadata for card">

                <DialogTitle>Set or scrape new metadata for card <b>{record.nft_id}</b></DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <div>
                        <span>Set new metadata manually:</span>
                        <Card className={classes.card}>
                            <TextField className={classes.cardItem} label="Title" variant="outlined" value={manualTitle} onChange={event => setManualTitle(event.target.value)} />
                            {
                                updatingManually
                                ?
                                <CircularProgress />
                                :
                                <Button className={classes.cardItem} onClick={handleUpdateManually} variant='contained' color='primary' disabled={updatingByScraping}>Update manually</Button>
                            }
                            <Typography variant='body2'>This option forces the given metadata to the card</Typography>
                        </Card>
                    </div>

                    <div>
                        <span>Rescrape metadata:</span>
                        <Card className={classes.card}>
                            {
                                scraping
                                ?
                                <CircularProgress />
                                :
                                <div className={classes.cardItem}>
                                    <Typography variant='body1'>Title: </Typography><Typography variant='caption' color='secondary'>{scrapedTitle}</Typography>
                                </div>
                            }
                            { !scraping && <Button className={classes.cardItem} onClick={handlePreviewScrapeData} variant='contained' color='secondary' disabled={scraping}>Preview scraped metadata</Button> }
                            {
                                updatingByScraping
                                ?
                                <CircularProgress />
                                :
                                <Button className={classes.cardItem} onClick={handleUpdateScraped} variant='contained' color='primary' disabled={updatingManually}>Update with scraped data</Button>
                            }
                            <Typography variant='body2'>This option will scrape the page again and insert the new metadata to the card. 
                            If you want to see the result of new scraping beforehand (without changing the card), click on Preview scraped metadata button.</Typography>
                        </Card>
                    </div>
                    
                </DialogContent>

            </Dialog>
        </Fragment>
    );
});

export default UpdateCardMetadataButton;
