import { useEffect, useState } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

import ContinueAdminTaskButton from '../../buttons/continueAdminTaskButton';
import { useWebservice } from '../../hooks/UseWebservice';


export interface AdminTask {
  id: number;
  start_checkpoint: number;
  end_checkpoint: number;
  total_entries_count: number;
  processed_entries_count: number;
  applied_on_entries_count: number;
  task_params: any;

  created_at: string;
  started_at: string;
  finished_at: string;

  error_msg: string;
  error_stacktrace: string;
  finished: boolean;

  task_type: "APPLY_TITLE_PARSER_RULE" | "CHANGE_FAVICON_ON_CARDS" | "CENSOR_DOMAIN_THUMBNAILS" | "RESCRAPE_CARD_THUMBNAILS"
}


export default function AdminTasks() {

  const { getAdminTasksOverview } = useWebservice();

  const [tasks, setTasks] = useState<AdminTask[]>();

  useEffect(() => {
    fetchTasks();
  }, [])

  const fetchTasks = async () => {
    const result = await getAdminTasksOverview(10, 48);
    setTasks(result);
  }

  const getTaskLabel = (task: AdminTask) => {
    switch (task.task_type) {
      case 'APPLY_TITLE_PARSER_RULE': return "Parser rule";
      case 'CHANGE_FAVICON_ON_CARDS': return "Favicon update";
      case 'CENSOR_DOMAIN_THUMBNAILS': return "Censoring";
      default: return task.task_type;
    }
  }

  const getDurationString = (task: AdminTask) => {
    if (task.started_at == null || task.finished_at == null) {
      return "N/A";
    }

    const startedTimestamp = new Date(task.started_at).getTime();
    const finishedTimestamp = new Date(task.finished_at).getTime();
    
    let durationTimeSeconds = (finishedTimestamp - startedTimestamp) / 1000;
    const days = Math.floor(durationTimeSeconds / (60 * 60 * 24));
    durationTimeSeconds -= days * (60 * 60 * 24);

    const hours = Math.floor(durationTimeSeconds / (60 * 60));
    durationTimeSeconds -= hours * (60 * 60);

    const minutes = Math.floor(durationTimeSeconds / (60));
    durationTimeSeconds -= minutes * (60);

    const seconds = durationTimeSeconds;

    if (days > 0) return `${days} day(s) ${hours} hour(s)`;
    if (hours > 0) return `${hours} hour(s) ${minutes} minute(s)`;
    if (minutes > 0) return `${minutes} minute(s) ${seconds} second(s)`;
    return `${seconds} second(s)`;
  }

  return (
    <Card>
      <CardHeader title="Admin tasks"></CardHeader>
      <CardContent>
      {
        tasks
        ?
        <div>
          {
            tasks.map(task => (
              <div key={task.id}>
                <div style={{padding: "8px 0"}}> 
                  {task.finished && task.error_msg == null && <>
                    <Typography style={{fontWeight: "bold"}} color="primary">FINISHED: </Typography> 
                  </>}

                  {task.finished && task.error_msg != null && <>
                    <Typography style={{fontWeight: "bold"}} color="error">ERROR: </Typography>
                  </>}

                  {!task.finished && task.started_at != null && <>
                    <Typography style={{fontWeight: "bold"}}>RUNNING: </Typography> 
                    <LinearProgress variant="determinate" value={(task.processed_entries_count / task.total_entries_count) * 100} />
                  </>}

                  {!task.finished && task.started_at == null && <>
                    <Typography style={{fontWeight: "bold"}}>QUEUED: </Typography> 
                  </>}

                  <Typography>{ getTaskLabel(task) } (id: {task.id}, updated entries: {task.applied_on_entries_count}, runtime: {getDurationString(task)})</Typography>
                  {task.error_msg != null && <>
                    <ContinueAdminTaskButton adminTask={task} onTaskContinued={(_) => fetchTasks()} />
                  </>}
                </div>
                <Divider />
              </div>
            ))
          }
        </div>
        :
        <CircularProgress />
      }
      </CardContent>
    </Card>
  );
}
