import { useEffect, useState } from 'react';

import Typography from '@mui/material/Typography';

import ConfirmActionButton from "../components/shared/ConfirmActionButton";
import useWeiConversions from '../hooks/web3/UseWeiConversions';
import { useRecordContext } from 'react-admin';
import { useWebservice } from '../hooks/UseWebservice';


export default function AcceptCardVerificationBidButton() {

  const { acceptVerificationBid } = useWebservice();

  const [acceptDisabled, setAcceptDisabled] = useState<boolean>(false);
  const {weiToMem} = useWeiConversions();
  const record = useRecordContext();

  useEffect(() => {
    const isBidActive = record.status === 'active';
    setAcceptDisabled(!isBidActive);
  }, [record.status])

  const onConfirmed = async (record: any) => {
    await acceptVerificationBid(record.id);
  }

  const requestedByWallet = record.requested_by_wallet;
  const requestedArtCreator = record.requested_art_creator;

  return (
    <ConfirmActionButton
      color={acceptDisabled? 'grey' : 'green'}
      dialogTitle="Are you sure you want to accept this bid?"
      buttonLabel="Accept bid"
      actionLabel="Accept card verification bid"
      disabled={acceptDisabled}
      onConfirmed={onConfirmed}
      onSuccess="Verification bid was accepted"
    >
      <Typography>
        Card: { record.nft_id }<br></br>
        Verification price: {weiToMem(record.offered_mem_wei_amount)?.toString()} $MEM<br></br>
        Royalty: {parseFloat(record.royalty_percentage)}%<br></br>
        Art creator: {requestedArtCreator.name}<br></br>
        User: {requestedByWallet.username ? requestedByWallet.username : requestedByWallet.account }<br></br>
      </Typography>

    </ConfirmActionButton>
  )
}