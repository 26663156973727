import React from 'react';

import {
    Create,
    Datagrid,
    Edit,
    EditButton,
    List,
    NumberField,
    required,
    SimpleForm,
    TextField,
    TextInput,
    SelectInput,
} from 'react-admin';

export const StatsAccountsList = () => (
    <List>
      <Datagrid>
        <NumberField source="id" />
        <TextField source="name" />
        <TextField source="account_type" />
        <TextField source="address" />
        <TextField source="processing_priority" />
        <EditButton />
      </Datagrid>
    </List>
);

const StatsAccountsTitle = ({ account }: any) => {
  return <span>Stats Account {account? `"${account.name}" (${account.id})` : ''}</span>
};

export const StatsAccountsCreate = () => (
    <Create title={<StatsAccountsTitle />}>
        <SimpleForm>
            <TextInput source="name" validate={required()}/>
            <SelectInput source="account_type" choices={[
                { id: 'AUCTION', name: 'AUCTION' },
                { id: 'REFUND', name: 'REFUND' },
                { id: 'TRANSFER', name: 'TRANSFER' },
                { id: 'INCOME', name: 'INCOME' }
            ]} />
            <TextInput source="address" validate={required()}/>
            <TextInput source="processing_priority" validate={required()}/>
        </SimpleForm>
    </Create>
);

export const StatsAccountsEdit = () => (
    <Edit title={<StatsAccountsTitle />}>
        <SimpleForm>
          <TextInput source="name" validate={required()}/>
          <SelectInput source="account_type" choices={[
            { id: 'AUCTION', name: 'AUCTION' },
            { id: 'REFUND', name: 'REFUND' },
            { id: 'TRANSFER', name: 'TRANSFER' },
            { id: 'INCOME', name: 'INCOME' }
          ]} />
          <TextInput source="address" validate={required()}/>
          <TextInput source="processing_priority" validate={required()}/>
        </SimpleForm>
    </Edit>
);
