import { useWebservice } from "./UseWebservice";


export default function useAppConfigChangeValidation() {

  const { validateAppConfigChange } = useWebservice();

  const validation = (values: any) => {
      const config_value = values.value;
      switch (values.value_type) {
          case "INTEGER":
              if (!config_value.match(/^-?\d+$/)) {
                  return Promise.resolve({'value': 'Not an integer'})
              }
              break;
          case "DECIMAL_PREC_10":
              if (isNaN(config_value)) {
                  return Promise.resolve({'value': 'Not a float'})
              }
              break;
          default:
              break;
      }

      const backendValidation = validateAppConfigChange(values).catch(reason => {
          alert('Error within app config validation. Check console for details.');
          console.error("Error within app config validation.");
          console.error(reason);
      });

      return Promise.resolve(backendValidation).then(response => {
          if (response && response.error) {
              return {
                  // we assign the returned error to the input for the attribute app_config.value
                  // this is perhaps not ideal, but I don't know how to show the error as a global form error instead
                  // of a validation error of a particular input element
                  'value': response.error
              };
          } else {
              return null;
          }
      });
  }

  return {
      validation
  }
}
