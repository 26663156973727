import {
    Datagrid,
    Edit,
    EditButton,
    List,
    NumberField,
    NumberInput,
    required,
    SimpleForm,
    TextField,
    TextInput,
    ReferenceField,
    ReferenceInput,
    SelectInput,
} from 'react-admin';


const DeckCardsFilter = [
    <TextInput label="Deck id" source="deck_id"  />,
    <TextInput label="Marble NFT id" source="marble_nft_id"  />
]

export const DeckCardsList = () => (
    <List filters={DeckCardsFilter}>
      <Datagrid>
        <NumberField source="id" />
        <ReferenceField label="Deck" source="deck_id" reference="decks">
            <TextField source="title" />
        </ReferenceField>

        <ReferenceField label="Marble NFT" source="marble_nft_id" reference="marble_nft">
            <NumberField source="nft_id" />
        </ReferenceField>

        <NumberField source="order" />
        <EditButton />
      </Datagrid>
    </List>
);

const DeckCardTitle = ({ deckCard }: any) => {
  return <span>Deck {deckCard? `"${deckCard.id}"` : ''}</span>
};

export const DeckCardEdit = () => (
    <Edit title={<DeckCardTitle />}>
        <SimpleForm>
            <NumberField source="id" />

            <ReferenceInput label="Deck" source="deck_id" reference="decks" validate={[required()]}>
                <SelectInput optionText="title" />
            </ReferenceInput>

            <NumberInput label="Marble NFT" source="marble_nft_id" validate={[required()]} />
        </SimpleForm>
    </Edit>
);
