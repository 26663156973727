import * as BigNumber from 'bignumber.js';
import { useState, useEffect } from 'react';
import useWeiConversions from './web3/UseWeiConversions';
import { useWebservice } from './UseWebservice';


export function useMemecoinStats() {

  const { getMemecoinStats, setEthToMemRate, setMaticToMemRate } = useWebservice();

  const [ currentEthRate, setCurrentEthRate ] = useState<BigNumber.BigNumber | null>(null);
  const [ currentMaticRate, setCurrentMaticRate ] = useState<BigNumber.BigNumber | null>(null);

  const [ adminEthWallet, setAdminEthWallet ] = useState<string | null>(null);
  const [ adminMaticWallet, setAdminMaticWallet ] = useState<string | null>(null);
  const [ adminEthBalance, setAdminEthBalance ] = useState<BigNumber.BigNumber | null>(null);
  const [ adminMaticBalance, setAdminMaticBalance ] = useState<BigNumber.BigNumber | null>(null);

  const [ loading, setLoading ] = useState<boolean>(true);
  const [ error, setError ] = useState<any | null>(null);

  const { weiToMem, oneMemToWei } = useWeiConversions();


  useEffect(() => {
    const fetch = async function() {
      setLoading(true);
      try {
        const result = await getMemecoinStats();
        setCurrentEthRate(weiToMem(new BigNumber.BigNumber(result.current_eth_rate)));
        setCurrentMaticRate(weiToMem(new BigNumber.BigNumber(result.current_matic_rate)));
        setAdminEthWallet(result.admin_eth);
        setAdminEthBalance(weiToMem(new BigNumber.BigNumber(result.admin_eth_balance)));
        setAdminMaticWallet(result.admin_matic);
        setAdminMaticBalance(weiToMem(new BigNumber.BigNumber(result.admin_matic_balance)));
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    }

    fetch();
  }, [])

  const updateEthMemRate = async (newRate: string | number | null) => {
    if (newRate === null || newRate === "" || newRate === "0") {
      return "Wrong format";
    }

    const newRateBigNumber = new BigNumber.BigNumber(newRate);
    const newRateWei = newRateBigNumber.multipliedBy(oneMemToWei)
    let result;

    try {
      result = await setEthToMemRate(newRateWei);
    } catch (error) {
      return error;
    }

    if (result['error']) {
      return result['error']
    } else {
      return null;
    }
  }

  const updateMaticMemRate = async(newRate: string | number | null) => {
    if (newRate === null || newRate === "" || newRate === "0") {
      return "Wrong format";
    }

    const newRateBigNumber = new BigNumber.BigNumber(newRate);
    const newRateWei = newRateBigNumber.multipliedBy(oneMemToWei);
    let result;

    try {
      result = await setMaticToMemRate(newRateWei);
    } catch (error) {
      return error;
    }

    if (result['error']) {
      return result['error']
    } else {
      return null;
    }
  }

  return {
    currentEthRate,
    currentMaticRate,

    adminEthWallet,
    adminMaticWallet,
    adminEthBalance,
    adminMaticBalance,

    updateEthMemRate,
    updateMaticMemRate,
    loading,
    error,
  }
}
