import settings from '../../settings';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';


interface ScrapingbeeApiResponse {
    data: ScrapingbeeStats
}

interface ScrapingbeeStats {
    used_api_credit: number;
    max_api_credit: number;
    current_concurrency: number;
    max_concurrency: number;
}


export default function ScrapingbeeStats() {

    const [scrapingbeeStats, setScrapingbeeStats] = useState<ScrapingbeeStats | null>(null);

    useEffect(() => {
        async function fetchData() {
            const apiKey = settings.scrapingbee.api_key;
            const apiUrl = `https://app.scrapingbee.com/api/v1/usage?api_key=${apiKey}`;
            const response: ScrapingbeeApiResponse = await axios.get(apiUrl)
            setScrapingbeeStats(response.data)
        }

        fetchData();
    }, [])

    return (
      <Card>
          <CardHeader title="Scrapingbee stats"></CardHeader>
          <CardContent>
          {
              scrapingbeeStats
              ?
              <div>
                  <Typography variant="body1" gutterBottom>
                      Credits: {scrapingbeeStats.used_api_credit} / {scrapingbeeStats.max_api_credit}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                      Concurency: {scrapingbeeStats.current_concurrency} / {scrapingbeeStats.max_concurrency}
                  </Typography>
              </div>
              :
              <CircularProgress />
          }
          </CardContent>
      </Card>
            
    );
}