import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';

import backgroundImage from '../../assets/marble.jpg';


export default function Welcome() {

    return (
      <Card >
          <CardHeader style={styles.card} title="Welcome to the Marblegame administration" />
      </Card>
    );
}

const styles = {
  card: {
    backgroundImage: `url(${backgroundImage})`,
  }
};
