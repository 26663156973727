import { useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import LinearProgress from '@mui/material/LinearProgress'; // TODO: change to LinearProgressWithLabel when we update the react-admin (material) library
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { useRecordContext } from 'react-admin';
import { useWebservice } from '../hooks/UseWebservice';

interface Props {
  internal: boolean;
}

interface ProcessingStatus {
  totalNfts: number;
  processedCount: number;
  appliedCount: number;
  finished: boolean;
  error: string;
}


export default function CensorDomainButton({ internal }: Props) {

  const { censorDomain, uncensorDomain, getAdminTaskStatus } = useWebservice();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [censoring, setCensoring] = useState<boolean | null>(null);
  const [processingCensorTask, setProcessingCensorTask] = useState<boolean>(false);
  const [processingStatus, setProcessingStatus] = useState<ProcessingStatus | null>(null);

  const record = useRecordContext();

  const onClickCensor = () => {
    setCensoring(true);
    setDialogOpen(true);
  }

  const onClickUncensor = () => {
    setCensoring(false);
    setDialogOpen(true);
  }

  const onCensor = async () => {
    setProcessingCensorTask(true);
    const result = await censorDomain(record.id, internal);
    getCensorTaskStatus(result.censor_task_id);
  }

  const onUncensor = async () => {
    setProcessingCensorTask(true);
    const result = await uncensorDomain(record.id, internal);
    getCensorTaskStatus(result.censor_task_id);
  }

  const getCensorTaskStatus = async (taskId: number) => {
    setTimeout(async () => {
      const result = await getAdminTaskStatus(taskId)
      setProcessingStatus({
        totalNfts: result.total_nfts_count,
        processedCount: result.processed_nfts_count,
        appliedCount: result.applied_on_nfts_count,
        finished: result.finished,
        error: result.error
      })

      if (!result.finished) {
        getCensorTaskStatus(taskId);
      }
    }, 5000)

  }

  const isCensored = internal? record.censor_internal_thumbnail : record.censor_external_thumbnails;
  const typeLabel = internal? "internal" : "external";

  return (
    <>
      <Button style={{backgroundColor: 'orangered', color: 'white'}} onClick={onClickCensor}>Censor {typeLabel} thumbnails</Button>
      <Button style={{backgroundColor: 'limegreen', color: 'white'}} onClick={onClickUncensor}>Uncensor {typeLabel} thumbnails</Button>
      
      <Dialog 
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <DialogTitle>Domain {typeLabel} thumbnail censoring - {record.id}: {record.domain} ({record.domain_display_name})</DialogTitle>
        
        <DialogContent>
          {
            processingCensorTask 
            ?
            <div>
              <Typography>Processing {typeLabel} {censoring? "censor" : "uncensor"} task!</Typography>
              {
                processingStatus != null
                ?
                <>
                  <Typography>NFTs to be {censoring? "censored" : "uncensored"}: {processingStatus.totalNfts}</Typography>
                  <Typography>Processed NFTs: {processingStatus.processedCount}</Typography>
                  <Typography>Newly censored NFTs: {processingStatus.appliedCount}</Typography>
                  <LinearProgress variant="determinate" value={(processingStatus.processedCount / processingStatus.totalNfts) * 100} />
                  { 
                    processingStatus.finished
                    ?
                    <Typography color="textSecondary"><b>FINISHED!</b></Typography>
                    :
                    <CircularProgress size={12} />
                  }
                </>
                :
                <>
                  <CircularProgress />
                  <Typography>The {censoring? "censor" : "uncensor"} task has not started yet.</Typography>
                </>
              }
            </div>
            :
            <div>
              <Typography>Do you really want to <b>{isCensored ? 'UNCENSOR' : 'CENSOR'}</b> all NFTs' thumbnails from domain <i>{record.domain}</i>?</Typography>
              <Typography>This will {censoring? "censor" : "uncensor"} all already created NFTs with the given domain as well as making all new created NFTs {censoring? "censored" : "uncensored"}.</Typography>
              <Typography>{censoring? "Censoring" : "Uncensoring"} all already created NFTs will take some time, this dialog will show you the {censoring? "censoring" : "uncensoring"} status.</Typography>
              {
                internal?
                <Typography>This will apply the censoring only in Library and Marketplace of marble.cards</Typography>
                :
                <Typography>This will apply the censoring only on external sites (currently only OpenSea)</Typography>
              }

              { !censoring && <Button style={{backgroundColor: 'limegreen', color: 'white'}} onClick={onUncensor}>Uncensor {typeLabel} thumbnails now!</Button> }
              { censoring && <Button style={{backgroundColor: 'orangered', color: 'white'}} onClick={onCensor}>Censor {typeLabel} thumbnails now!</Button> }
            </div>
          }
        </DialogContent>
      </Dialog>
    </>
  )
}
