import { useState } from 'react';
import {
    List, Edit, Create, Datagrid, DateField, TextField, ReferenceField, NumberField, FunctionField,
    EditButton, SimpleForm, ImageField,
    TextInput, NumberInput, required, email, useRecordContext
} from 'react-admin';
import { useWebservice } from '../../hooks/UseWebservice';
import { BooleanResponse } from '../../webservice/models';
import TruncatedTextField from './../../components/shared/TruncatedTextField';
import UploadImageField from './../../components/shared/UploadImageField';
import useWeiConversions from './../../hooks/web3/UseWeiConversions';


const inputStyle = { width: '1000px' };

export const ArtCreatorsList = () => {
    const {weiToMem} = useWeiConversions();

    const imagesHeight = 66;

    return (
        <List>
            <Datagrid>
                <TextField source="id" />
                <TextField source="wallet.username" label="Username" />
                <TextField source="wallet.email" label="Email" />
                <ImageField source="icon" sx={{'& .RaImageField-image': {'height': imagesHeight}}} />
                <ImageField source="circular_icon_large" label="Thumbnail" sx={{'& .RaImageField-image': {'height': imagesHeight}}} />
                <ImageField source="signature_image" sx={{'& .RaImageField-image': {'height': imagesHeight}}} />
                <TruncatedTextField source="biography" length={60} />
                <ReferenceField source="wallet_id" label="Wallet" reference="wallets">
                    <TextField source="account" />
                </ReferenceField>
                <FunctionField
                    label="Minimal verification $MEM price"
                    render={(record: any) => record.minimal_verification_mem_wei_price != null ? weiToMem(record.minimal_verification_mem_wei_price)?.toString(10) : null}
                    />;
                <NumberField source="verified_nfts_count" />
                <DateField source="created_at" />
                <UploadImageField taskPath="admin/art_creators/set_creator_icon_task" label="Set Icon"></UploadImageField>
                <UploadImageField taskPath="admin/art_creators/set_signature_image_task" label="Set Signature"></UploadImageField>
                <EditButton />
            </Datagrid>
        </List>
    )
};

const ArtCreatorTitle = () => {
    const record = useRecordContext();
    return <span>{!record?.id ? "New art creator" : `Art Creator ${record?.id}`}</span>
};


const useAsyncUniqueNameValidation = () => {
    const { existsOtherCreatorWithNameTask } = useWebservice();
    const [previousValidatedName, setPreviousValidatedName] = useState<string>("");
    const [previousResult, setPreviousResult] = useState<any>(null);

    const asyncValidateUniqueName = (values: any) => {
        const name = 'wallet' in values? values['wallet']['username'] : null;
        if (name === null || name === undefined) {
            return Promise.resolve(undefined);
        }

        if (previousValidatedName === name) {
            return previousResult === null? Promise.resolve() : Promise.reject(previousResult);
        } else {
            setPreviousValidatedName(name);
        }

        const handleErrorWithinValidation = (reason: any) => {
            alert('Error within creator unique name validation. Check console for details.');
            console.error("Error within creator unique name validation.");
            console.error(reason);
        };

        const artCreatorId = values['id'];
        const existsOtherCreatorWithNamePromise = existsOtherCreatorWithNameTask(artCreatorId, name.trim()).catch(handleErrorWithinValidation);

        return Promise.all([existsOtherCreatorWithNamePromise]).then((responseValues: (void | BooleanResponse)[]) => {
            const responseValue = responseValues[0];
            if (responseValue == undefined) {
                return { wallet: { username: 'unknown error' } }
            }

            let creatorNameOccupied = responseValue.response;

            if (creatorNameOccupied) {
                const errors = {
                    wallet: { username: 'Art Creator Name is already taken.' }
                };
                setPreviousResult(errors);
                return errors;
            } else {
                setPreviousResult(null);
                return null;
            }
        });
    };

    return {
        uniqueNameValidator: asyncValidateUniqueName
    }
}


export const ArtCreatorCreate = () => {
    const { memToWei, weiToMem } = useWeiConversions();
    const { uniqueNameValidator } = useAsyncUniqueNameValidation();

    return (
        <Create title={<ArtCreatorTitle />}>
            <SimpleForm validate={uniqueNameValidator}>
                <TextInput source="wallet.username" label="Displayed name of the creator" validate={required()} style={inputStyle} />
                <TextInput source="wallet.email" label="Creator's email" validate={[required(), email()]} style={inputStyle} />
                <TextInput multiline source="biography" label="Biography (max 500 characters)" style={inputStyle} inputProps={{ maxLength: 500 }} />
                <NumberInput source="minimal_verification_mem_wei_price" label="Minimal verification price (in $MEM)" format={v => weiToMem(v)} parse={v => memToWei(v)} style={inputStyle} />
                <TextInput source="wallet.account" label="Creator's ethereum wallet" validate={required()} style={inputStyle} />
            </SimpleForm>
        </Create>
    );
}

export const ArtCreatorEdit = () => {
    const { memToWei, weiToMem } = useWeiConversions();
    const { uniqueNameValidator } = useAsyncUniqueNameValidation();

    return (
        <Edit title={<ArtCreatorTitle />}>
            <SimpleForm validate={uniqueNameValidator}>
                <TextInput source="wallet.username" label="Displayed name of the creator" validate={required()} style={inputStyle} />
                <TextInput source="wallet.email" label="Creator's email" validate={[required(), email()]} style={inputStyle} />
                <TextInput multiline source="biography" label="Biography (max 500 characters)" style={inputStyle} inputProps={{ maxLength: 500 }} />
                <NumberInput source="minimal_verification_mem_wei_price" label="Minimal verification price (in $MEM)" format={v => weiToMem(v)} parse={v => memToWei(v)} style={inputStyle} />
                <TextField source="wallet.account" label="Creator's ethereum wallet" style={inputStyle} />
            </SimpleForm>
        </Edit>
    )
}
