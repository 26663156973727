import Button from '@mui/material/Button';
import { useNotify, useRecordContext } from 'react-admin';
import { useWebservice } from '../hooks/UseWebservice';


interface LeagueCardPartial {
    id: number;
}


function KickFromLeagueButton() {

    const { kickCardFromLeague } = useWebservice();

    const notify = useNotify();
    const record = useRecordContext<LeagueCardPartial>();

    const onClick = () => {
        const kickCardFromLeaguePromise = kickCardFromLeague(record.id);

        kickCardFromLeaguePromise.catch(error => {
            notify("Error on webservice! " + error.message, { type: "warning" })
        });

        kickCardFromLeaguePromise.then(response => {
            if (response.error) {
                notify("Error kicking the card! " + response.error, { type: "warning" })
            }
            else {
                notify("Card kicked from the league! Refresh to see changes", { type: "info" })
            }
        });
    };

    return <Button style={{backgroundColor: 'coral'}} onClick={onClick}>Kick</Button>
}

export default KickFromLeagueButton;
