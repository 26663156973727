import React, { useEffect, useState } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import ConfirmActionButton from '../shared/ConfirmActionButton';
import { useWebservice } from '../../hooks/UseWebservice';
import { PendingTransactionInfo } from '../../webservice/models';


export default function PendingTransactions() {

  const { getOutgoingPendingWeb3TransactionsOverview, setGasPrice } = useWebservice();

  const [queuedCount, setQueuedCount] = useState<number>();
  const [pendingCount, setPendingCount] = useState<number>();
  const [pendingTransactions, setPendingTransactions] = useState<PendingTransactionInfo[]>();
  const [failedTxs24h, setFailedTxs24h] = useState<number>();
  const [failedTxs3d, setFailedTxs3d] = useState<number>();
  const [failedTxs7d, setFailedTxs7d] = useState<number>();
  const [currentGasPrice, setCurrentGasPrice] = useState<number>();
  const [newGasPrice, setNewGasPrice] = useState<number | string>('');

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      
      const result = await getOutgoingPendingWeb3TransactionsOverview();
      setQueuedCount(result.queued_count)
      setPendingCount(result.pending_count)
      setPendingTransactions(result.pending_transactions)
      setFailedTxs24h(result.failed_transactions_24h);
      setFailedTxs3d(result.failed_transactions_3d);
      setFailedTxs7d(result.failed_transactions_7d);
      setCurrentGasPrice(result.gas_price)
      setNewGasPrice(result.gas_price)
      
      setLoading(false);
    }

    fetch();
  }, [])

  const onSetNewGasPriceClick = async () => {
    await setGasPrice(newGasPrice);
  }

  return (
    <Card>
      <CardHeader title="Transactions overview"></CardHeader>
      <CardContent>
      {
        !loading
        ?
        <div style={{display: "flex", flexDirection: "column"}}>
          <div style={{display: "flex", flexDirection: "row", marginBottom: 24}}>
            <TextField type="number" label="Gas Price" value={newGasPrice} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewGasPrice(event.target.value)}></TextField>
            <ConfirmActionButton 
              onConfirmed={(record) => onSetNewGasPriceClick()} 
              color="#2196f3" 
              buttonLabel="Set Gas Price"
              dialogTitle="Set new gas price"
              actionLabel={`Set gas price to ${newGasPrice} wei`}
              disabled={newGasPrice === currentGasPrice}>
            </ConfirmActionButton>
          </div>

          <div style={{display: "flex", flexDirection: "column", marginBottom: 24}}>
            <FailedTransactionsCountInfo count={failedTxs24h!} interval="24 hours" />
            <FailedTransactionsCountInfo count={failedTxs3d!} interval="3 days" />
            <FailedTransactionsCountInfo count={failedTxs7d!} interval="7 days" />
          </div>

          <div style={{display: "flex", flexDirection: "row"}}>
            <div style={{padding: "0 13px 0 0"}}>
              <Typography variant="h2" style={{fontWeight: "bold", fontSize: 22}}>Transactions queue info</Typography> 
              <div style={{display: 'flex'}}>
                <Typography style={{fontWeight: "bold"}}>Queued transactions count:</Typography> 
                <Typography style={{fontWeight: "bold", color: queuedCount! < 5? "green" : queuedCount! < 15? "orange" : "red"}}> {queuedCount}</Typography> 
              </div>
              
              <div style={{display: 'flex'}}>
                <Typography style={{fontWeight: "bold"}}>Pending transactions count: </Typography> 
                <Typography style={{fontWeight: "bold", color: pendingCount! < 10? "green" : "red"}}> {pendingCount}</Typography> 
              </div>
            </div>
            
            <div>
              <Typography variant="h2" style={{fontWeight: "bold", fontSize: 22}}>Pending transactions</Typography> 
              {
                pendingTransactions!.length > 0
                ?
                pendingTransactions!.map((transaction) => (
                  <TransactionInfo key={transaction.id} transaction={transaction}></TransactionInfo>
                ))
                :
                <Typography style={{fontWeight: "bold"}} color="primary">No pending transactions</Typography> 
              }
            </div>
          </div>
        </div>
        :
        <CircularProgress />
      }
      </CardContent>
    </Card>
  );
}

function TransactionInfo({ transaction }: { transaction: PendingTransactionInfo }) {
  return (
    <div style={{display: 'flex', flexDirection: 'row'}}>
      <Typography style={{fontWeight: "bold"}}>{transaction.id}: </Typography> 
      <Typography>{new Date(transaction.sent_at * 1000).toISOString()} {transaction.transaction_hash}</Typography> 
    </div>
  )
}

const FailedTransactionsCountInfo = ({ count, interval }: { count: number, interval: string }) => (
  <div style={{display: "flex", flexDirection: "row"}}>
    <Typography style={{fontWeight: "bold"}}>Failed txs in last {interval}:</Typography> 
    <Typography style={{fontWeight: "bold", color: count == 0? "green" : "red"}}> {count}</Typography> 
  </div>
)
