import Button from '@mui/material/Button';
import { useNotify, useRecordContext } from 'react-admin';
import { useWebservice } from '../../../../hooks/UseWebservice';


function LevelDownButton() {

    const { levelDownCard } = useWebservice();
    const notify = useNotify();
    const record = useRecordContext();
    
    const onClick = () => {
        const levelDownCardPromise = levelDownCard(record.nft_id);

        levelDownCardPromise.catch(error => {
            notify("Error on webservice! " + error.message, { type: "warning" })
        });

        levelDownCardPromise.then(response => {
            if (response.error && response.error != null) {
                notify("Error leveling down the card! " + response.error, { type: "warning" })
            }
            else {
                notify("Card successfully leveled down to " + response.level + "!", { type: "info" })
            }
        });
    };

    return <Button style={{backgroundColor: 'coral', height: "100%", width: "100%"}} onClick={onClick}>Level Down</Button>
}

export default LevelDownButton;
