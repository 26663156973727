import React, {useState, Fragment} from 'react';

import { StringMap, useNotify, useRecordContext } from 'react-admin';

import Button from '@mui/material/Button';

import IconCancel from '@mui/icons-material/Cancel';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useWebservice } from '../hooks/UseWebservice';


interface State {
    error: boolean;
    link: string | null;
    showDialog: boolean;
    exportedData: any;
    fromDateTime: Date | null;
    toDateTime: Date | null;
    exportType: 'winning-cards' | 'all-battles';
};


interface ArenaLeaguePartial {
    id: number;
    title: string;
}


function ExportLeagueButton() {

    const { exportWinningCardsLeague } = useWebservice();

    const [state, setState] = useState<State>({
        error: false,
        link: null,
        showDialog: false,
        exportedData: null,

        fromDateTime: new Date(),
        toDateTime: new Date(),
        exportType: 'winning-cards'
    });

    const notify = useNotify();
    const record = useRecordContext<ArenaLeaguePartial>();

    const handleClick = () => {
        setState({ ...state, showDialog: true });
    };

    const handleCloseClick = () => {
        setState({ ...state, showDialog: false, exportedData: null });
    };

    const handleWinningStatsCardsExecuteClick = () => {
        const exportPromise = exportWinningCardsLeague(record.id, state.fromDateTime!, state.toDateTime!, state.exportType);

        exportPromise.catch(error => {
            notify("Error on webservice! " + error.message, { type: "warning" });
        });

        exportPromise.then(response => {
            if (response.error) {
                notify("Error exporting stats! " + response.error, { type: "warning" });
            }
            else {
                notify(`Stats exported successfully.`, { type: "info" });
                setState({...state, exportedData: response["export_result"]});
            }
        });
    }  

    const onExportTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // @ts-expect-error
        setState({...state, exportType: e.target.value})
    }

    return (
        <Fragment>
            <Button style={ {'backgroundColor': 'goldenrod', color: 'white'} } onClick={handleClick}>
                Export Stats
            </Button>

            <Dialog
                fullWidth
                open={state.showDialog}
                onClose={handleCloseClick}
                aria-label="Export stats">

                <DialogTitle>Export League Stats -  {record.title}</DialogTitle>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            renderInput={(props) => <TextField {...props} />}
                            label="Date from (UTC time)"
                            value={state.fromDateTime}
                            onChange={(date) => setState({...state, fromDateTime: date})}
                        />

                        <DateTimePicker
                            renderInput={(props) => <TextField {...props} />}
                            label="Date to (UTC time)"
                            value={state.toDateTime}
                            onChange={(date) => setState({...state, toDateTime: date})}
                        />
                    </LocalizationProvider>

                    <RadioGroup aria-label="Export type" value={state.exportType} onChange={onExportTypeChange}>
                        <FormControlLabel value="winning-cards" control={<Radio />} label="Best Winning Cards" />
                        <FormControlLabel value="all-battles" control={<Radio />} label="All Battles" />
                    </RadioGroup>

                    <Button
                        style={ {backgroundColor: 'goldenrod', color: 'white'} }
                        onClick={handleWinningStatsCardsExecuteClick}
                    >
                        Export
                    </Button>
                    
                    { !!state.exportedData &&
                        <a href={state.exportedData['url']}>Click to Download - {state.exportedData['filename']}</a>
                    }
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={handleCloseClick}
                    >
                        Cancel
                        <IconCancel/>
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}

export default ExportLeagueButton;
